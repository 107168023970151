import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConfirmationModalComponent } from '../components/app-confirmation-modal-component/app-confirmation-modal.component';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ConfirmationModalService {
    constructor(private modalService: NgbModal) {}

    open(title: string, message: string): Promise<boolean> {
        const modalRef = this.modalService.open(AppConfirmationModalComponent);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.waitForClose = false;

        return firstValueFrom(modalRef.closed);
    }

    openAndWaitToClose(
        title: string,
        message: string,
    ): { modalRef: any; result: Promise<boolean> } {
        const modalRef = this.modalService.open(AppConfirmationModalComponent);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.waitForClose = true;

        const resultPromise = firstValueFrom(modalRef.closed);

        return { modalRef, result: resultPromise };
    }
}
