import { UserDto } from '../dtos/UserDto';
import { StateService } from '../../services/StateService';
import { ActiveCountEvent } from './ActiveCountEvent';
import { format } from 'date-fns';

export enum IssueType {
    NeedsToBeJunked = 1,
    NeedsToBeReinstated = 2,
    IssueBetweenDistricts = 3,
    ZeroCostAsset = 4,
}
export const IssueTypeLabels = {
    1: 'Needs To Be Junked',
    2: 'Needs To Be Reinstated',
    3: 'Issue Between Districts',
    4: 'Zero Cost Asset',
};

export enum AssetStatus {
    Counted = 1,
    NotCounted = 2,
    Out = 3,
    HasIssue = 4,
}
export const AssetStatusLabels = {
    1: 'Counted',
    2: 'Not Counted',
    3: 'Out',
    4: 'Issues',
};

export interface Asset {
    item: string;
    description: string;
    category: string;
    subCategory: string;
}

export interface AssetEventHistory {
    lastStatusChangeEvent: ActiveCountEvent;
}

export interface ISessionAsset {
    asset: Asset;
    status: AssetStatus;
    ticket?: number | null;
    eventHistory?: AssetEventHistory | null;
    notes?: string;
    issueType?: IssueType | null;
    isResolved?: boolean;
    onTrailer?: boolean;
    isAdded?: boolean;
}

export class SessionAsset {
    private stateService: StateService;

    assetNumber: string;
    description: string;
    category: string;
    subCategory: string;
    notes: string;
    status: AssetStatus;
    issueType: IssueType | null;
    isResolved: boolean;
    ticket: number | null;
    eventHistory: AssetEventHistory | null;
    onTrailer: boolean;
    isAdded: boolean;

    constructor(iSessionAsset: ISessionAsset, stateService: StateService) {
        this.stateService = stateService;

        const { item, description, category, subCategory } = iSessionAsset.asset;

        this.assetNumber = item;
        this.description = description;
        this.category = category;
        this.subCategory = subCategory;
        this.status = iSessionAsset.status;
        this.eventHistory = iSessionAsset.eventHistory ?? null;
        this.ticket = iSessionAsset.ticket ?? null;
        this.notes = iSessionAsset.notes ?? '';
        this.issueType = iSessionAsset.issueType ?? null;
        this.isResolved = iSessionAsset.isResolved ?? false;
        this.onTrailer = iSessionAsset.onTrailer ?? false;
        this.isAdded = iSessionAsset.isAdded ?? false;
    }

    get hasTicket(): boolean {
        return this.ticket !== 0;
    }

    get hasNotes(): boolean {
        return this.notes.length > 0;
    }

    get shouldShowNotes(): boolean {
        return this.hasNotes && !this.isResolved;
    }

    get shouldShowIsResolved(): boolean {
        return this.hasIssueType && this.isResolved;
    }

    get statusLabel(): string {
        return AssetStatusLabels[this.status];
    }

    get hasLastMovementEvent(): boolean {
        return this.eventHistory?.lastStatusChangeEvent?.statusChange !== undefined;
    }

    get shouldShowLastEvent(): boolean {
        return this.hasLastMovementEvent && !this.isResolved;
    }

    get isStatusIssue(): boolean {
        return this.status === AssetStatus.HasIssue;
    }

    get hasIssueType(): boolean {
        return this.isStatusIssue && this.issueType !== null;
    }

    get shouldShowIssueType(): boolean {
        return this.hasIssueType && !this.isResolved;
    }

    get location(): string | null {
        return this.eventHistory?.lastStatusChangeEvent?.location ?? null;
    }

    get issueTypeDetails(): string {
        if (this.status != AssetStatus.HasIssue || this.issueType == null) return '--';
        return IssueTypeLabels[this.issueType];
    }

    get lastEventDetails(): string {
        if (!this.hasLastMovementEvent) return '';

        const event = this.eventHistory?.lastStatusChangeEvent;
        if (!event) return '--';

        const users = this.stateService.get('users') as UserDto[];
        const user = users.find(
            (user) => user.userName.toLowerCase() === event?.username.toLowerCase(),
        );

        const eventLabel = AssetStatusLabels[event?.statusChange.to];
        const abbreviatedName = `${user?.firstName} ${user?.lastName?.[0]}.`;
        const dateFormatted = event?.utcTimestamp
            ? format(new Date(event.utcTimestamp), 'M/d/yy, h:mm a')
            : '--';

        return `${eventLabel} by ${abbreviatedName}, ${dateFormatted} @ ${event?.location}`;
    }

    get assetNumberForDisplay(): string {
        return this.isAdded ? `+ ${this.assetNumber}` : this.assetNumber;
    }
}
