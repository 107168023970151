import { Component, Input, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-button',
    standalone: true,
    imports: [CommonModule],
    template: `
        <button
            [id]="id"
            type="button"
            [ngClass]="[baseClass, sizeClass, class, iconOnlyClass]"
            [style.width]="width"
            [disabled]="isDisabled"
        >
            <span
                *ngIf="icon"
                class="material-icons icon"
                >{{ icon }}</span
            >
            {{ text }}
        </button>
    `,
    styleUrls: ['../../styles/_buttons.scss'],
})
export class AppButtonComponent {
    @Input() id: string = '';
    @Input() text: string = '';
    @Input() class: string = '';
    @Input() isDisabled: boolean = false;
    @Input() width: string = ''; // Accepts: '100%' or '150px'
    @Input() icon: string | null = null; // Add icon input

    @HostBinding('attr.primary')
    @Input()
    primary: '' | null = null;

    @HostBinding('attr.medium')
    @Input()
    medium: '' | null = null;

    @HostBinding('attr.small')
    @Input()
    small: '' | null = null;

    get baseClass(): string {
        return this.primary !== null ? 'app-button-primary' : 'app-button';
    }

    get sizeClass(): string {
        if (this.medium !== null) {
            return 'app-button-medium';
        } else if (this.small !== null) {
            return 'app-button-small';
        }
        return '';
    }

    get iconOnlyClass(): string {
        return this.icon && !this.text.trim() ? 'icon-only' : '';
    }
}
