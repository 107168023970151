/* src/app/modules/main/asset-retention-history/asset-retention-history.component.scss */
.flex-row,
.asset-retention-history .asset-numbers .assets > .list .asset .asset-number-shared,
.asset-retention-history .asset-numbers .assets > .list .asset.valid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset,
.asset-retention-history .asset-numbers .assets > .header .right,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > .title,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > div.icon,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title,
.asset-retention-history .asset-numbers .assets > .header,
.asset-retention-history .asset-numbers,
.asset-retention-history > .count-info,
.asset-retention-history > .count-title,
.app-readonly-field .text,
.app-readonly-field .label,
.app-filter-search > .clear-icon,
.app-filter-search > .field-input,
.app-filter-search > .search-icon,
.app-filter-search,
.app-datepicker .label,
.app-datepicker,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column,
.asset-retention-history .asset-numbers .assets > .list,
.asset-retention-history .asset-numbers .assets > .header .left,
.asset-retention-history .asset-numbers .assets,
.asset-retention-history,
.app-readonly-field {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis,
.asset-retention-history .asset-numbers .assets > .list .asset .asset-number-shared,
.asset-retention-history .asset-numbers .assets > .list .asset.valid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .asset-number,
.app-readonly-field .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
.app-filter-search > .clear-icon,
.app-filter-search > .search-icon,
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-field-shadow,
.app-textarea,
.app-filter-search,
.app-datepicker input[type=date],
.app-input-text {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.app-field-shadow,
.app-textarea,
.app-filter-search,
.app-datepicker input[type=date],
.app-input-text {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.flex-row,
.asset-retention-history .asset-numbers .assets > .list .asset .asset-number-shared,
.asset-retention-history .asset-numbers .assets > .list .asset.valid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset,
.asset-retention-history .asset-numbers .assets > .header .right,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > .title,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > div.icon,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title,
.asset-retention-history .asset-numbers .assets > .header,
.asset-retention-history .asset-numbers,
.asset-retention-history > .count-info,
.asset-retention-history > .count-title,
.app-readonly-field .text,
.app-readonly-field .label,
.app-filter-search > .clear-icon,
.app-filter-search > .field-input,
.app-filter-search > .search-icon,
.app-filter-search,
.app-datepicker .label,
.app-datepicker,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column,
.asset-retention-history .asset-numbers .assets > .list,
.asset-retention-history .asset-numbers .assets > .header .left,
.asset-retention-history .asset-numbers .assets,
.asset-retention-history,
.app-readonly-field {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis,
.asset-retention-history .asset-numbers .assets > .list .asset .asset-number-shared,
.asset-retention-history .asset-numbers .assets > .list .asset.valid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .asset-number,
.app-readonly-field .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
.app-filter-search > .clear-icon,
.app-filter-search > .search-icon,
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to bottom,
      rgba(37, 37, 37, 0) 0%,
      rgba(37, 37, 37, 0.2) 100%);
  width: auto;
  justify-content: center;
  height: 33px;
  min-height: 33px;
  max-height: 33px;
  line-height: 33px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  padding: 0 22px;
  font-size: 12.5px;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  color: #505050;
  background-color: #efefef;
  border: 1px solid #aaa;
}
.app-button .icon,
.app-toggle-button-group button .icon,
.app-multi-toggle-button-group button .icon,
.app-button-primary .icon {
  width: auto;
  margin: 0 5px 0 0;
  font-size: 15px;
  padding: 0;
  font-weight: 500;
}
.app-button[disabled],
.app-toggle-button-group button[disabled],
.app-multi-toggle-button-group button[disabled],
[disabled].app-button-primary {
  opacity: 0.4;
  cursor: default;
}
.app-button:not([disabled]):hover,
.app-toggle-button-group button:not([disabled]):hover,
.app-multi-toggle-button-group button:not([disabled]):hover,
.app-button-primary:not([disabled]):hover {
  background: #efefef;
}
.app-button:not([disabled]):active,
.app-toggle-button-group button:not([disabled]):active,
.app-multi-toggle-button-group button:not([disabled]):active,
.app-button-primary:not([disabled]):active {
  background-color: #ccc;
}
.app-button.app-button-medium,
.app-toggle-button-group button.app-button-medium,
.app-multi-toggle-button-group button.app-button-medium,
.app-button-medium.app-button-primary {
  padding: 0 17px;
  font-size: 11px;
  height: 29px;
  min-height: 29px;
  max-height: 29px;
  line-height: 29px;
}
.app-button.app-button-medium .icon,
.app-toggle-button-group button.app-button-medium .icon,
.app-multi-toggle-button-group button.app-button-medium .icon,
.app-button-medium.app-button-primary .icon {
  margin: 0 5px 0 0;
  font-size: 15px;
}
.app-button.app-button-small,
.app-toggle-button-group button.app-button-small,
.app-multi-toggle-button-group button.app-button-small,
.app-button-small.app-button-primary {
  padding: 0 12px;
  font-size: 10px;
  height: 23px;
  min-height: 23px;
  max-height: 23px;
  line-height: 23px;
}
.app-button.app-button-small .icon,
.app-toggle-button-group button.app-button-small .icon,
.app-multi-toggle-button-group button.app-button-small .icon,
.app-button-small.app-button-primary .icon {
  margin: 0 4px 0 0;
  font-size: 13px;
}
.app-button.icon-only,
.app-toggle-button-group button.icon-only,
.app-multi-toggle-button-group button.icon-only,
.icon-only.app-button-primary {
  padding: 0 15px;
}
.app-button.icon-only .icon,
.app-toggle-button-group button.icon-only .icon,
.app-multi-toggle-button-group button.icon-only .icon,
.icon-only.app-button-primary .icon {
  margin: 0;
}
.app-button.icon-only.app-button-medium,
.app-toggle-button-group button.icon-only.app-button-medium,
.app-multi-toggle-button-group button.icon-only.app-button-medium,
.icon-only.app-button-medium.app-button-primary {
  padding: 0 12px;
}
.app-button.icon-only.app-button-small,
.app-toggle-button-group button.icon-only.app-button-small,
.app-multi-toggle-button-group button.icon-only.app-button-small,
.icon-only.app-button-small.app-button-primary {
  padding: 0 10px;
}
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%);
  color: #fff;
  background-color: #0a51a3;
  border: 1px solid #0a51a3;
}
.app-button-primary:not([disabled]):hover,
.app-toggle-button-group button.selected:not([disabled]):hover,
.app-multi-toggle-button-group button.selected:not([disabled]):hover {
  background: #0a51a3;
}
.app-button-primary:not([disabled]):active,
.app-toggle-button-group button.selected:not([disabled]):active,
.app-multi-toggle-button-group button.selected:not([disabled]):active {
  background-color: #093b75;
}
.app-toggle-button-group,
.app-multi-toggle-button-group {
  display: inline-flex;
  border: 1px solid #ccc;
  border-radius: 2px;
  overflow: hidden;
}
.app-toggle-button-group button,
.app-multi-toggle-button-group button {
  font-size: 12px;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 0 !important;
}
.app-toggle-button-group button:first-child,
.app-multi-toggle-button-group button:first-child {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
.app-toggle-button-group button:last-child,
.app-multi-toggle-button-group button:last-child {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.app-toggle-button-group button:not(:last-child),
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button .icon {
  font-size: 14px;
  color: #505050;
  margin: 0 4px 0 0;
}
.app-multi-toggle-button-group button.selected {
  border-right: 1px solid #0a51a3;
}
.app-multi-toggle-button-group button.selected .icon {
  color: #fff;
  opacity: 0.85;
}
.flex-row,
.asset-retention-history .asset-numbers .assets > .list .asset .asset-number-shared,
.asset-retention-history .asset-numbers .assets > .list .asset.valid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset,
.asset-retention-history .asset-numbers .assets > .header .right,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > .title,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > div.icon,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title,
.asset-retention-history .asset-numbers .assets > .header,
.asset-retention-history .asset-numbers,
.asset-retention-history > .count-info,
.asset-retention-history > .count-title,
.app-readonly-field .text,
.app-readonly-field .label,
.app-filter-search > .clear-icon,
.app-filter-search > .field-input,
.app-filter-search > .search-icon,
.app-filter-search,
.app-datepicker .label,
.app-datepicker,
.app-button,
.app-button-primary,
.app-toggle-button-group button,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button,
.app-multi-toggle-button-group button.selected {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column,
.asset-retention-history .asset-numbers .assets > .list,
.asset-retention-history .asset-numbers .assets > .header .left,
.asset-retention-history .asset-numbers .assets,
.asset-retention-history,
.app-readonly-field {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis,
.asset-retention-history .asset-numbers .assets > .list .asset .asset-number-shared,
.asset-retention-history .asset-numbers .assets > .list .asset.valid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .asset-number,
.app-readonly-field .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
.app-filter-search > .clear-icon,
.app-filter-search > .search-icon,
.app-button,
.app-button-primary,
.app-toggle-button-group button,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button,
.app-multi-toggle-button-group button.selected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-field-shadow,
.app-textarea,
.app-filter-search,
.app-datepicker input[type=date],
.app-input-text {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.app-field-shadow,
.app-textarea,
.app-filter-search,
.app-datepicker input[type=date],
.app-input-text {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.flex-row,
.asset-retention-history .asset-numbers .assets > .list .asset .asset-number-shared,
.asset-retention-history .asset-numbers .assets > .list .asset.valid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset,
.asset-retention-history .asset-numbers .assets > .header .right,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > .title,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > div.icon,
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title,
.asset-retention-history .asset-numbers .assets > .header,
.asset-retention-history .asset-numbers,
.asset-retention-history > .count-info,
.asset-retention-history > .count-title,
.app-readonly-field .text,
.app-readonly-field .label,
.app-filter-search > .clear-icon,
.app-filter-search > .field-input,
.app-filter-search > .search-icon,
.app-filter-search,
.app-datepicker .label,
.app-datepicker,
.app-button,
.app-button-primary,
.app-toggle-button-group button,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button,
.app-multi-toggle-button-group button.selected {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column,
.asset-retention-history .asset-numbers .assets > .list,
.asset-retention-history .asset-numbers .assets > .header .left,
.asset-retention-history .asset-numbers .assets,
.asset-retention-history,
.app-readonly-field {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis,
.asset-retention-history .asset-numbers .assets > .list .asset .asset-number-shared,
.asset-retention-history .asset-numbers .assets > .list .asset.valid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .asset-number,
.app-readonly-field .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
.app-filter-search > .clear-icon,
.app-filter-search > .search-icon,
.app-button,
.app-button-primary,
.app-toggle-button-group button,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button,
.app-multi-toggle-button-group button.selected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-button,
.app-button-primary,
.app-toggle-button-group button,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to bottom,
      rgba(37, 37, 37, 0) 0%,
      rgba(37, 37, 37, 0.2) 100%);
  width: auto;
  justify-content: center;
  height: 33px;
  min-height: 33px;
  max-height: 33px;
  line-height: 33px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  padding: 0 22px;
  font-size: 12.5px;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  color: #505050;
  background-color: #efefef;
  border: 1px solid #aaa;
}
.app-button .icon,
.app-button-primary .icon,
.app-toggle-button-group button .icon,
.app-multi-toggle-button-group button .icon {
  width: auto;
  margin: 0 5px 0 0;
  font-size: 15px;
  padding: 0;
  font-weight: 500;
}
.app-button[disabled],
[disabled].app-button-primary,
.app-toggle-button-group button[disabled],
.app-multi-toggle-button-group button[disabled] {
  opacity: 0.4;
  cursor: default;
}
.app-button:not([disabled]):hover,
.app-button-primary:not([disabled]):hover,
.app-toggle-button-group button:not([disabled]):hover,
.app-multi-toggle-button-group button:not([disabled]):hover {
  background: #efefef;
}
.app-button:not([disabled]):active,
.app-button-primary:not([disabled]):active,
.app-toggle-button-group button:not([disabled]):active,
.app-multi-toggle-button-group button:not([disabled]):active {
  background-color: #ccc;
}
.app-button.app-button-medium,
.app-button-medium.app-button-primary,
.app-toggle-button-group button.app-button-medium,
.app-multi-toggle-button-group button.app-button-medium {
  padding: 0 17px;
  font-size: 11px;
  height: 29px;
  min-height: 29px;
  max-height: 29px;
  line-height: 29px;
}
.app-button.app-button-medium .icon,
.app-button-medium.app-button-primary .icon,
.app-toggle-button-group button.app-button-medium .icon,
.app-multi-toggle-button-group button.app-button-medium .icon {
  margin: 0 5px 0 0;
  font-size: 15px;
}
.app-button.app-button-small,
.app-button-small.app-button-primary,
.app-toggle-button-group button.app-button-small,
.app-multi-toggle-button-group button.app-button-small {
  padding: 0 12px;
  font-size: 10px;
  height: 23px;
  min-height: 23px;
  max-height: 23px;
  line-height: 23px;
}
.app-button.app-button-small .icon,
.app-button-small.app-button-primary .icon,
.app-toggle-button-group button.app-button-small .icon,
.app-multi-toggle-button-group button.app-button-small .icon {
  margin: 0 4px 0 0;
  font-size: 13px;
}
.app-button.icon-only,
.icon-only.app-button-primary,
.app-toggle-button-group button.icon-only,
.app-multi-toggle-button-group button.icon-only {
  padding: 0 15px;
}
.app-button.icon-only .icon,
.icon-only.app-button-primary .icon,
.app-toggle-button-group button.icon-only .icon,
.app-multi-toggle-button-group button.icon-only .icon {
  margin: 0;
}
.app-button.icon-only.app-button-medium,
.icon-only.app-button-medium.app-button-primary,
.app-toggle-button-group button.icon-only.app-button-medium,
.app-multi-toggle-button-group button.icon-only.app-button-medium {
  padding: 0 12px;
}
.app-button.icon-only.app-button-small,
.icon-only.app-button-small.app-button-primary,
.app-toggle-button-group button.icon-only.app-button-small,
.app-multi-toggle-button-group button.icon-only.app-button-small {
  padding: 0 10px;
}
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%);
  color: #fff;
  background-color: #0a51a3;
  border: 1px solid #0a51a3;
}
.app-button-primary:not([disabled]):hover,
.app-toggle-button-group button.selected:not([disabled]):hover,
.app-multi-toggle-button-group button.selected:not([disabled]):hover {
  background: #0a51a3;
}
.app-button-primary:not([disabled]):active,
.app-toggle-button-group button.selected:not([disabled]):active,
.app-multi-toggle-button-group button.selected:not([disabled]):active {
  background-color: #093b75;
}
.app-toggle-button-group,
.app-multi-toggle-button-group {
  display: inline-flex;
  border: 1px solid #ccc;
  border-radius: 2px;
  overflow: hidden;
}
.app-toggle-button-group button,
.app-multi-toggle-button-group button {
  font-size: 12px;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 0 !important;
}
.app-toggle-button-group button:first-child,
.app-multi-toggle-button-group button:first-child {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
.app-toggle-button-group button:last-child,
.app-multi-toggle-button-group button:last-child {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.app-toggle-button-group button:not(:last-child),
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button .icon {
  font-size: 14px;
  color: #505050;
  margin: 0 4px 0 0;
}
.app-multi-toggle-button-group button.selected {
  border-right: 1px solid #0a51a3;
}
.app-multi-toggle-button-group button.selected .icon {
  color: #fff;
  opacity: 0.85;
}
.app-input-text {
  width: 100%;
  padding: 8px 10px 8px 12px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 2px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  background-color: #fff;
}
.app-input-text:hover {
  background-color: rgba(117, 117, 117, 0.05);
}
.app-input-text::placeholder {
  color: #757575;
  font-weight: 300;
  opacity: 0.7;
  font-style: italic;
  font-size: 11px;
  text-transform: none;
}
.app-select {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #252525;
  color: #252525;
  padding: 0 30px 0 15px;
  height: 33px;
  line-height: 33px;
  outline: none;
}
.app-select option {
  background-color: transparent;
  border: 1px solid #252525;
}
.app-select.small {
  border: none;
  font-size: 11px;
  font-weight: 500;
  padding: 0 15px 0 7px;
  height: 19.8px;
  line-height: 19.8px;
}
.app-select.medium {
  font-size: 12px;
  font-weight: 400;
  padding: 0 22px 0 10px;
  height: 26.4px;
  line-height: 26.4px;
}
.app-datepicker {
  width: auto;
  position: relative;
  cursor: pointer;
}
.app-datepicker .label {
  width: auto;
  padding: 0 10px 0 0;
  font-size: 13px;
  font-weight: 500;
  color: #252525;
}
.app-datepicker input[type=date] {
  font-size: 14px;
  padding: 4px 10px;
  border-radius: 2px;
  border: 1px solid #505050;
}
.app-datepicker .overlay {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
}
.app-datepicker:hover input {
  background-color: rgba(0, 0, 0, 0.05);
}
.app-filter-search {
  position: relative;
  height: 35px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  border-radius: 2px;
  background-color: #fff;
  cursor: default;
}
.app-filter-search:hover {
  background-color: rgba(239, 239, 239, 0.5);
}
.app-filter-search:hover .field-input {
  background-color: rgba(239, 239, 239, 0.5);
}
.app-filter-search > .search-icon {
  position: absolute;
  top: 0;
  right: unset;
  bottom: 0;
  left: 0;
  width: 38px;
  height: 100%;
  justify-content: center;
  background-color: transparent;
}
.app-filter-search > .search-icon .icon {
  margin-top: 1px;
  font-size: 17px;
  color: #606060;
  background-color: transparent;
}
.app-filter-search > .field-input {
  position: absolute;
  top: 0;
  right: 34px;
  bottom: 0;
  left: 38px;
  width: auto;
  height: 30px;
}
.app-filter-search > .field-input input[type=text] {
  width: 100%;
  padding: 0;
  margin: 4px 0 0 0;
  outline: none;
  border: none;
  font-size: 12.5px;
  font-weight: 400;
  color: #353535;
  background-color: transparent;
}
.app-filter-search > .field-input input[type=text]::placeholder {
  color: #757575;
  font-weight: 300;
  opacity: 0.7;
  font-style: italic;
  font-size: 12px;
  text-transform: none;
}
.app-filter-search > .clear-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: unset;
  width: 34px;
  height: 100%;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}
.app-filter-search > .clear-icon .icon {
  margin-top: 1px;
  font-size: 17px;
  color: #757575;
  border-radius: 2px;
  background-color: transparent;
}
.app-filter-search > .clear-icon:hover {
  background-color: rgba(117, 117, 117, 0.1);
}
.app-filter-search > .clear-icon:hover .icon {
  color: #252525;
}
.app-readonly-field {
  margin: 0 0 10px 0;
}
.app-readonly-field .label {
  font-size: 11px;
  font-weight: 400;
  color: #093b75;
  line-height: 17px;
  text-shadow: 1px 1px 1px rgba(80, 80, 80, 0.1);
}
.app-readonly-field .text {
  font-size: 13px;
  font-weight: 400;
  color: #252525;
  line-height: 19px;
  word-wrap: break-word;
}
.app-readonly-field .text.no-value {
  opacity: 0.6;
}
.app-textarea {
  width: 100%;
  resize: none;
  height: 100px;
  padding: 8px 10px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  border-radius: 2px;
  border: 1px solid rgba(128, 128, 128, 0.5);
  background-color: rgba(117, 117, 117, 0.05);
}
.app-textarea::placeholder {
  color: #757575;
  font-weight: 300;
  opacity: 0.7;
  font-style: italic;
  font-size: 11px;
  text-transform: none;
}
.back-button {
  margin: 0 10px 0 0;
}
.asset-retention-history {
  position: absolute;
  top: 60px;
  right: unset;
  bottom: 10px;
  left: 25px;
  width: calc(100% - 45px);
}
.asset-retention-history > .count-title {
  padding: 3px 0;
}
.asset-retention-history > .count-title .district-code,
.asset-retention-history > .count-title .district-name {
  font-size: 15px;
  font-weight: 600;
  color: rgba(9, 59, 117, 0.8);
}
.asset-retention-history > .count-title .district-code {
  color: #093b75;
  margin: 0 8px 0 0;
}
.asset-retention-history > .count-info > span.label {
  font-size: 12px;
  font-weight: 600;
  color: #093b75;
  padding: 2px 0;
  width: 32px;
}
.asset-retention-history > .count-info > div.value {
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 0 10px;
  color: #505050;
}
.asset-retention-history > .count-info > div.value > span.user {
  margin: 0 0 0 12px;
  font-size: 12px;
  font-weight: 400;
  color: #757575;
}
.asset-retention-history .asset-numbers {
  position: absolute;
  top: 85px;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: flex-start;
}
.asset-retention-history .asset-numbers .assets {
  height: 100%;
}
.asset-retention-history .asset-numbers .assets.invalid {
  width: auto;
  max-width: 700px;
  white-space: nowrap;
  margin: 0 50px 0 0;
}
.asset-retention-history .asset-numbers .assets.valid {
  width: auto;
  min-width: 200px;
  white-space: nowrap;
}
.asset-retention-history .asset-numbers .assets > .header .left {
  padding: 3px 0;
  font-size: 12px;
  font-weight: 400;
  color: #505050;
}
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > div.icon {
  width: 23px;
  height: 23px;
}
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > div.icon .icon {
  padding: 0;
  margin: 0;
  font-size: 17px;
  font-weight: 400;
}
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > div.icon .icon.invalid {
  color: #d52f23;
}
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > div.icon .icon.valid {
  color: #279a2c;
}
.asset-retention-history .asset-numbers .assets > .header .left > .icon-and-title > .title {
  width: calc(100% - 23px);
  height: auto;
  color: #252525;
  font-weight: 400;
}
.asset-retention-history .asset-numbers .assets > .header .left > .details {
  height: auto;
  font-size: 11px;
  color: #757575;
  font-weight: 400;
  padding: 0 0 0 2px;
}
.asset-retention-history .asset-numbers .assets > .header .right {
  justify-content: flex-end;
  align-items: center;
}
.asset-retention-history .asset-numbers .assets > .list {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
  margin: 5px 0 0 0;
  padding: 5px 0;
  box-shadow: inset 0 8px 6px -6px rgba(0, 0, 0, 0.1), inset 0 -8px 6px -6px rgba(0, 0, 0, 0.1);
}
.asset-retention-history .asset-numbers .assets > .list .asset {
  align-items: flex-start;
  margin: 0 0 5px 0;
  padding: 0 20px 0 0;
}
.asset-retention-history .asset-numbers .assets > .list .asset .asset-number-shared,
.asset-retention-history .asset-numbers .assets > .list .asset.valid .asset-number,
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .asset-number {
  font-size: 11px;
  font-weight: 500;
  color: #252525;
  padding: 3px;
}
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .asset-number {
  width: 100px;
}
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .errors {
  font-size: 11px;
  font-weight: 400;
  color: #505050;
  width: calc(100% - 100px);
  word-wrap: break-word;
  white-space: normal;
  padding: 3px 0;
  line-height: 1.4;
}
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .errors ul {
  margin: 0 0 4px 0;
  padding: 0 0 0 15px;
}
.asset-retention-history .asset-numbers .assets > .list .asset.invalid .errors ul li {
  margin: 0;
  padding: 0 0 3px 0;
}
.asset-retention-history .asset-numbers .assets > .list .asset.valid .asset-number {
  width: 100%;
}
/*# sourceMappingURL=asset-retention-history.component.css.map */
