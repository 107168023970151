/* src/components/app-multi-select/app-multi-select.component.scss */
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
.app-multi-select .dropdown-panel,
.app-multi-select .display {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-multi-select {
  position: relative;
  min-width: 175px;
  border-bottom: 1px solid #252525;
  outline: none;
  cursor: pointer;
}
.app-multi-select .display {
  height: 33px !important;
  min-height: 33px !important;
  max-height: 33px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #252525;
  font-size: 14px;
  font-weight: 400;
  padding: 0 5px 0 15px;
  line-height: 33px;
}
.app-multi-select .display .dropdown-arrow {
  height: 33px !important;
  min-height: 33px !important;
  max-height: 33px !important;
  margin: 0 0 0 auto;
  line-height: 33px;
  color: #000;
  padding: 0 4px;
}
.app-multi-select .dropdown-panel {
  position: absolute;
  top: 100%;
  right: 0;
  bottom: unset;
  left: 0;
  background: #fff;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid rgba(37, 37, 37, 0.6);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  z-index: 1000;
}
.app-multi-select .dropdown-panel .option {
  height: 38px !important;
  min-height: 38px !important;
  max-height: 38px !important;
  display: flex;
  align-items: center;
  gap: 8px;
  line-height: 38px;
  font-size: 14px;
  font-weight: 400;
  padding: 5px;
}
.app-multi-select .dropdown-panel .option input[type=checkbox] {
  width: 15px;
  height: 15px;
}
.app-multi-select .dropdown-panel .option:hover {
  background-color: rgba(9, 59, 117, 0.05);
}
.app-multi-select .dropdown-panel .option:active {
  background-color: rgba(9, 59, 117, 0.1);
}
.app-multi-select.medium .display {
  height: 29px !important;
  min-height: 29px !important;
  max-height: 29px !important;
  font-size: 12px;
  font-weight: 400;
  padding: 0 6px 0 10px;
  line-height: 29px;
}
.app-multi-select.medium .display .dropdown-arrow {
  height: 29px !important;
  min-height: 29px !important;
  max-height: 29px !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 29px;
  padding: 0 4px;
}
.app-multi-select.medium .dropdown-panel {
  top: 110%;
  max-height: 175px;
}
.app-multi-select.medium .dropdown-panel .option {
  height: 33px !important;
  min-height: 33px !important;
  max-height: 33px !important;
  line-height: 33px;
  font-size: 12px;
  font-weight: 400;
  gap: 7px;
}
.app-multi-select.medium .dropdown-panel .option input[type=checkbox] {
  width: 14px;
  height: 14px;
}
.app-multi-select.small {
  border: none;
}
.app-multi-select.small .display {
  height: 25px !important;
  min-height: 25px !important;
  max-height: 25px !important;
  font-size: 11px;
  font-weight: 500;
  padding: 0 4px 0 7px;
  line-height: 25px;
}
.app-multi-select.small .display .dropdown-arrow {
  height: 25px !important;
  min-height: 25px !important;
  max-height: 25px !important;
  font-size: 11px;
  font-weight: 500;
  line-height: 25px;
  padding: 0 3px;
}
.app-multi-select.small .dropdown-panel {
  top: 120%;
  max-height: 150px;
}
.app-multi-select.small .dropdown-panel .option {
  height: 33px !important;
  min-height: 33px !important;
  max-height: 33px !important;
  line-height: 33px;
  font-size: 11px;
  font-weight: 500;
  gap: 5px;
}
.app-multi-select.small .dropdown-panel .option input[type=checkbox] {
  width: 12px;
  height: 12px;
}
/*# sourceMappingURL=app-multi-select.component.css.map */
