import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgStyle } from '@angular/common';

@Component({
    selector: 'app-filter-search',
    template: `
        <div
            [id]="id"
            [class]="'app-filter-search ' + class"
            [ngStyle]="{ width: width + 'px' }"
        >
            <div class="search-icon">
                <span class="material-icons icon">search</span>
            </div>
            <div class="field-input">
                <input
                    type="text"
                    spellcheck="false"
                    [placeholder]="placeholder"
                    [(ngModel)]="searchText"
                    (keyup)="filterRows()"
                />
            </div>
            <div
                class="clear-icon"
                (click)="clearSearch()"
            >
                <span class="material-icons icon">close</span>
            </div>
        </div>
    `,
    standalone: true,
    styleUrls: ['../../styles/_controls.scss'],
    imports: [FormsModule, NgStyle],
})
export class FilterSearchComponent {
    @Input() id = '';
    @Input() class = '';
    @Input() width = 225;
    @Input() placeholder = 'Filter...';

    @Output() filter = new EventEmitter<string>();
    @Output() clear = new EventEmitter<void>();

    searchText = '';

    filterRows() {
        this.filter.emit(this.searchText);
    }

    clearSearch() {
        this.searchText = '';
        this.clear.emit();
    }
}
