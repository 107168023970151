import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SessionCounts } from '../../../../../../../models/interfaces/SessionCounts';
import { Session } from '../../../../../../../models/classes/Session';
import { AssetStatus } from '../../../../../../../models/classes/SessionAsset';

interface CountItem {
    label: string;
    key: keyof SessionCounts;
}

@Component({
    selector: 'session-counts',
    standalone: true,
    imports: [CommonModule],
    template: `
        <div
            class="session-counts"
            *ngIf="sessionCounts"
        >
            <div class="total">Total Assets ({{ sessionCounts.total | number }})</div>
            <div
                class="count-value"
                *ngFor="let item of countItems"
            >
                <div class="label">{{ item.label }}</div>
                <div class="value">{{ sessionCounts[item.key] | number }}</div>
            </div>
        </div>
    `,
    styleUrls: ['./session-counts.component.scss'],
})
export class SessionCountsComponent implements OnInit {
    readonly countItems: CountItem[] = [
        { label: 'Not Counted', key: 'notCounted' },
        { label: 'Counted', key: 'counted' },
        { label: 'Out', key: 'out' },
        { label: 'Issues', key: 'issues' },
    ];

    @Input() session?: Session;

    sessionCounts?: SessionCounts;

    ngOnInit() {
        if (!this.session) return;

        const { assets } = this.session;

        this.sessionCounts = {
            total: assets?.length ?? 0,
            notCounted:
                assets?.filter((asset) => asset.status === AssetStatus.NotCounted).length ?? 0,
            counted: assets?.filter((asset) => asset.status === AssetStatus.Counted).length ?? 0,
            out: assets?.filter((asset) => asset.status === AssetStatus.Out).length ?? 0,
            issues: assets?.filter((asset) => asset.status === AssetStatus.HasIssue).length ?? 0,
        };
    }
}
