/* src/app/modules/main/components/asset-count-live/components/session-section/session-section.component.scss */
.flex-row,
.session-section .section-header {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column,
.session-section .content,
.session-section {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.session-section {
  min-width: 120px;
  max-width: 275px;
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  width: calc(100% - 20px);
  margin: 0 10px;
}
.session-section .section-header {
  border: none;
  border-top: 1px solid rgba(9, 59, 117, 0.25);
  padding: 0 0 0 10px;
  margin: 0;
}
.session-section .section-header legend {
  font-size: 12px;
  font-weight: 600;
  color: #093b75;
  padding: 0 10px;
}
.session-section .content {
  overflow-x: hidden;
  overflow-y: auto;
  padding: 10px;
  font-size: 11px;
  height: 100%;
  border-bottom: 1px solid rgba(9, 59, 117, 0.25);
}
/*# sourceMappingURL=session-section.component.css.map */
