import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleButtonGroupOption } from '../../models/interfaces/ToggleButtonGroupOption';

@Component({
    selector: 'app-toggle-button-group',
    standalone: true,
    imports: [CommonModule],
    template: `
        <div class="app-toggle-button-group">
            <button
                *ngFor="let option of options"
                type="button"
                [ngClass]="{ selected: isSelected(option.type) }"
                (click)="toggleSelection(option.type)"
            >
                {{ option.text }}
            </button>
        </div>
    `,
    styleUrl: '../../styles/_buttons.scss',
})
export class AppToggleButtonGroupComponent<T> {
    @Input() options: ToggleButtonGroupOption<T>[] = [];
    @Input() selected!: T;

    @Output() selectionChange = new EventEmitter<T>();

    isSelected(type: T): boolean {
        return this.selected === type;
    }

    toggleSelection(type: T): void {
        if (this.selected !== type) {
            this.selectionChange.emit(type);
        }
    }
}
