<div class="manage-images-modal">
    <div class="modal-container">
        <div class="modal-header">
            <div class="title"> Manage Images - {{ assetNumber }} </div>
            <div
                class="close"
                aria-label="Close"
                (click)="closeModal(true)"
            >
                <span class="material-icons icon">close</span>
            </div>
        </div>

        <div class="modal-body">
            <div
                class="list"
                *ngIf="imagesLength"
            >
                <div
                    class="image"
                    *ngFor="let image of orderBy(images, ['id'], ['asc'])"
                    (click)="displayImage(image.id, image.url)"
                    [style.backgroundImage]="'url(' + image.url + ')'"
                    [ngStyle]="{
                        outline: selectedImageId === image.id ? '3px solid blue' : 'none',
                    }"
                ></div>
            </div>

            <div
                class="viewer"
                *ngIf="imagesLength"
            >
                <div
                    class="image"
                    *ngIf="selectedImageId !== null"
                    [style.backgroundImage]="'url(' + selectedImageUrl + ')'"
                ></div>
                <div
                    class="no-image"
                    *ngIf="selectedImageId === null"
                >
                    No Image Selected
                </div>
            </div>

            <div
                class="add"
                *ngIf="imagesLength"
            >
                <app-button
                    primary
                    icon="upload"
                    text="Upload Image"
                    (click)="displayUploadImageForm()"
                />
            </div>

            <div
                class="name-and-delete"
                *ngIf="imagesLength"
            >
                <div
                    class="name"
                    *ngIf="selectedImageId !== null"
                >
                    Image {{ selectedImageIndex }} of {{ imagesLength }}
                </div>
                <div class="delete">
                    <app-button
                        primary
                        icon="delete"
                        text="Delete Image"
                        *ngIf="selectedImageId !== null"
                        (click)="deleteImage()"
                    />
                </div>
            </div>

            <div
                class="image-upload-form"
                *ngIf="shouldShowUploadImageForm"
            >
                <div class="form">
                    <div class="intro"> Select Image File To Upload </div>
                    <div
                        *ngIf="imagesLength"
                        class="close-button"
                        (click)="closeUploadForm()"
                    >
                        <span class="material-icons-sharp icon">close</span>
                    </div>

                    <div class="upload-body">
                        <input
                            type="file"
                            (change)="onFileSelected($event)"
                            accept="image/*"
                            #fileInput
                            style="display: none"
                        />

                        <app-button
                            primary
                            icon="image"
                            text="Select Image"
                            class="select-image-button"
                            (click)="fileInput.click()"
                            *ngIf="!selectedFile && !errorMessage"
                        />

                        <div
                            class="file-selected"
                            *ngIf="selectedFile && !errorMessage"
                        >
                            <strong>File Selected:</strong>
                            {{ selectedFile.name }}
                        </div>

                        <app-button
                            primary
                            icon="upload"
                            text="Upload"
                            (click)="uploadFile()"
                            *ngIf="selectedFile && !errorMessage"
                        />

                        <div
                            *ngIf="errorMessage"
                            class="error-message"
                        >
                            {{ errorMessage }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <app-modal-loading-indicator />
    </div>
</div>
