import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';
import { AppModalLoadingIndicatorComponent } from '../app-modal-loading-indicator/app-modal-loading-indicator.component';

@Component({
    selector: 'app-confirmation-modal',
    standalone: true,
    imports: [NgIf, AppModalLoadingIndicatorComponent],
    templateUrl: 'app-confirmation-modal.component.html',
    styleUrls: ['app-confirmation-modal.component.scss'],
})
export class AppConfirmationModalComponent {
    @Input() title: string = '';
    @Input() message: string = '';
    @Input() waitForClose: boolean = false;

    @Output() yesClicked = new EventEmitter<void>();

    isLoading: boolean = false;

    constructor(public activeModal: NgbActiveModal) {}

    onYes() {
        if (this.waitForClose) {
            this.isLoading = true;
            this.yesClicked.emit();
        } else {
            this.closeModal(true);
        }
    }

    onNo() {
        this.closeModal(false);
    }

    closeModal(result: boolean) {
        this.isLoading = false;
        this.activeModal.close(result);
    }
}
