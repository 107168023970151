import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { DecimalPipe, NgForOf, NgIf } from '@angular/common';
import { StateService } from '../../../../services/StateService';
import { CountDto } from '../../../../models/dtos/CountDto';
import { format } from 'date-fns';
import { UserDto } from '../../../../models/dtos/UserDto';
import { AppButtonComponent } from '../../../../components/app-button/app-button.component';
import { LoadingIndicatorService } from '../../../../services/LoadingIndicatorService';
import { AssetRetentionService } from '../../../../services/AssetRetentionService';
import {
    AssetErrorsDto,
    CountRetentionHistoryDto,
} from '../../../../models/dtos/CountRetentionHistoryDto';
import { CleanTextPipe } from '../../../../pipes/CleanTextPipe';
import { AcknowledgementModalService } from '../../../../services/AcknowledgementModalService';
import { UtilityService } from '../../../../services/UtilityService';
import { ToastService } from '../../../../services/ToastService';

@Component({
    selector: 'app-asset-retention-history',
    standalone: true,
    imports: [NgIf, RouterModule, AppButtonComponent, DecimalPipe, NgForOf, CleanTextPipe],
    templateUrl: './asset-retention-history.component.html',
    styleUrls: ['./asset-retention-history.component.scss', '../main-shared.scss'],
})
export class AssetRetentionHistoryComponent implements OnInit {
    private readonly DATE_FORMAT: string = 'M/dd/yyyy';

    assetCountId: string | null = null;
    count: CountDto | null = null;
    users: UserDto[] = [];
    history: CountRetentionHistoryDto | null = null;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private stateService: StateService,
        private loadingIndicatorService: LoadingIndicatorService,
        private assetRetentionService: AssetRetentionService,
        private acknowledgementModalService: AcknowledgementModalService,
        private utilityService: UtilityService,
        private toastService: ToastService,
    ) {}

    async ngOnInit() {
        this.loadingIndicatorService.set('component', true, 'Initializing...');

        this.route.paramMap.subscribe(async (params) => {
            const assetCountId: string | null = params.get('id');
            if (assetCountId) {
                this.assetCountId = assetCountId;

                const counts: CountDto[] = this.stateService.get('counts') as CountDto[];
                const count = counts.find((count) => count.id === this.assetCountId);

                if (count) {
                    this.count = count;
                    this.users = this.stateService.get('users') as UserDto[];
                    this.history = await this.assetRetentionService.getCountRetentionHistory(
                        this.assetCountId,
                    );
                    this.loadingIndicatorService.set('component', false);
                } else {
                    await this.handleBadData();
                }
            } else {
                await this.handleBadData();
            }
        });
    }

    get dateStart(): string {
        if (!this.count) return '';
        const { startDate } = this.count;
        return format(startDate, this.DATE_FORMAT);
    }

    get dateEnd(): string {
        if (!this.count) return '';
        const { endDate } = this.count;
        return format(endDate, this.DATE_FORMAT);
    }

    get userStart(): string {
        if (!this.count) return '';
        const { startUser } = this.count;
        const user: UserDto | undefined = this.getUserByUserName(startUser);
        if (user) return `${user.firstName} ${user.lastName}`;
        return '--';
    }

    get userEnd(): string {
        if (!this.count) return '';
        const { endUser } = this.count;
        const user: UserDto | undefined = this.getUserByUserName(endUser);
        if (user) return `${user.firstName} ${user.lastName}`;
        return '--';
    }

    get invalidAssetsCount(): number {
        if (!this.count || !this.history?.invalidAssets) return 0;
        return this.history.invalidAssets.length;
    }

    get validAssetsCount(): number {
        if (!this.count || !this.history?.validAssets) return 0;
        return this.history.validAssets.length;
    }

    navigateToAssetRetention(): void {
        this.router.navigate(['/main/asset-retention']);
    }

    copyToClipboard(type: string): void {
        let text: string = '';

        if (type === 'invalid') {
            console.log('copyToClipboard | invalid');

            if (this.history?.invalidAssets?.length == 0) return;
            text = this.history?.invalidAssets
                ?.map((asset: AssetErrorsDto) => {
                    const assetNumber: string = asset.assetNumber;
                    const errorMessages: string = asset.errorMessages
                        .map((asset) => asset?.replace(/[\r\n]+/g, ' ').trim())
                        .join('; ');
                    return `${assetNumber}\t${errorMessages}\r\n`;
                })
                .join('') as string;
        }

        if (text)
            navigator.clipboard.writeText(text).then(async () => {
                await this.utilityService.sleep(50);
                this.toastService.show({
                    type: 'info',
                    message: `Invalid asset copied to clipboard.`,
                });
            });
    }

    private getUserByUserName(userName: string): UserDto | undefined {
        return this.users.find((user) => user.userName === userName);
    }

    private async handleBadData() {
        await this.router.navigate(['/main/asset-retention']);
        this.loadingIndicatorService.set('component', false);
        await this.acknowledgementModalService.open(
            'Count Not Found',
            'The asset count could not be found. Please try again.',
        );
    }
}
