import { Component, Input, OnInit } from '@angular/core';
import { AppButtonComponent } from '../../../../../../../components/app-button/app-button.component';
import { AppInputTextComponent } from '../../../../../../../components/app-input-text/app-input-text.component';
import { NgIf } from '@angular/common';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from '../../../../../../../services/UtilityService';
import { AssetRetentionService } from '../../../../../../../services/AssetRetentionService';
import { LoadingIndicatorService } from '../../../../../../../services/LoadingIndicatorService';
import { AppModalLoadingIndicatorComponent } from '../../../../../../../components/app-modal-loading-indicator/app-modal-loading-indicator.component';
import { Ticket } from '../../../../../../../models/classes/Ticket';
import { AppReadonlyFieldComponent } from '../../../../../../../components/app-readonly-field/app-readonly-field.component';

@Component({
    selector: 'app-ticket-details-modal',
    standalone: true,
    imports: [
        AppButtonComponent,
        AppInputTextComponent,
        NgIf,
        AppModalLoadingIndicatorComponent,
        AppReadonlyFieldComponent,
    ],
    templateUrl: './ticket-details-modal.component.html',
    styleUrls: [
        './ticket-details-modal.component.scss',
        '../../../../../../../styles/_common.scss',
        '../../../../../../../styles/_colors.scss',
    ],
})
export class TicketDetailsModalComponent implements OnInit {
    ticket: Ticket | null = null;
    assetNumberDisplay: string = '';

    @Input() ticketId: number | undefined;
    @Input() assetNumber: string | undefined;
    @Input() missingItemId: number | undefined;

    constructor(
        public activeModal: NgbActiveModal,
        private utilityService: UtilityService,
        private assetRetentionService: AssetRetentionService,
        private loadingIndicatorService: LoadingIndicatorService,
    ) {}

    async ngOnInit() {
        this.loadingIndicatorService.set('modal', true);
        this.assetNumberDisplay = this.assetNumber ?? '--';
        await this.getTicketDetails();
        await this.utilityService.sleep(350);
        this.loadingIndicatorService.set('modal', false);
    }

    get systemNoteDisplay(): string {
        return this.ticket?.systemNote ?? '';
    }

    closeModal(result: boolean): void {
        this.activeModal.close(result);
    }

    private async getTicketDetails() {
        if (!this.missingItemId) return;

        const ticketDto = await this.assetRetentionService.getTicketDetail(this.missingItemId);
        if (!ticketDto) return;

        this.ticket = new Ticket(ticketDto);
    }
}
