/* src/app/modules/main/components/asset-retention/components/manage-images-modal/manage-images-modal.component.scss */
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.flex-row,
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .close-button,
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .intro,
.manage-images-modal .modal-container > .modal-body > .image-upload-form,
.manage-images-modal .modal-container > .modal-body > .name-and-delete > .name,
.manage-images-modal .modal-container > .modal-body > .name-and-delete,
.manage-images-modal .modal-container > .modal-body > .add,
.manage-images-modal .modal-container > .modal-body > .viewer > .no-image,
.app-modal .modal-container > .modal-footer,
.manage-images-modal .modal-container > .modal-footer,
.app-modal .modal-container > .modal-header > .close,
.manage-images-modal .modal-container > .modal-header > .close,
.app-modal .modal-container > .modal-header,
.manage-images-modal .modal-container > .modal-header,
.app-modal,
.manage-images-modal,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column,
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .upload-body,
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form,
.app-modal .modal-container > .modal-body,
.manage-images-modal .modal-container > .modal-body,
.app-modal .modal-container,
.manage-images-modal .modal-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis,
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .file-selected {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .close-button,
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .intro,
.app-modal .modal-container > .modal-header > .title,
.manage-images-modal .modal-container > .modal-header > .title,
.app-modal .modal-container > .modal-header,
.manage-images-modal .modal-container > .modal-header,
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow,
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form,
.app-modal .modal-container,
.manage-images-modal .modal-container {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border,
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form,
.app-modal .modal-container,
.manage-images-modal .modal-container {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to bottom,
      rgba(37, 37, 37, 0) 0%,
      rgba(37, 37, 37, 0.2) 100%);
  width: auto;
  justify-content: center;
  height: 33px;
  min-height: 33px;
  max-height: 33px;
  line-height: 33px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  padding: 0 22px;
  font-size: 12.5px;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  color: #505050;
  background-color: #efefef;
  border: 1px solid #aaa;
}
.app-button .icon,
.app-toggle-button-group button .icon,
.app-multi-toggle-button-group button .icon,
.app-button-primary .icon {
  width: auto;
  margin: 0 5px 0 0;
  font-size: 15px;
  padding: 0;
  font-weight: 500;
}
.app-button[disabled],
.app-toggle-button-group button[disabled],
.app-multi-toggle-button-group button[disabled],
[disabled].app-button-primary {
  opacity: 0.4;
  cursor: default;
}
.app-button:not([disabled]):hover,
.app-toggle-button-group button:not([disabled]):hover,
.app-multi-toggle-button-group button:not([disabled]):hover,
.app-button-primary:not([disabled]):hover {
  background: #efefef;
}
.app-button:not([disabled]):active,
.app-toggle-button-group button:not([disabled]):active,
.app-multi-toggle-button-group button:not([disabled]):active,
.app-button-primary:not([disabled]):active {
  background-color: #ccc;
}
.app-button.app-button-medium,
.app-toggle-button-group button.app-button-medium,
.app-multi-toggle-button-group button.app-button-medium,
.app-button-medium.app-button-primary {
  padding: 0 17px;
  font-size: 11px;
  height: 29px;
  min-height: 29px;
  max-height: 29px;
  line-height: 29px;
}
.app-button.app-button-medium .icon,
.app-toggle-button-group button.app-button-medium .icon,
.app-multi-toggle-button-group button.app-button-medium .icon,
.app-button-medium.app-button-primary .icon {
  margin: 0 5px 0 0;
  font-size: 15px;
}
.app-button.app-button-small,
.app-toggle-button-group button.app-button-small,
.app-multi-toggle-button-group button.app-button-small,
.app-button-small.app-button-primary {
  padding: 0 12px;
  font-size: 10px;
  height: 23px;
  min-height: 23px;
  max-height: 23px;
  line-height: 23px;
}
.app-button.app-button-small .icon,
.app-toggle-button-group button.app-button-small .icon,
.app-multi-toggle-button-group button.app-button-small .icon,
.app-button-small.app-button-primary .icon {
  margin: 0 4px 0 0;
  font-size: 13px;
}
.app-button.icon-only,
.app-toggle-button-group button.icon-only,
.app-multi-toggle-button-group button.icon-only,
.icon-only.app-button-primary {
  padding: 0 15px;
}
.app-button.icon-only .icon,
.app-toggle-button-group button.icon-only .icon,
.app-multi-toggle-button-group button.icon-only .icon,
.icon-only.app-button-primary .icon {
  margin: 0;
}
.app-button.icon-only.app-button-medium,
.app-toggle-button-group button.icon-only.app-button-medium,
.app-multi-toggle-button-group button.icon-only.app-button-medium,
.icon-only.app-button-medium.app-button-primary {
  padding: 0 12px;
}
.app-button.icon-only.app-button-small,
.app-toggle-button-group button.icon-only.app-button-small,
.app-multi-toggle-button-group button.icon-only.app-button-small,
.icon-only.app-button-small.app-button-primary {
  padding: 0 10px;
}
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%);
  color: #fff;
  background-color: #0a51a3;
  border: 1px solid #0a51a3;
}
.app-button-primary:not([disabled]):hover,
.app-toggle-button-group button.selected:not([disabled]):hover,
.app-multi-toggle-button-group button.selected:not([disabled]):hover {
  background: #0a51a3;
}
.app-button-primary:not([disabled]):active,
.app-toggle-button-group button.selected:not([disabled]):active,
.app-multi-toggle-button-group button.selected:not([disabled]):active {
  background-color: #093b75;
}
.app-toggle-button-group,
.app-multi-toggle-button-group {
  display: inline-flex;
  border: 1px solid #ccc;
  border-radius: 2px;
  overflow: hidden;
}
.app-toggle-button-group button,
.app-multi-toggle-button-group button {
  font-size: 12px;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 0 !important;
}
.app-toggle-button-group button:first-child,
.app-multi-toggle-button-group button:first-child {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
.app-toggle-button-group button:last-child,
.app-multi-toggle-button-group button:last-child {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.app-toggle-button-group button:not(:last-child),
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button .icon {
  font-size: 14px;
  color: #505050;
  margin: 0 4px 0 0;
}
.app-multi-toggle-button-group button.selected {
  border-right: 1px solid #0a51a3;
}
.app-multi-toggle-button-group button.selected .icon {
  color: #fff;
  opacity: 0.85;
}
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.modal-shadow,
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form,
.app-modal .modal-container,
.manage-images-modal .modal-container {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border,
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form,
.app-modal .modal-container,
.manage-images-modal .modal-container {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.modal-body-shared,
.manage-images-modal .modal-container > .modal-body {
  justify-content: start !important;
  align-items: start !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  padding-bottom: 20px !important;
}
.app-modal,
.manage-images-modal {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
}
.app-modal .modal-container,
.manage-images-modal .modal-container {
  position: relative;
  width: 550px;
  height: 400px;
  background-color: #fff;
}
.app-modal .modal-container > .modal-header,
.manage-images-modal .modal-container > .modal-header {
  height: 65px;
}
.app-modal .modal-container > .modal-header > .title,
.manage-images-modal .modal-container > .modal-header > .title {
  width: calc(100% - 65px);
  text-indent: 25px;
  font-size: 19px;
  font-weight: 600;
}
.app-modal .modal-container > .modal-header > .close,
.manage-images-modal .modal-container > .modal-header > .close {
  justify-content: center;
  width: 65px;
  height: 100%;
  cursor: pointer;
}
.app-modal .modal-container > .modal-header > .close .icon,
.manage-images-modal .modal-container > .modal-header > .close .icon {
  font-size: 23px;
  font-weight: 300;
  opacity: 0.6;
}
.app-modal .modal-container > .modal-header > .close:hover,
.manage-images-modal .modal-container > .modal-header > .close:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.app-modal .modal-container > .modal-header > .close:hover .icon,
.manage-images-modal .modal-container > .modal-header > .close:hover .icon {
  font-weight: 500;
  opacity: 1;
}
.app-modal .modal-container > .modal-header > .close:active,
.manage-images-modal .modal-container > .modal-header > .close:active {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-modal .modal-container > .modal-body,
.manage-images-modal .modal-container > .modal-body {
  justify-content: start;
  align-items: center;
  padding: 7px 27px 7px 27px;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 13px;
  line-height: 17px;
}
.app-modal .modal-container > .modal-footer,
.manage-images-modal .modal-container > .modal-footer {
  justify-content: end;
  padding: 0 20px;
  height: 65px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background:
    linear-gradient(
      to top,
      rgba(0, 0, 0, 0.125),
      transparent);
}
.manage-images-modal .modal-container {
  width: 950px;
  height: auto;
}
.manage-images-modal .modal-container > .modal-body {
  position: relative;
  height: 500px;
  overflow: hidden;
}
.manage-images-modal .modal-container > .modal-body > .list {
  position: absolute;
  top: 0;
  right: unset;
  bottom: 70px;
  left: 0;
  border-top: 1px solid #757575;
  padding: 10px 0 25px 0;
  width: 235px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
  overflow-y: auto;
}
.manage-images-modal .modal-container > .modal-body > .list > .image {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #757575;
  margin: 0 0 10px 10px;
  cursor: pointer;
}
.manage-images-modal .modal-container > .modal-body > .viewer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 70px;
  left: 235px;
  border-top: 1px solid #757575;
}
.manage-images-modal .modal-container > .modal-body > .viewer > .image {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.manage-images-modal .modal-container > .modal-body > .viewer > .no-image {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  align-items: flex-start;
  justify-content: center;
  padding-top: 50px;
  font-size: 18px;
  font-weight: 400;
  color: #505050;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
}
.manage-images-modal .modal-container > .modal-body > .add {
  position: absolute;
  top: unset;
  right: unset;
  bottom: 0;
  left: 0;
  width: 235px;
  height: 70px;
  justify-content: center;
  border-top: 1px solid #757575;
  background:
    linear-gradient(
      to top,
      rgba(0, 0, 0, 0.125),
      transparent);
}
.manage-images-modal .modal-container > .modal-body > .name-and-delete {
  position: absolute;
  top: unset;
  right: 0;
  bottom: 0;
  left: 235px;
  height: 70px;
  border-top: 1px solid #757575;
  width: calc(100% - 235px);
  background:
    linear-gradient(
      to top,
      rgba(0, 0, 0, 0.125),
      transparent);
}
.manage-images-modal .modal-container > .modal-body > .name-and-delete > .name {
  width: auto;
  font-size: 15px;
  font-weight: 500;
  color: #093b75;
}
.manage-images-modal .modal-container > .modal-body > .name-and-delete > .delete {
  width: auto;
  margin: 0 20px 0 auto;
}
.manage-images-modal .modal-container > .modal-body > .image-upload-form {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form {
  position: relative;
  width: 325px;
  height: 130px;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border: 1px solid #757575;
}
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .intro {
  position: absolute;
  top: 0;
  right: 40px;
  bottom: unset;
  left: 0;
  white-space: nowrap;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  color: #505050;
  padding: 0 20px;
}
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .close-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: unset;
  left: unset;
  width: 40px;
  height: 40px;
  justify-content: center;
  cursor: pointer;
}
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .close-button .icon {
  font-size: 15px;
  font-weight: 400;
  color: #505050;
}
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .close-button:hover {
  background-color: rgba(80, 80, 80, 0.1);
}
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .upload-body {
  position: absolute;
  top: 40px;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: flex-start;
}
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .upload-body .select-image-button {
  margin-top: 20px;
}
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .file-selected {
  display: block;
  width: auto;
  max-width: 250px;
  font-size: 11px;
  font-weight: 500;
  color: #093b75;
  padding: 5px 0;
}
.manage-images-modal .modal-container > .modal-body > .image-upload-form .form .error-message {
  font-size: 12px;
  font-weight: 500;
  color: darkred;
}
/*# sourceMappingURL=manage-images-modal.component.css.map */
