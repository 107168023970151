<div class="retention-history-modal">
    <div class="modal-container">
        <div class="modal-header">
            <div class="title"> Retention History </div>
            <div
                class="close"
                aria-label="Close"
                (click)="closeModal(false)"
            >
                <span class="material-icons icon">close</span>
            </div>
        </div>

        <div class="modal-body">
            <p>
                Displayed below is a history of all assets that were sent from the Asset Count app
                into Asset Retention automatically at the conclusion of a given count. They are
                listed in reverse chronological order - newest to oldest.
            </p>

            <div class="history">
                <div
                    class="history-item"
                    *ngFor="let historyItem of history"
                >
                    <!--Header-->
                    <div class="header">
                        <div class="district">
                            {{ historyItem.districtCode }} - {{ historyItem.districtName }}
                        </div>
                        <div class="date-range">
                            {{ historyItem.startDate }} - {{ historyItem.endDate }}
                        </div>
                    </div>

                    <!--List-->
                    <div class="list">
                        <div class="results-lists">
                            <!--Invalid-->
                            <div class="results invalid">
                                <div class="title">
                                    <span class="material-icons-sharp icon">close</span>
                                    Invalid Assets
                                </div>
                                <div class="summary">
                                    {{ historyItem.invalidAssetCount | number }} assets failed to be
                                    added to retention.
                                </div>
                            </div>

                            <!--Valid-->
                            <div class="results valid">
                                <div class="title">
                                    <span class="material-icons-sharp icon">check</span>
                                    Valid Assets
                                </div>
                                <div class="summary">
                                    {{ historyItem.validAssetCount | number }} assets added to
                                    retention.
                                </div>
                            </div>

                            <!--Details-->
                            <div class="results-details">
                                <span (click)="routeToCountHistory(historyItem.assetCountId)">
                                    View Details
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <app-modal-loading-indicator />
    </div>
</div>
