import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { StateService } from '../../../../../services/StateService';
import { AssetCountService } from '../../../../../services/AssetCountService';
import { UtilityService } from '../../../../../services/UtilityService';
import { LoadingIndicatorService } from '../../../../../services/LoadingIndicatorService';
import { ActiveCountDto } from '../../../../../models/dtos/ActiveCountDto';
import { DecimalPipe, NgForOf, NgIf } from '@angular/common';
import { Session } from '../../../../../models/classes/Session';
import { AppTabsComponent } from '../../../../../components/app-tabs/app-tabs.component';
import { AppTabItem } from '../../../../../models/interfaces/AppTabItem';
import { format } from 'date-fns';
import { SessionCountsComponent } from './components/session-counts/session-counts.component';
import { SessionSectionComponent } from './components/session-section/session-section.component';
import { orderBy, parseInt, debounce } from 'lodash-es';
import {
    AssetStatus,
    AssetStatusLabels,
    IssueType,
    IssueTypeLabels,
    SessionAsset,
} from '../../../../../models/classes/SessionAsset';
import { AppSelectComponent } from '../../../../../components/app-select/app-select.component';
import { SelectOption } from '../../../../../models/classes/SelectOption';
import { FormsModule } from '@angular/forms';
import { AppButtonComponent } from '../../../../../components/app-button/app-button.component';
import { ExcelService } from '../../../../../services/ExcelService';
import { ReportExport } from '../../../../../models/interfaces/ReportExport';
import { AcknowledgementModalService } from '../../../../../services/AcknowledgementModalService';
import { ToastService } from '../../../../../services/ToastService';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActiveCountReportsModalComponent } from './components/active-count-reports-modal/active-count-reports-modal.component';
import { SelectReportTypes } from '../../../../../models/interfaces/SelectReportTypes';
import { AppMultiSelectComponent } from '../../../../../components/app-multi-select/app-multi-select.component';

@Component({
    selector: 'app-asset-count-live',
    standalone: true,
    imports: [
        NgIf,
        AppTabsComponent,
        SessionCountsComponent,
        SessionSectionComponent,
        NgForOf,
        AppSelectComponent,
        AppSelectComponent,
        DecimalPipe,
        FormsModule,
        AppButtonComponent,
        AppMultiSelectComponent,
    ],
    templateUrl: './asset-count-live.component.html',
    styleUrls: [
        './asset-count-live.component.scss',
        '../../main-shared.scss',
        '../../../../../styles/_tabs.scss',
    ],
})
export class AssetCountLiveComponent implements OnInit {
    protected readonly orderBy = orderBy;
    protected readonly AssetStatus = AssetStatus;

    activeCounts: ActiveCountDto[] = [];
    isLoaded: boolean = false;
    hasNoCounts: boolean = false;
    // Tabs
    appTabItems: AppTabItem[] = [];
    selectedAppTabItem: string = '';
    // Session
    session: Session | null = null;
    // Assets
    shouldShowStatusFilters: boolean = true;
    filteredAssets: SessionAsset[] = [];
    selectedTextFilter: string = '';
    statusFilterOptions: SelectOption[] = [];
    issueTypesFilterOptions: SelectOption[] = [];
    resolvedFilterOptions: SelectOption[] = [];
    selectedStatusFilter: string = AssetStatus.NotCounted.toString();
    selectedIssueTypeFilterOption: string = '';
    selectedResolvedFilterOption: string = '';
    // Assets / Filtering
    selectedFilterStatusOptions: string[] = [];

    @ViewChild('textFilter') textFilterElement: ElementRef | undefined;
    @ViewChild('locationsDiv') locationsDivElement: ElementRef | undefined;
    @ViewChild('usersDiv') usersDivElement: ElementRef | undefined;
    @ViewChild('assetDiv') assetDivElement: ElementRef | undefined;
    @ViewChild('statusesMultiSelect') statusesMultiSelect!: AppMultiSelectComponent;

    constructor(
        private assetCountService: AssetCountService,
        private utilityService: UtilityService,
        private loadingIndicatorService: LoadingIndicatorService,
        private toastService: ToastService,
        private stateService: StateService,
        private excelService: ExcelService,
        private acknowledgementModalService: AcknowledgementModalService,
        private modalService: NgbModal,
    ) {}

    async ngOnInit() {
        await this.initializeData();
    }

    get hostName(): string {
        if (!this.session) return '';
        const { firstName, lastName } = this.session.host;
        return `${firstName} ${lastName}`;
    }

    get hostEmail(): string {
        if (!this.session) return '';
        const { email } = this.session.host;
        return `<a href="mailto:${email}">${email}</a>`;
    }

    get dateStart(): string {
        if (!this.session) return '';
        const { dateStarted } = this.session;
        return format(dateStarted, 'M/dd/yyyy, h:mm a');
    }

    get shouldDisableFilterTextReportButton(): boolean {
        return this.filteredAssets?.length === 0;
    }

    handleTextChange(): void {
        this.debouncedProcessFilters();
    }

    handleClearTextFilters(): void {
        this.selectedTextFilter = '';
        this.statusesMultiSelect.clear();
        this.processTextFilters();
    }

    async handleTextFilterStatusChanges(newStatuses: string[]) {
        this.selectedFilterStatusOptions = newStatuses;
        this.processTextFilters();
    }

    async handleFilterTextGenerateReportClick() {
        await this.generateTextFilterReportForDownload();
    }

    async handleFilterToggle() {
        this.shouldShowStatusFilters = !this.shouldShowStatusFilters;

        if (this.shouldShowStatusFilters) {
            await this.applyStatusFilterChange();
        } else {
            this.handleTextChange();
            await this.utilityService.sleep(10);
            if (this.selectedTextFilter.trim().length === 0) this.filteredAssets = [];
            if (this.textFilterElement) this.textFilterElement.nativeElement.focus();
        }
    }

    async handleIssueTypeFilterChange(newFilterOption: string) {
        this.selectedIssueTypeFilterOption = newFilterOption;
        await this.applyStatusFilterChange();
    }

    async handleResolvedFilterChange(newFilterOption: string) {
        this.selectedResolvedFilterOption = newFilterOption;
        await this.applyStatusFilterChange();
    }

    async setSelectedTab(warehouseCode: string) {
        this.loadingIndicatorService.set('component', true);
        this.selectedAppTabItem = warehouseCode;
        await this.loadCount(warehouseCode);
    }

    async handleStatusFilterChange(newStatus: string) {
        this.selectedStatusFilter = newStatus;
        await this.applyStatusFilterChange();
        if (this.selectedStatusFilter === AssetStatus.HasIssue.toString()) {
            // Initialize default values.
            this.selectedIssueTypeFilterOption = this.issueTypesFilterOptions[0].value;
            this.selectedResolvedFilterOption = this.resolvedFilterOptions[0].value;
        }
    }

    async displayReportsModal() {
        const modalRef: NgbModalRef = this.modalService.open(ActiveCountReportsModalComponent);
        const result = await modalRef.result;

        if (result) {
            const reportTypes: SelectReportTypes = result as SelectReportTypes;
            await this.generateReportForDownload({
                warehouseCode: this.selectedAppTabItem,
                ...reportTypes,
            });
        }
    }

    private processTextFilters() {
        if (!this.session?.assets?.length) return;

        // Initial check to see if any filters are set. If none are set, set the filteredAssets list to [].
        if (
            this.selectedFilterStatusOptions.length === 0 &&
            this.selectedTextFilter.trim() === ''
        ) {
            this.filteredAssets = [];
            return;
        }

        let filteredAssets: any[] = [...this.session.assets];

        // Apply status filtering.
        if (this.selectedFilterStatusOptions.length !== 0) {
            filteredAssets = [
                ...filteredAssets.filter((asset) =>
                    this.selectedFilterStatusOptions.includes(asset.status.toString()),
                ),
            ];
        }

        // Apply text filtering.
        const selectedTextFilter: string = this.selectedTextFilter.trim();
        if (selectedTextFilter.length) {
            const filterTextLower: string = this.selectedTextFilter.trim().toLowerCase();
            const filterTextUpper: string = this.selectedTextFilter.trim().toUpperCase();

            filteredAssets = filteredAssets.filter(
                (asset) =>
                    asset.assetNumber.includes(filterTextUpper) ||
                    asset.description.toLowerCase().includes(filterTextLower),
            );
        }

        this.filteredAssets = [...filteredAssets];
    }

    private async generateTextFilterReportForDownload() {
        const warehouseCode: string = this.selectedAppTabItem;
        const data = this.prepareTextFilterDataForExport(warehouseCode);

        if (data) {
            const fileNameDate: string = format(new Date(), 'M-d-yyyy-hmm-a');
            const fileName: string = `${warehouseCode}-Active-Count-Filter-Report-${fileNameDate}`;
            this.excelService.generate(data, fileName);
            this.toastService.show({
                type: 'success',
                message: `Excel report file successfully generated.`,
            });
        } else {
            await this.acknowledgementModalService.open(
                'No Assets',
                'The report could not be generated at this time because corresponding assets were not found.',
            );
        }
    }

    private prepareTextFilterDataForExport(warehouseCode: string): string[][] | boolean {
        const filteredAssets: SessionAsset[] = [...this.filteredAssets];
        if (!filteredAssets) return false;

        const sectionHeaderLine = '--------------------------------';
        const headerKeys: string[] = ['assetNumber', 'status', 'ticket', 'description'];
        const headers: string[] = ['Asset Number', 'Status', 'Ticket #', 'Description'];
        const data: string[][] = [[warehouseCode], []];
        const buildSectionRows = (d: string[][], filters: string[], assets: SessionAsset[]) => {
            filters.forEach((filter: string) => d.push([filter]));
            d.push([sectionHeaderLine]);
            let rows: string[][] = assets.map((obj) =>
                headerKeys.map((key) => {
                    if (key === 'status') {
                        return AssetStatusLabels[obj[key]];
                    } else if (key === 'ticket') {
                        return obj[key] ? obj[key].toString() : '--';
                    }
                    // @ts-ignore
                    return obj[key];
                }),
            );
            d.push(headers);
            d.push(...rows);
            d.push([]);
        };

        let sectionHeaders: string[] = [];
        if (this.selectedTextFilter.trim().length) {
            sectionHeaders.push(`Text Filter: '${this.selectedTextFilter.trim()}'`);
        }
        if (this.selectedFilterStatusOptions.length) {
            const statusLabels: string = this.selectedFilterStatusOptions
                // @ts-ignore
                .map((value) => AssetStatusLabels[parseInt(value)])
                .join(', ');
            sectionHeaders.push(`Statuses: ${statusLabels}`);
        }

        buildSectionRows(data, sectionHeaders, filteredAssets);

        return data;
    }

    private async generateReportForDownload(reportExport: ReportExport) {
        const warehouseCode: string = this.selectedAppTabItem;
        const data = this.prepareDataForExport(reportExport);

        if (data) {
            const fileNameDate: string = format(new Date(), 'M-d-yyyy-hmm-a');
            const fileName: string = `${warehouseCode}-Active-Count-Report--${fileNameDate}`;
            this.excelService.generate(data, fileName);
            this.toastService.show({
                type: 'success',
                message: `Excel report file successfully generated.`,
            });
        } else {
            await this.acknowledgementModalService.open(
                'No Assets',
                'The report could not be generated at this time due to no assets being found for the given count.',
            );
        }
    }

    private prepareDataForExport(reportExport: ReportExport): string[][] | boolean {
        const sessionAssets: SessionAsset[] = [...(this.session?.assets as SessionAsset[])];
        if (!sessionAssets) return false;

        const sectionHeaderLine = '--------------------------------';
        const headerKeys: string[] = ['assetNumber', 'description'];
        const headers: string[] = ['Asset Number', 'Description'];
        const data: string[][] = [[reportExport.warehouseCode], []];
        const buildSectionRows = (d: string[][], header: string, assets: SessionAsset[]) => {
            d.push([header]);
            d.push([sectionHeaderLine]);
            let rows: string[][] = assets.map((obj) =>
                // @ts-ignore
                headerKeys.map((key) => obj[key]),
            );
            d.push(headers);
            d.push(...rows);
            d.push([]);
        };

        // Asset Status Sections
        reportExport.assetStatuses.forEach((assetStatus: AssetStatus) => {
            const sectionAssets: SessionAsset[] = [
                ...sessionAssets.filter((asset: SessionAsset) => asset.status === assetStatus),
            ];
            const sectionHeader: string = `${AssetStatusLabels[assetStatus]} Assets  (${sectionAssets.length})`;
            buildSectionRows(data, sectionHeader, sectionAssets);
        });

        // Issue Types Sections
        reportExport.issueTypes.forEach((issueType: IssueType) => {
            const sectionAssets: SessionAsset[] = [
                ...sessionAssets.filter(
                    (asset: SessionAsset) =>
                        asset.status === AssetStatus.HasIssue && asset.issueType === issueType,
                ),
            ];
            const sectionHeader: string = `Issue Assets - ${IssueTypeLabels[issueType]}  (${sectionAssets.length})`;
            buildSectionRows(data, sectionHeader, sectionAssets);
        });

        return data;
    }

    private async initializeData() {
        // Initialize status filter options.
        const customOrder = ['2', '1', '3', '4'];
        this.statusFilterOptions = Object.values(AssetStatus)
            .filter((value) => typeof value === 'number')
            .map((value) => ({
                text: AssetStatusLabels[value],
                value: (value as AssetStatus).toString(),
            }))
            .sort((a, b) => customOrder.indexOf(a.value) - customOrder.indexOf(b.value));

        // Initialize issue type list.
        this.issueTypesFilterOptions = Object.values(IssueType)
            .filter((value) => typeof value === 'number')
            .map((value) => ({
                text: IssueTypeLabels[value],
                value: (value as IssueType).toString(),
            }))
            .sort((a, b) => parseInt(a.value) - parseInt(b.value));
        this.issueTypesFilterOptions.unshift({
            text: '-- Any Issue Type --',
            value: '0',
        });
        // Initialize default value.
        this.selectedIssueTypeFilterOption = this.issueTypesFilterOptions[0].value;

        // Initialize issue type list.
        this.resolvedFilterOptions = [
            {
                text: '-- Any Resolution --',
                value: '0',
            },
            {
                text: 'Resolved',
                value: '1',
            },
            {
                text: 'Not Resolved',
                value: '2',
            },
        ];
        // Initialize default value.
        this.selectedResolvedFilterOption = this.resolvedFilterOptions[0].value;

        // Initialize active counts.
        this.activeCounts = await this.assetCountService.getActiveCounts();
        if (this.activeCounts.length) {
            const orderedCounts: ActiveCountDto[] = orderBy(
                this.activeCounts,
                ['dateStarted'],
                ['asc'],
            );
            this.appTabItems = orderedCounts.map((count) => {
                return {
                    name: count.warehouseCode,
                    text: `${count.warehouseCode} - ${count.name}`,
                };
            });
            this.selectedAppTabItem = orderedCounts[0].warehouseCode;
            this.isLoaded = true;

            // Load in count data for first selected count.
            this.loadingIndicatorService.set('component', true);
            await this.loadCount(this.selectedAppTabItem);
        } else {
            this.hasNoCounts = true;
        }
    }

    async handleIssuesFiltersReset() {
        // Initialize default values.
        this.selectedIssueTypeFilterOption = this.issueTypesFilterOptions[0].value;
        this.selectedResolvedFilterOption = this.resolvedFilterOptions[0].value;
        await this.applyStatusFilterChange();
    }

    private async loadCount(warehouseCode: string) {
        const activeCount = await this.assetCountService.getActiveCount(warehouseCode);
        this.session = null;
        await this.utilityService.sleep(1);
        this.session = new Session(activeCount, this.stateService);
        this.resetTabUiElements();
        await this.applyStatusFilterChange();

        await this.utilityService.sleep(350);
        this.loadingIndicatorService.set('component', false);
    }

    private async applyStatusFilterChange() {
        if (!this.session?.assets?.length) return;
        await this.utilityService.sleep(10);
        this.filteredAssets = [
            ...this.session.assets.filter(
                (asset: SessionAsset) => asset.status.toString() === this.selectedStatusFilter,
            ),
        ];

        // Conditionally apply issues filters.
        if (this.selectedStatusFilter === AssetStatus.HasIssue.toString()) {
            // Issue type filter.
            if (this.selectedIssueTypeFilterOption !== '0') {
                this.filteredAssets = [
                    ...this.filteredAssets.filter(
                        (asset: SessionAsset) =>
                            asset.issueType?.toString() === this.selectedIssueTypeFilterOption,
                    ),
                ];
            }

            // Resolved filter.
            if (this.selectedResolvedFilterOption !== '0') {
                const isResolved: boolean = this.selectedResolvedFilterOption === '1';
                this.filteredAssets = [
                    ...this.filteredAssets.filter(
                        (asset: SessionAsset) => asset.isResolved === isResolved,
                    ),
                ];
            }
        }
    }

    private resetTabUiElements(): void {
        // Scroll all scrollable divs to the top.
        // Locations
        let locationsDivScrollTop = this.locationsDivElement?.nativeElement?.scrollTop;
        if (locationsDivScrollTop !== undefined) locationsDivScrollTop = 0;
        // Users
        let usersDivScrollTop = this.usersDivElement?.nativeElement?.scrollTop;
        if (usersDivScrollTop !== undefined) usersDivScrollTop = 0;
        // Assets
        let assetDivScrollTop = this.assetDivElement?.nativeElement?.scrollTop;
        if (assetDivScrollTop !== undefined) assetDivScrollTop = 0;

        // Reset asset list primary filters.
        this.selectedTextFilter = '';
        this.selectedStatusFilter = AssetStatus.NotCounted.toString();

        // Reset issues filters.
        this.selectedIssueTypeFilterOption = this.issueTypesFilterOptions[0].value;
        this.selectedResolvedFilterOption = this.resolvedFilterOptions[0].value;
    }

    private debouncedProcessFilters = debounce(() => {
        this.processTextFilters();
    }, 300);
}
