import { AssetTypeType } from '../types/AssetTypeType';

export interface AssetRetentionDto {
    missingItemId: number;
    assetNumber: string;
    assetDescription: string;
    addedUser: string;
    addedDate: Date;
    statusId: MissingItemStatus;
    comment: string | undefined;
    assetCountId: string | undefined;
    assetCountNote: string | undefined;
    netBookValue: number | undefined;
    onTicketId: number | undefined;
    junkTicketId: number | undefined;
    resolvedUser: string | undefined;
    assetType: AssetTypeType | undefined;
    resolvedDate: Date | null;
    errorMessage: string | undefined;
    images?: MissingItemImage[];
}

export interface MissingItemImage {
    id: number;
    url: string;
}

export enum MissingItemStatus {
    Added = 0,
    Posted = 1,
    Resolved = 2,
    FailedToPost = 3,
    FailedToResolve = 4,
}
