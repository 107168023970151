export type SortDirection = 'asc' | 'desc' | '';

export interface AppTableHeader {
    name: string;
    text: string;
    width?: string;
    align?: 'left' | 'center' | 'right';
    isSortable?: boolean;
    direction?: SortDirection;
}

export function CreateHeader(
    name: string,
    text: string,
    options: Partial<Omit<AppTableHeader, 'name' | 'text'>> = {},
): AppTableHeader {
    return {
        name,
        text,
        isSortable: options.isSortable ?? true,
        width: options.width,
        align: options.align ?? 'left',
        direction: options.direction || '',
    };
}

export interface DefaultSort {
    column: string;
    direction: 'asc' | 'desc';
}
