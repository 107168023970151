import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from '../../services/LoadingIndicatorService';
import { AsyncPipe, NgIf } from '@angular/common';
import { LoadingState } from '../../models/interfaces/LoadingState';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-component-loading-indicator',
    template: `
        <div
            class="app-component-loading-indicator"
            *ngIf="loadingState$ | async as state"
            [class.show]="state.isLoading"
            [class.hide]="!state.isLoading"
        >
            <div class="container">
                <div class="spinner"></div>
                {{ state.text }}
            </div>
        </div>
    `,
    standalone: true,
    imports: [AsyncPipe, NgIf],
    styleUrl: 'app-component-loading-indicator.component.scss',
})
export class AppComponentLoadingIndicatorComponent implements OnInit {
    loadingState$!: Observable<LoadingState>;

    constructor(private loadingIndicatorService: LoadingIndicatorService) {}

    ngOnInit() {
        this.loadingState$ = this.loadingIndicatorService.componentIndicator$;
    }
}
