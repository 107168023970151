/* src/app/modules/main/main.component.scss */
.flex-row,
.main .menu .option > .option-text,
.main .menu .option > .option-icon,
.main .menu .option,
.main header .logged-in-user .logout,
.main header .logged-in-user,
.main header,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column,
.main .menu {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
.main .menu,
.main header .logged-in-user .logout,
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.flex-row,
.main .menu .option > .option-text,
.main .menu .option > .option-icon,
.main .menu .option,
.main header .logged-in-user .logout,
.main header .logged-in-user,
.main header,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column,
.main .menu {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
.main .menu,
.main header .logged-in-user .logout,
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to bottom,
      rgba(37, 37, 37, 0) 0%,
      rgba(37, 37, 37, 0.2) 100%);
  width: auto;
  justify-content: center;
  height: 33px;
  min-height: 33px;
  max-height: 33px;
  line-height: 33px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  padding: 0 22px;
  font-size: 12.5px;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  color: #505050;
  background-color: #efefef;
  border: 1px solid #aaa;
}
.app-button .icon,
.app-toggle-button-group button .icon,
.app-multi-toggle-button-group button .icon,
.app-button-primary .icon {
  width: auto;
  margin: 0 5px 0 0;
  font-size: 15px;
  padding: 0;
  font-weight: 500;
}
.app-button[disabled],
.app-toggle-button-group button[disabled],
.app-multi-toggle-button-group button[disabled],
[disabled].app-button-primary {
  opacity: 0.4;
  cursor: default;
}
.app-button:not([disabled]):hover,
.app-toggle-button-group button:not([disabled]):hover,
.app-multi-toggle-button-group button:not([disabled]):hover,
.app-button-primary:not([disabled]):hover {
  background: #efefef;
}
.app-button:not([disabled]):active,
.app-toggle-button-group button:not([disabled]):active,
.app-multi-toggle-button-group button:not([disabled]):active,
.app-button-primary:not([disabled]):active {
  background-color: #ccc;
}
.app-button.app-button-medium,
.app-toggle-button-group button.app-button-medium,
.app-multi-toggle-button-group button.app-button-medium,
.app-button-medium.app-button-primary {
  padding: 0 17px;
  font-size: 11px;
  height: 29px;
  min-height: 29px;
  max-height: 29px;
  line-height: 29px;
}
.app-button.app-button-medium .icon,
.app-toggle-button-group button.app-button-medium .icon,
.app-multi-toggle-button-group button.app-button-medium .icon,
.app-button-medium.app-button-primary .icon {
  margin: 0 5px 0 0;
  font-size: 15px;
}
.app-button.app-button-small,
.app-toggle-button-group button.app-button-small,
.app-multi-toggle-button-group button.app-button-small,
.app-button-small.app-button-primary {
  padding: 0 12px;
  font-size: 10px;
  height: 23px;
  min-height: 23px;
  max-height: 23px;
  line-height: 23px;
}
.app-button.app-button-small .icon,
.app-toggle-button-group button.app-button-small .icon,
.app-multi-toggle-button-group button.app-button-small .icon,
.app-button-small.app-button-primary .icon {
  margin: 0 4px 0 0;
  font-size: 13px;
}
.app-button.icon-only,
.app-toggle-button-group button.icon-only,
.app-multi-toggle-button-group button.icon-only,
.icon-only.app-button-primary {
  padding: 0 15px;
}
.app-button.icon-only .icon,
.app-toggle-button-group button.icon-only .icon,
.app-multi-toggle-button-group button.icon-only .icon,
.icon-only.app-button-primary .icon {
  margin: 0;
}
.app-button.icon-only.app-button-medium,
.app-toggle-button-group button.icon-only.app-button-medium,
.app-multi-toggle-button-group button.icon-only.app-button-medium,
.icon-only.app-button-medium.app-button-primary {
  padding: 0 12px;
}
.app-button.icon-only.app-button-small,
.app-toggle-button-group button.icon-only.app-button-small,
.app-multi-toggle-button-group button.icon-only.app-button-small,
.icon-only.app-button-small.app-button-primary {
  padding: 0 10px;
}
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%);
  color: #fff;
  background-color: #0a51a3;
  border: 1px solid #0a51a3;
}
.app-button-primary:not([disabled]):hover,
.app-toggle-button-group button.selected:not([disabled]):hover,
.app-multi-toggle-button-group button.selected:not([disabled]):hover {
  background: #0a51a3;
}
.app-button-primary:not([disabled]):active,
.app-toggle-button-group button.selected:not([disabled]):active,
.app-multi-toggle-button-group button.selected:not([disabled]):active {
  background-color: #093b75;
}
.app-toggle-button-group,
.app-multi-toggle-button-group {
  display: inline-flex;
  border: 1px solid #ccc;
  border-radius: 2px;
  overflow: hidden;
}
.app-toggle-button-group button,
.app-multi-toggle-button-group button {
  font-size: 12px;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 0 !important;
}
.app-toggle-button-group button:first-child,
.app-multi-toggle-button-group button:first-child {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
.app-toggle-button-group button:last-child,
.app-multi-toggle-button-group button:last-child {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.app-toggle-button-group button:not(:last-child),
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button .icon {
  font-size: 14px;
  color: #505050;
  margin: 0 4px 0 0;
}
.app-multi-toggle-button-group button.selected {
  border-right: 1px solid #0a51a3;
}
.app-multi-toggle-button-group button.selected .icon {
  color: #fff;
  opacity: 0.85;
}
.main {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
  min-width: 1700px;
  min-height: 650px;
}
.main header {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: unset;
  left: 0px;
  width: 100%;
  min-width: 1000px;
  height: 82px;
  padding: 0 20px;
  background-color: #2a2a2a;
  z-index: 100;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}
.main header img {
  height: 45.1px;
}
.main header .title {
  font-size: 23px;
  font-weight: 500;
  color: rgba(0, 148, 255, 0.9);
  margin: 0 15px;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}
.main header .title span {
  font-size: 23px;
  font-weight: 400;
  margin: 0 0 0 2px;
  color: rgba(255, 255, 255, 0.9);
  font-style: italic;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.4);
}
.main header .logged-in-user {
  justify-content: end;
  margin: 0 0 0 auto;
  min-width: 200px;
  height: 100%;
  color: #efefef;
  font-size: 13px;
  font-weight: 300;
}
.main header .logged-in-user .icon.person {
  font-size: 21px;
  margin: 0 5px 0 7px;
}
.main header .logged-in-user .separator {
  font-size: 31px;
  font-weight: 100;
  color: rgba(204, 204, 204, 0.5);
  margin: 0;
  padding: 0 2px;
}
.main header .logged-in-user .logout {
  justify-content: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
}
.main header .logged-in-user .logout .icon {
  font-size: 18px;
}
.main header .logged-in-user .logout:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
}
.main header .logged-in-user .logout:active {
  background-color: rgba(255, 255, 255, 0.1);
}
.main .menu {
  position: absolute;
  top: 57px;
  right: unset;
  bottom: 0px;
  left: 0px;
  box-shadow: 4px 0 4px -4px rgba(0, 0, 0, 0.15);
  width: 250px;
  padding: 40px 25px 15px 20px;
  z-index: 90;
  border-right: 1px solid #d9d9d9;
  background-color: #efefef;
}
.main .menu .option {
  width: 100%;
  height: 33px;
  cursor: pointer;
}
.main .menu .option > .option-icon {
  width: 33px;
  height: 100%;
  justify-content: center;
}
.main .menu .option > .option-icon .icon {
  font-size: 17px;
}
.main .menu .option > .option-text {
  width: calc(100% - 33px);
  height: 100%;
  font-size: 13px;
  font-weight: 400;
}
.main .menu .option.selected {
  background-color: #0a51a3;
  color: #fff;
  text-shadow: 0.5px 0.5px 1px rgba(0, 0, 0, 0.6);
}
.main .menu .option:not(.selected):hover {
  background-color: rgba(0, 0, 0, 0.15);
}
.main .body {
  position: absolute;
  top: 82px;
  right: 0px;
  bottom: 0px;
  left: 250px;
  overflow: hidden;
}
/*# sourceMappingURL=main.component.css.map */
