import { ISessionAsset, SessionAsset } from './SessionAsset';
import { StateService } from '../../services/StateService';

export interface ISession {
    id: string;
    host: SessionUser;
    district: SessionDistrict;
    dateStarted: string;
    locations: string[];
    lastLoggedEvent: Date;
    users: SessionUser[];
    assets: any;
}

export class Session implements ISession {
    id: string;
    host: SessionUser;
    district: SessionDistrict;
    dateStarted: string;
    locations: string[];
    lastLoggedEvent: Date;
    users: SessionUser[];
    assets: any[] | undefined;
    shouldSync: boolean;

    constructor(session: ISession, stateService: StateService) {
        this.id = session.id;
        this.host = session.host;
        this.district = session.district;
        this.dateStarted = session.dateStarted;
        this.locations = session.locations;
        this.lastLoggedEvent = new Date();
        this.assets = Object.values(session.assets)?.map(
            // @ts-ignore
            (asset: ISessionAsset) => new SessionAsset(asset, stateService),
        );
        this.users = session.users;
        this.shouldSync = true;
    }
}

export interface SessionDistrict {
    code: string;
    description: string;
}

export interface SessionUser {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
}
