import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppModalLoadingIndicatorComponent } from '../../../../../../../components/app-modal-loading-indicator/app-modal-loading-indicator.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { UtilityService } from '../../../../../../../services/UtilityService';
import { LoadingIndicatorService } from '../../../../../../../services/LoadingIndicatorService';
import { AssetRetentionService } from '../../../../../../../services/AssetRetentionService';
import { AppTextareaComponent } from '../../../../../../../components/app-textarea/app-textarea.component';

@Component({
    selector: 'app-edit-comments-modal',
    standalone: true,
    imports: [AppModalLoadingIndicatorComponent, FormsModule, AppTextareaComponent],
    templateUrl: './edit-comments-modal.component.html',
    styleUrl: './edit-comments-modal.component.scss',
})
export class EditCommentsModalComponent implements OnInit {
    @Input() missingItemId: number | null = null;

    @Output() saveClicked = new EventEmitter<string | boolean>();

    originalComments: string = '';
    updatedComments: string = '';

    constructor(
        public activeModal: NgbActiveModal,
        private loadingIndicatorService: LoadingIndicatorService,
        private assetRetentionService: AssetRetentionService,
        private utilityService: UtilityService,
    ) {}

    async ngOnInit() {
        this.loadingIndicatorService.set('modal', true);
        const response = await this.assetRetentionService.getComment(this.missingItemId as number);
        this.originalComments = response.comment;
        this.updatedComments = response.comment;
        await this.utilityService.sleep(350);
        this.loadingIndicatorService.set('modal', false);
    }

    onSave(): void {
        // Check to see if there were changed; if so emit changes.
        if (this.haveCommentsChanged()) {
            this.activeModal.close(this.updatedComments?.trim());
        } else {
            this.activeModal.close(false);
        }
    }

    closeModal(result: boolean): void {
        this.activeModal.close(result);
    }

    private haveCommentsChanged(): boolean {
        return this.originalComments?.trim() !== this.updatedComments?.trim();
    }
}
