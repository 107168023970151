import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadingState } from '../models/interfaces/LoadingState';

const defaultLoadingText: string = 'Loading...';

@Injectable({
    providedIn: 'root',
})
export class LoadingIndicatorService {
    private fullScreenState = new BehaviorSubject<LoadingState>({
        isLoading: false,
        text: defaultLoadingText,
    });
    private componentState = new BehaviorSubject<LoadingState>({
        isLoading: false,
        text: defaultLoadingText,
    });
    private modalState = new BehaviorSubject<LoadingState>({
        isLoading: false,
        text: defaultLoadingText,
    });

    fullScreenIndicator$ = this.fullScreenState.asObservable();
    componentIndicator$ = this.componentState.asObservable();
    modalIndicator$ = this.modalState.asObservable();

    set(type: 'fullScreen' | 'component' | 'modal', isLoading: boolean, text: string = defaultLoadingText) {
        const currentState = this[`${type}State`].getValue();
        const newText = isLoading ? text || currentState.text : currentState.text;
        this[`${type}State`].next({ isLoading, text: newText });

        if (!isLoading) {
            setTimeout(() => {
                this[`${type}State`].next({ isLoading: false, text: defaultLoadingText });
            }, 500);
        }
    }
}
