import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { MainComponent } from './modules/main/main.component';
import { HomeComponent } from './modules/main/components/home/home.component';
import { AssetRetentionComponent } from './modules/main/components/asset-retention/asset-retention.component';
import { ReportsComponent } from './modules/main/components/reports/reports.component';
import { AssetCountLiveComponent } from './modules/main/components/asset-count-live/asset-count-live.component';
import { AuthComponent } from './modules/auth/auth.component';
import { AuthGuard } from '../auth-guard/auth.guard';
import { AssetRetentionHistoryComponent } from './modules/main/asset-retention-history/asset-retention-history.component';

export const routes: Routes = [
    {
        path: 'login',
        component: AuthComponent, // Login page; always accessible.
    },
    {
        path: 'main',
        component: MainComponent,
        canActivate: [MsalGuard], // Protect /main with MSAL authentication.
        children: [
            // Special route/component that can be accessed through a direct
            // URL path (e.g. from Email) or through traditional routing.
            {
                path: 'asset-retention-history/:id',
                component: AssetRetentionHistoryComponent,
                canActivate: [AuthGuard],
            },
            // Default child of /main.
            {
                path: '',
                component: HomeComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'asset-retention',
                component: AssetRetentionComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'reports',
                component: ReportsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'asset-count-live',
                component: AssetCountLiveComponent,
                canActivate: [AuthGuard],
            },
            // Redirect /main/home to /main.
            {
                path: 'home',
                redirectTo: '',
                pathMatch: 'full',
            },
        ],
    },
    // Default route redirects to /login.
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    // Catch-all redirects to /login.
    {
        path: '**',
        redirectTo: 'login',
    },
];
