/* src/components/app-toast/app-toast.component.scss */
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.flex-row,
.app-toast {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-toast {
  background:
    linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.1) 100%);
  width: auto;
  height: auto;
  max-width: 300px;
  padding: 10px 22px;
  border-radius: 1px;
  color: white;
  font-size: 12px;
  font-weight: 500;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease-in-out;
  z-index: 100;
}
.app-toast.success {
  background-color: #279a2c;
}
.app-toast.error {
  background-color: #d52f23;
}
.app-toast.info {
  background-color: #246ac2;
}
.app-toast.warning {
  background-color: #da7515;
}
.app-toast.top-left {
  position: absolute;
  top: 10px;
  right: unset;
  bottom: unset;
  left: 10px;
}
.app-toast.top-center {
  position: absolute;
  top: 10px;
  right: unset;
  bottom: unset;
  left: 50%;
  transform: translateX(-50%);
  max-width: 450px;
}
.app-toast.top-right {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: unset;
  left: unset;
}
.app-toast.bottom-left {
  position: absolute;
  top: unset;
  right: unset;
  bottom: 10px;
  left: 10px;
}
.app-toast.bottom-center {
  position: absolute;
  top: unset;
  right: unset;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.app-toast.bottom-right {
  position: absolute;
  top: unset;
  right: 10px;
  bottom: 10px;
  left: unset;
}
/*# sourceMappingURL=app-toast.component.css.map */
