<div class="edit-comments-modal">
    <div class="modal-container">
        <div class="modal-header">
            <div class="title"> Edit Comments </div>
            <div
                class="close"
                aria-label="Close"
                (click)="closeModal(false)"
            >
                <span class="material-icons icon">close</span>
            </div>
        </div>

        <div class="modal-body">
            <p> Within the field below, you can update the comments associated with this asset. </p>
            <app-textarea
                [(value)]="updatedComments"
                width="100%"
                height="100px"
            />
        </div>

        <div class="modal-footer">
            <button
                type="button"
                class="app-button-primary"
                (click)="onSave()"
            >
                Save
            </button>
        </div>
        <app-modal-loading-indicator />
    </div>
</div>
