import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { last, takeRight, join } from 'lodash-es';
import { LoadingIndicatorService } from '../../../../../services/LoadingIndicatorService';
import { ReportsService } from '../../../../../services/ReportsService';
import { SelectOption } from '../../../../../models/classes/SelectOption';
import { AppSelectComponent } from '../../../../../components/app-select/app-select.component';
import { AppButtonComponent } from '../../../../../components/app-button/app-button.component';
import { StateService } from '../../../../../services/StateService';
import { CompletedCount } from '../../../../../models/classes/CompletedCount';

@Component({
    selector: 'app-reports',
    standalone: true,
    imports: [FormsModule, AppSelectComponent, AppButtonComponent],
    templateUrl: './reports.component.html',
    styleUrls: ['reports.component.scss', '../../main-shared.scss'],
})
export class ReportsComponent implements OnInit {
    constructor(
        private reportService: ReportsService,
        private loadingIndicatorService: LoadingIndicatorService,
        private stateService: StateService,
    ) {}

    private readonly reportTypes = {
        summary: {
            prefix: 'Asset-Count-Summary-Report',
            method: 'getSummaryReport',
        },
        finalState: {
            prefix: 'Asset-Count-Final-State-Report',
            method: 'getFinalStateReport',
        },
    };

    completedCounts: CompletedCount[] = [];
    completedCountsOptions: SelectOption[] = [];
    selectedCountId: string | undefined = undefined;

    async ngOnInit() {
        await this.initializeCounts();
    }

    async initializeCounts() {
        this.completedCounts = this.stateService.get('completedCounts') as CompletedCount[];
        this.initializeCountOptions();

        if (this.completedCountsOptions.length)
            this.selectedCountId = last(this.completedCountsOptions)?.value ?? undefined;
    }

    async downloadReport(reportTypeName: string) {
        this.loadingIndicatorService.set('component', true, 'Downloading...');
        // @ts-ignore
        const { prefix, method } = this.reportTypes[reportTypeName];
        const completedCount = this.getSelectedCompletedCount();

        if (completedCount) {
            const fileName = `${this.getReportFileName(prefix, completedCount)}.xlsx`;
            const { id, warehouseCode } = completedCount;

            // @ts-ignore
            this.reportService[method](id, warehouseCode).subscribe(
                (blob: Blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    link.click();
                    window.URL.revokeObjectURL(url);
                    this.loadingIndicatorService.set('component', false);
                },
                // @ts-ignore
                (error) => {
                    this.loadingIndicatorService.set('component', false);
                    console.error('Download failed:', error);
                    alert(`Download Failed\r\n\r\n${error}`);
                },
            );
        } else {
            this.loadingIndicatorService.set('component', false);
            alert(`Report ID Invalid: ${this.selectedCountId}`);
        }
    }

    private initializeCountOptions(): void {
        this.completedCountsOptions = this.completedCounts.map((count) => ({
            value: count.id,
            text: count.name,
        }));
    }

    private getReportFileName(reportName: string, completedCount: CompletedCount): string {
        const warehouseCode = completedCount.warehouseCode;
        const date = join(takeRight(completedCount.fileNameSuffix.split('-'), 3), '-');

        return `${reportName}-${warehouseCode}-${date}`;
    }

    private getSelectedCompletedCount(): CompletedCount | undefined {
        return this.completedCounts.find((count) => count.id === this.selectedCountId);
    }
}
