<div class="main">
    <!--Header-->
    <header>
        <img
            src="assets/logos/tts-logo.png"
            alt="TTS Logo"
        />
        <span class="title"> TTS<span>Web</span> </span>

        <div class="logged-in-user">
            {{ getUserFullName }}
            <span class="material-icons-sharp person icon">person</span>
            <span class="separator">|</span>
            <div
                class="logout"
                (click)="handleLogout()"
            >
                <span class="material-icons-sharp icon">logout</span>
            </div>
        </div>
    </header>

    <!--Menu-->
    <div class="menu">
        <div
            class="option"
            *ngFor="let option of menuOptions"
            [ngClass]="{ selected: selectedOption === option.name }"
            (click)="setSelectedOption(option.name)"
        >
            <div class="option-icon">
                <span
                    [ngClass]="option.iconCss"
                    class="icon"
                >
                    {{ option.icon }}
                </span>
            </div>
            <div class="option-text">
                {{ option.text }}
            </div>
        </div>
    </div>

    <!--Body-->
    <div class="body">
        <router-outlet *ngIf="isAppDataLoaded" />
        <app-component-loading-indicator />
        <app-toast />
    </div>
</div>
