/* src/app/modules/main/components/home/home.component.scss */
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
p {
  font-size: 19px;
  font-weight: 400;
  color: #093b75;
  margin: 25px 0 0 25px;
  padding: 0;
  line-height: 1.3;
  font-style: italic;
}
p span {
  font-style: normal;
  padding: 2px 0 0 12px;
  font-size: 13px;
  font-weight: 300;
  color: #757575;
}
/*# sourceMappingURL=home.component.css.map */
