import { Component, Input, OnInit } from '@angular/core';
import { NgIf } from '@angular/common';
import { AppModalLoadingIndicatorComponent } from '../../../../../../../components/app-modal-loading-indicator/app-modal-loading-indicator.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { AppButtonComponent } from '../../../../../../../components/app-button/app-button.component';
import { UtilityService } from '../../../../../../../services/UtilityService';
import { AssetRetentionService } from '../../../../../../../services/AssetRetentionService';
import { AssetRetentionDto } from '../../../../../../../models/dtos/AssetRetentionDto';
import { AppInputTextComponent } from '../../../../../../../components/app-input-text/app-input-text.component';
import { StateService } from '../../../../../../../services/StateService';
import { User } from '../../../../../../../models/classes/User';
import { HttpError } from '../../../../../../../models/interfaces/HttpError';
import { join } from 'lodash-es';

@Component({
    selector: 'app-edit-comments-modal',
    standalone: true,
    imports: [
        NgIf,
        AppModalLoadingIndicatorComponent,
        FormsModule,
        AppButtonComponent,
        AppInputTextComponent,
    ],
    templateUrl: './add-asset-modal.component.html',
    styleUrls: [
        './add-asset-modal.component.scss',
        '../../../../../../../styles/_controls.scss',
        '../../../../../../../styles/_common.scss',
        '../../../../../../../styles/_loading-indicator-inline.scss',
        '../../../../../../../styles/_mixins.scss',
    ],
})
export class AddAssetModalComponent {
    assetNumber: string = '';
    shouldShowFormOverlay: boolean = false;
    shouldShowLoadingIndicator: boolean = false;
    errorMessage: string = '';

    @Input() districtCode: string = '';
    @Input() assetCountId: string = '';

    constructor(
        public activeModal: NgbActiveModal,
        private utilityService: UtilityService,
        private assetRetentionService: AssetRetentionService,
        private stateService: StateService,
    ) {}

    async handleAddAssetClick() {
        this.errorMessage = '';
        const assetNumber: string = this.assetNumber?.trim()?.toUpperCase();

        if (assetNumber?.length < 4) return;

        this.shouldShowLoadingIndicator = true;
        this.shouldShowFormOverlay = true;

        // HTTP call to add the asset to retention for the given district.
        const user: User = this.stateService.get('user') as User;
        const districtCode: string = this.districtCode;
        await this.utilityService.sleep(350);

        try {
            const response: AssetRetentionDto | HttpError =
                await this.assetRetentionService.addAsset(
                    assetNumber,
                    districtCode,
                    user.username,
                    this.assetCountId,
                );
            this.activeModal.close(response);
        } catch (error: any) {
            const httpError: HttpError = { ...error } as HttpError;
            this.shouldShowLoadingIndicator = false;
            this.shouldShowFormOverlay = false;

            // Prepare error message display.
            if (httpError.messages.length === 1) {
                this.errorMessage = httpError.messages[0];
            } else {
                const lineItems = httpError.messages.map((message) => {
                    return `<li>${message}</li>`;
                });
                this.errorMessage = `<ul>${join(lineItems, '')}</ul>`;
            }
        }
    }

    handleTextChange(): void {
        this.errorMessage = '';
    }

    closeModal(result: boolean): void {
        this.activeModal.close(result);
    }
}
