/* src/app/modules/main/components/asset-count-live/components/session-counts/session-counts.component.scss */
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.flex-row,
.session-counts .count-value > .value,
.session-counts .count-value > .label,
.session-counts .count-value {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.session-counts {
  width: 180px;
  height: auto;
  margin: 0 15px 0 0;
}
.session-counts .total {
  font-size: 11px;
  font-weight: 600;
  color: #252525;
  margin: 0 0 4px 0;
}
.session-counts .count-value {
  font-size: 11px;
  font-weight: 500;
  color: #252525;
  margin: 0 0 4px 0;
}
.session-counts .count-value > .label {
  width: 100px;
  height: 100%;
  padding: 0 0 0 10px;
}
.session-counts .count-value > .value {
  justify-content: flex-end;
  width: calc(100% - 100px);
  height: 100%;
  font-weight: 400;
  padding: 0 0 0 0;
}
/*# sourceMappingURL=session-counts.component.css.map */
