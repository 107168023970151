import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { firstValueFrom } from 'rxjs';
import { ActiveCountDto } from '../models/dtos/ActiveCountDto';
import { User } from '../models/classes/User';
import { StateService } from './StateService';

@Injectable({
    providedIn: 'root',
})
export class AssetCountService {
    private urls = environment.endpoints;

    constructor(
        private http: HttpClient,
        private stateService: StateService,
    ) {}

    async getActiveCounts(): Promise<ActiveCountDto[]> {
        const url = this.urls.getActiveCounts;
        return await firstValueFrom(this.http.get<ActiveCountDto[]>(url));
    }

    async getActiveCount(warehouseCode: string): Promise<any> {
        const user = this.stateService.get('user') as User;
        let url = this.urls.getActiveCount;
        url = url.replace('{0}', user.username);
        url = url.replace('{1}', warehouseCode);
        return await firstValueFrom(this.http.get<any>(url));
    }
}
