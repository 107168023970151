<div class="add-asset-modal">
    <div class="modal-container">
        <div class="modal-header">
            <div class="title"> Add Asset </div>
            <div
                class="close"
                aria-label="Close"
                (click)="closeModal(false)"
            >
                <span class="material-icons icon">close</span>
            </div>
        </div>

        <div class="modal-body">
            <p> Search for an asset to add to Asset Retention using the field below. </p>

            <div class="asset-number-search">
                <div
                    class="overlay"
                    *ngIf="shouldShowFormOverlay"
                ></div>
                <div class="input">
                    <app-input-text
                        [(value)]="assetNumber"
                        [showClear]="true"
                        [showAllCaps]="true"
                        (valueChange)="handleTextChange()"
                        placeholder="Enter asset number..."
                    />
                </div>

                <div class="button">
                    <app-button
                        primary
                        icon="add"
                        width="55px"
                        title="Add Asset"
                        (click)="handleAddAssetClick()"
                    />
                </div>
            </div>

            <!--Loading Indicator-->
            <div
                class="loader"
                *ngIf="shouldShowLoadingIndicator"
            >
                <div class="app-loading-indicator-inline"></div>
            </div>

            <!--Error Message-->
            <div
                *ngIf="errorMessage.length"
                class="error-message"
            >
                <div class="check-icon">
                    <span class="material-icons-sharp icon">close</span>
                </div>
                <div
                    class="message"
                    [innerHTML]="errorMessage"
                >
                </div>
            </div>
        </div>
    </div>
</div>
