/* src/app/modules/main/components/asset-retention/components/post-to-junk-ticket-modal/post-to-junk-ticket-modal.component.scss */
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.flex-row,
.selected-tab .results .title .copied-notification,
.selected-tab,
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .text,
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .notes-footer > .character-count,
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .notes-footer > .min-max,
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .notes-footer,
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .list .asset-number,
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes,
.app-results-view .assets-list .list-asset .list-asset-reason,
.selected-tab .results .assets-list .list-asset .list-asset-reason,
.app-results-view .assets-list .list-asset .list-asset-number,
.selected-tab .results .assets-list .list-asset .list-asset-number,
.app-results-view .assets-list .list-asset,
.selected-tab .results .assets-list .list-asset,
.app-results-view .title,
.selected-tab .results .title,
.app-modal .modal-container > .modal-footer,
.add-to-junk-ticket-modal .modal-container > .modal-footer,
.app-modal .modal-container > .modal-header > .close,
.add-to-junk-ticket-modal .modal-container > .modal-header > .close,
.app-modal .modal-container > .modal-header,
.add-to-junk-ticket-modal .modal-container > .modal-header,
.app-modal,
.add-to-junk-ticket-modal,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column,
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes,
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .list,
.app-results-view .assets-list,
.selected-tab .results .assets-list,
.app-results-view,
.selected-tab .results,
.app-modal .modal-container > .modal-body,
.add-to-junk-ticket-modal .modal-container > .modal-body,
.app-modal .modal-container,
.add-to-junk-ticket-modal .modal-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
.app-modal .modal-container > .modal-header > .title,
.add-to-junk-ticket-modal .modal-container > .modal-header > .title,
.app-modal .modal-container > .modal-header,
.add-to-junk-ticket-modal .modal-container > .modal-header,
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow,
.app-modal .modal-container,
.add-to-junk-ticket-modal .modal-container {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border,
.app-modal .modal-container,
.add-to-junk-ticket-modal .modal-container {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to bottom,
      rgba(37, 37, 37, 0) 0%,
      rgba(37, 37, 37, 0.2) 100%);
  width: auto;
  justify-content: center;
  height: 33px;
  min-height: 33px;
  max-height: 33px;
  line-height: 33px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  padding: 0 22px;
  font-size: 12.5px;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  color: #505050;
  background-color: #efefef;
  border: 1px solid #aaa;
}
.app-button .icon,
.app-toggle-button-group button .icon,
.app-multi-toggle-button-group button .icon,
.app-button-primary .icon {
  width: auto;
  margin: 0 5px 0 0;
  font-size: 15px;
  padding: 0;
  font-weight: 500;
}
.app-button[disabled],
.app-toggle-button-group button[disabled],
.app-multi-toggle-button-group button[disabled],
[disabled].app-button-primary {
  opacity: 0.4;
  cursor: default;
}
.app-button:not([disabled]):hover,
.app-toggle-button-group button:not([disabled]):hover,
.app-multi-toggle-button-group button:not([disabled]):hover,
.app-button-primary:not([disabled]):hover {
  background: #efefef;
}
.app-button:not([disabled]):active,
.app-toggle-button-group button:not([disabled]):active,
.app-multi-toggle-button-group button:not([disabled]):active,
.app-button-primary:not([disabled]):active {
  background-color: #ccc;
}
.app-button.app-button-medium,
.app-toggle-button-group button.app-button-medium,
.app-multi-toggle-button-group button.app-button-medium,
.app-button-medium.app-button-primary {
  padding: 0 17px;
  font-size: 11px;
  height: 29px;
  min-height: 29px;
  max-height: 29px;
  line-height: 29px;
}
.app-button.app-button-medium .icon,
.app-toggle-button-group button.app-button-medium .icon,
.app-multi-toggle-button-group button.app-button-medium .icon,
.app-button-medium.app-button-primary .icon {
  margin: 0 5px 0 0;
  font-size: 15px;
}
.app-button.app-button-small,
.app-toggle-button-group button.app-button-small,
.app-multi-toggle-button-group button.app-button-small,
.app-button-small.app-button-primary {
  padding: 0 12px;
  font-size: 10px;
  height: 23px;
  min-height: 23px;
  max-height: 23px;
  line-height: 23px;
}
.app-button.app-button-small .icon,
.app-toggle-button-group button.app-button-small .icon,
.app-multi-toggle-button-group button.app-button-small .icon,
.app-button-small.app-button-primary .icon {
  margin: 0 4px 0 0;
  font-size: 13px;
}
.app-button.icon-only,
.app-toggle-button-group button.icon-only,
.app-multi-toggle-button-group button.icon-only,
.icon-only.app-button-primary {
  padding: 0 15px;
}
.app-button.icon-only .icon,
.app-toggle-button-group button.icon-only .icon,
.app-multi-toggle-button-group button.icon-only .icon,
.icon-only.app-button-primary .icon {
  margin: 0;
}
.app-button.icon-only.app-button-medium,
.app-toggle-button-group button.icon-only.app-button-medium,
.app-multi-toggle-button-group button.icon-only.app-button-medium,
.icon-only.app-button-medium.app-button-primary {
  padding: 0 12px;
}
.app-button.icon-only.app-button-small,
.app-toggle-button-group button.icon-only.app-button-small,
.app-multi-toggle-button-group button.icon-only.app-button-small,
.icon-only.app-button-small.app-button-primary {
  padding: 0 10px;
}
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%);
  color: #fff;
  background-color: #0a51a3;
  border: 1px solid #0a51a3;
}
.app-button-primary:not([disabled]):hover,
.app-toggle-button-group button.selected:not([disabled]):hover,
.app-multi-toggle-button-group button.selected:not([disabled]):hover {
  background: #0a51a3;
}
.app-button-primary:not([disabled]):active,
.app-toggle-button-group button.selected:not([disabled]):active,
.app-multi-toggle-button-group button.selected:not([disabled]):active {
  background-color: #093b75;
}
.app-toggle-button-group,
.app-multi-toggle-button-group {
  display: inline-flex;
  border: 1px solid #ccc;
  border-radius: 2px;
  overflow: hidden;
}
.app-toggle-button-group button,
.app-multi-toggle-button-group button {
  font-size: 12px;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 0 !important;
}
.app-toggle-button-group button:first-child,
.app-multi-toggle-button-group button:first-child {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
.app-toggle-button-group button:last-child,
.app-multi-toggle-button-group button:last-child {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.app-toggle-button-group button:not(:last-child),
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button .icon {
  font-size: 14px;
  color: #505050;
  margin: 0 4px 0 0;
}
.app-multi-toggle-button-group button.selected {
  border-right: 1px solid #0a51a3;
}
.app-multi-toggle-button-group button.selected .icon {
  color: #fff;
  opacity: 0.85;
}
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.modal-shadow,
.app-modal .modal-container,
.add-to-junk-ticket-modal .modal-container {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border,
.app-modal .modal-container,
.add-to-junk-ticket-modal .modal-container {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.modal-body-shared,
.add-to-junk-ticket-modal .modal-container > .modal-body {
  justify-content: start !important;
  align-items: start !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  padding-bottom: 20px !important;
}
.app-modal,
.add-to-junk-ticket-modal {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
}
.app-modal .modal-container,
.add-to-junk-ticket-modal .modal-container {
  position: relative;
  width: 550px;
  height: 400px;
  background-color: #fff;
}
.app-modal .modal-container > .modal-header,
.add-to-junk-ticket-modal .modal-container > .modal-header {
  height: 65px;
}
.app-modal .modal-container > .modal-header > .title,
.add-to-junk-ticket-modal .modal-container > .modal-header > .title {
  width: calc(100% - 65px);
  text-indent: 25px;
  font-size: 19px;
  font-weight: 600;
}
.app-modal .modal-container > .modal-header > .close,
.add-to-junk-ticket-modal .modal-container > .modal-header > .close {
  justify-content: center;
  width: 65px;
  height: 100%;
  cursor: pointer;
}
.app-modal .modal-container > .modal-header > .close .icon,
.add-to-junk-ticket-modal .modal-container > .modal-header > .close .icon {
  font-size: 23px;
  font-weight: 300;
  opacity: 0.6;
}
.app-modal .modal-container > .modal-header > .close:hover,
.add-to-junk-ticket-modal .modal-container > .modal-header > .close:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.app-modal .modal-container > .modal-header > .close:hover .icon,
.add-to-junk-ticket-modal .modal-container > .modal-header > .close:hover .icon {
  font-weight: 500;
  opacity: 1;
}
.app-modal .modal-container > .modal-header > .close:active,
.add-to-junk-ticket-modal .modal-container > .modal-header > .close:active {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-modal .modal-container > .modal-body,
.add-to-junk-ticket-modal .modal-container > .modal-body {
  justify-content: start;
  align-items: center;
  padding: 7px 27px 7px 27px;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 13px;
  line-height: 17px;
}
.app-modal .modal-container > .modal-footer,
.add-to-junk-ticket-modal .modal-container > .modal-footer {
  justify-content: end;
  padding: 0 20px;
  height: 65px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background:
    linear-gradient(
      to top,
      rgba(0, 0, 0, 0.125),
      transparent);
}
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.app-results-view,
.selected-tab .results {
  align-items: start;
  justify-content: start;
  width: 250px;
  margin: 0 25px;
  min-height: 60px;
}
.app-results-view .title,
.selected-tab .results .title {
  width: 100%;
  font-size: 11px;
  font-weight: 500;
  color: #252525;
  height: 28px;
  min-height: 28px;
  max-height: 28px;
  border-bottom: 1px solid rgba(37, 37, 37, 0.4);
  text-indent: 1px;
}
.app-results-view .title .icon,
.selected-tab .results .title .icon {
  font-size: 13px;
  font-weight: 500;
  margin: 0 4px 0 0;
}
.app-results-view.valid .icon,
.selected-tab .valid.results .icon {
  color: rgba(0, 128, 0, 0.8);
}
.app-results-view.invalid .icon,
.selected-tab .invalid.results .icon {
  color: rgba(255, 0, 0, 0.8);
}
.app-results-view .summary,
.selected-tab .results .summary {
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  padding: 4px 0;
  align-items: start;
  color: #505050;
  justify-content: start;
  width: 100%;
}
.app-results-view .assets-list,
.selected-tab .results .assets-list {
  align-items: start;
  justify-content: start;
  width: 100%;
  height: 180px;
  overflow-x: hidden;
  overflow-y: auto;
}
.app-results-view .assets-list .list-asset,
.selected-tab .results .assets-list .list-asset {
  width: 100%;
  align-items: start;
  border-bottom: 1px solid rgba(204, 204, 204, 0.4);
}
.app-results-view .assets-list .list-asset .list-asset-number,
.selected-tab .results .assets-list .list-asset .list-asset-number,
.app-results-view .assets-list .list-asset .list-asset-reason,
.selected-tab .results .assets-list .list-asset .list-asset-reason {
  align-items: start;
  padding: 5px 0;
  font-size: 12px;
}
.app-results-view .assets-list .list-asset .list-asset-number,
.selected-tab .results .assets-list .list-asset .list-asset-number {
  width: 88px;
  font-weight: 600;
  color: #093b75;
  white-space: nowrap;
}
.app-results-view .assets-list .list-asset .list-asset-reason,
.selected-tab .results .assets-list .list-asset .list-asset-reason {
  width: calc(100% - 88px);
  font-weight: 400;
  word-break: break-word;
}
.app-results-view .assets-list .list-asset:last-child,
.selected-tab .results .assets-list .list-asset:last-child {
  border: none;
}
.add-to-junk-ticket-modal .modal-container {
  width: 800px;
  height: auto;
}
.add-to-junk-ticket-modal .modal-container > .modal-body {
  position: relative;
  height: 300px;
  bottom: 15px;
  overflow: hidden;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view {
  position: absolute;
  top: 15px;
  right: 28px;
  bottom: 0px;
  left: 28px;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro p {
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  margin: 0 0 20px 0;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro p.assets-quantity-note {
  font-weight: 500;
  color: #093b75;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes {
  align-items: flex-start;
  width: 98%;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .list {
  width: 150px;
  height: 145px;
  margin: 7px 40px 0 0;
  border-top: 1px solid rgba(204, 204, 204, 0.8);
  border-bottom: 1px solid rgba(204, 204, 204, 0.8);
  overflow-x: hidden;
  overflow-y: auto;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .list .asset-number {
  width: 92%;
  padding: 0 0 0 7px;
  font-size: 11px;
  font-weight: 400;
  color: #093b75;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
  border-bottom: 1px solid rgba(238, 238, 238, 0.75);
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes {
  width: calc(100% - 150px);
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .label {
  font-size: 12px;
  font-weight: 600;
  color: #093b75;
  padding: 3px 0;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .label .pre-existing {
  font-size: 9px;
  font-weight: 300;
  color: #757575;
  margin: -1px 0 0 5px;
  text-transform: uppercase;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .notes-footer .shared-footer-font,
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .notes-footer > .character-count,
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .notes-footer > .min-max {
  height: 25px;
  line-height: 25px;
  font-size: 11px;
  color: #505050;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .notes-footer > .min-max {
  width: 50%;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .notes-footer > .character-count {
  width: 50%;
  justify-content: flex-end;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .notes-footer > .character-count .icon {
  margin-top: 2px;
  margin-left: 5px;
  font-size: 15px;
  font-weight: 500;
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .notes-footer > .character-count .icon.valid {
  color: rgba(0, 128, 0, 0.8);
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .notes-footer > .character-count .icon.invalid {
  color: rgba(255, 0, 0, 0.8);
}
.add-to-junk-ticket-modal .modal-container > .modal-body .view.intro .list-and-notes .notes > .text {
  font-size: 13px;
  line-height: 17px;
  font-weight: 400;
}
.add-to-junk-ticket-modal .modal-container > .modal-body.results {
  height: 425px;
}
.selected-tab {
  align-items: flex-start;
  justify-content: flex-start;
}
.selected-tab .results {
  margin-top: 20px;
  height: 345px;
  overflow-x: hidden;
  overflow-y: auto;
}
.selected-tab .results.invalid {
  width: 65%;
}
.selected-tab .results.valid {
  width: 35%;
}
.selected-tab .results .title {
  font-size: 11px;
  text-transform: uppercase;
  border: none;
  height: 25px;
  min-height: 25px;
  max-height: 25px;
}
.selected-tab .results .title .copy-button {
  margin: 12px 0 0 auto;
}
.selected-tab .results .title .copied-notification {
  justify-content: center;
  margin: 12px 0 0 auto;
  width: 65px;
  border-radius: 2px;
  height: 27px;
  line-height: 27px;
  background-color: #279a2c;
  color: #fff;
  font-size: 11px;
  font-weight: 400;
}
.selected-tab .results .summary {
  line-height: 15px;
  padding: 0 0 0 4px;
}
.selected-tab .results .assets-list {
  height: unset;
  padding: 5px 0 0 5px;
}
::ng-deep span.app-tabs-ticket-type {
  font-weight: 500;
  color: #000;
  margin-right: 5px;
}
/*# sourceMappingURL=post-to-junk-ticket-modal.component.css.map */
