import { TicketDto } from '../dtos/TicketDto';
import { format } from 'date-fns';

export class Ticket {
    id: string;
    office: string;
    account: string;
    dispatcher: string;
    note: string;
    type: string;
    posted: string;
    deliveryDate: string;
    systemNote?: string;

    constructor(ticketDto: TicketDto) {
        this.id = ticketDto.id?.toString() ?? '';
        this.office = ticketDto.office?.trim() ?? '';
        this.account = ticketDto.account?.trim() ?? '';
        this.dispatcher = ticketDto.dispatcher?.trim() ?? '';
        this.note = ticketDto.note?.trim() ?? '';
        this.type = ticketDto.type?.trim() ?? '';
        this.posted = ticketDto.posted ? 'Yes' : 'No';
        this.deliveryDate = ticketDto.deliveryDate
            ? format(ticketDto.deliveryDate, 'M/dd/yyyy')
            : '';
        this.systemNote = ticketDto.systemNote?.trim() ?? '';
    }
}
