import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'session-section',
    standalone: true,
    imports: [CommonModule],
    template: `
        <div class="session-section">
            <fieldset class="section-header">
                <legend>{{ title }}</legend>
            </fieldset>
            <div class="content">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./session-section.component.scss'],
})
export class SessionSectionComponent {
    @Input() title = '';
}
