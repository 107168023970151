import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ReportsService } from './ReportsService';
import { firstValueFrom, forkJoin } from 'rxjs';
import { AppDataDto } from '../models/interfaces/AppDataResponse';

@Injectable({
    providedIn: 'root',
})
export class MainService {
    private urls = environment.endpoints;

    constructor(
        private http: HttpClient,
        private reportsService: ReportsService,
    ) {}

    async getAppData(): Promise<AppDataDto> {
        const url = this.urls.getAppData;
        return await firstValueFrom(this.http.get<AppDataDto>(url));
    }

    async getAppInitData() {
        return firstValueFrom(
            forkJoin({
                completedCounts: this.reportsService.getCompletedCounts(),
                appData: this.getAppData(),
            }),
        );
    }
}
