import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
    AssetStatus,
    AssetStatusLabels,
    IssueType,
    IssueTypeLabels,
} from '../../../../../../../models/classes/SessionAsset';
import { NgForOf } from '@angular/common';
import { SelectReportTypes } from '../../../../../../../models/interfaces/SelectReportTypes';

@Component({
    selector: 'app-active-count-reports-modal',
    standalone: true,
    templateUrl: './active-count-reports-modal.component.html',
    styleUrls: [
        './active-count-reports-modal.component.scss',
        '../../../../../../../styles/_common.scss',
    ],
    imports: [ReactiveFormsModule, NgForOf],
})
export class ActiveCountReportsModalComponent implements OnInit {
    // @ts-ignore
    form: FormGroup;
    assetStatuses = Object.entries(AssetStatus)
        .filter(([key]) => isNaN(Number(key)))
        .filter(([_, value]) => value !== AssetStatus.HasIssue)
        .map(([key, value]) => ({ key, value }));
    issueTypes = Object.entries(IssueType)
        .filter(([key]) => isNaN(Number(key)))
        .map(([key, value]) => ({ key, value }));

    constructor(
        public activeModal: NgbActiveModal,
        private formBuilder: FormBuilder,
    ) {}

    ngOnInit() {
        this.initializeForm();
    }

    get shouldDisableGenerateButton(): boolean {
        return (
            this.getSelectedIssueTypes().length === 0 &&
            this.getSelectedAssetStatuses().length === 0
        );
    }

    getStatusLabel(value: any): string {
        // @ts-ignore
        return AssetStatusLabels[AssetStatus[value]] || '';
    }

    getIssueTypeLabel(value: any): string {
        // @ts-ignore
        return IssueTypeLabels[IssueType[value]] || '';
    }

    closeModal(result: boolean): void {
        this.activeModal.close(result);
    }

    toggleCheckbox(formSection: string, key: string): void {
        const control = this.form.get(`${formSection}.${key}`);
        if (control) control.setValue(!control.value);
    }

    handleGenerateClick(): void {
        const reportTypes: SelectReportTypes = {
            assetStatuses: this.getSelectedAssetStatuses(),
            issueTypes: this.getSelectedIssueTypes(),
        };
        this.activeModal.close(reportTypes);
    }

    private getSelectedAssetStatuses(): AssetStatus[] {
        return Object.entries(this.form.get('assetStatuses')?.value || {})
            .filter(([_, checked]) => checked)
            .map(([key]) => AssetStatus[key as keyof typeof AssetStatus]);
    }

    private getSelectedIssueTypes(): IssueType[] {
        return Object.entries(this.form.get('issueTypes')?.value || {})
            .filter(([_, checked]) => checked)
            .map(([key]) => IssueType[key as keyof typeof IssueType]);
    }

    private initializeForm(): void {
        this.form = this.formBuilder.group({
            issueTypes: this.formBuilder.group(
                Object.keys(IssueType)
                    .filter((key) => isNaN(Number(key)))
                    .reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            ),
            assetStatuses: this.formBuilder.group(
                Object.keys(AssetStatus)
                    .filter((key) => isNaN(Number(key)))
                    .reduce((acc, key) => ({ ...acc, [key]: false }), {}),
            ),
        });
    }
}
