import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DatePipe, NgClass, NgForOf, NgIf } from '@angular/common';
import { SelectionType } from './models/types/SelectionType';
import { AppToggleButtonGroupComponent } from '../../../../../components/app-toggle-button-group/app-toggle-button-group.component';
import {
    MultiToggleButtonGroupOption,
    ToggleButtonGroupOption,
} from '../../../../../models/interfaces/ToggleButtonGroupOption';
import { AssetRetentionService } from '../../../../../services/AssetRetentionService';
import { LoadingIndicatorService } from '../../../../../services/LoadingIndicatorService';
import { SelectOption } from '../../../../../models/classes/SelectOption';
import {
    DistrictCountDto,
    DistrictResponseDto,
    DistrictsDto,
    DistrictType,
} from '../../../../../models/dtos/DistrictResponseDto';
import { AppSelectComponent } from '../../../../../components/app-select/app-select.component';
import { AppButtonComponent } from '../../../../../components/app-button/app-button.component';
import { first, last, orderBy, sortBy } from 'lodash-es';
import { format } from 'date-fns';
import { StateService } from '../../../../../services/StateService';
import { FilterSearchComponent } from '../../../../../components/app-filter-search/app-filter-search.component';
import { AppTableComponent } from '../../../../../components/app-table/app-table.component';
import {
    AppTableHeader,
    CreateHeader,
} from '../../../../../components/app-table/models/interfaces/AppTableHeader';
import { AppRowComponent } from '../../../../../components/app-table/components/app-row/app-row.component';
import { AppCellComponent } from '../../../../../components/app-table/components/app-cell/app-cell.component';
import {
    AssetRetentionDto,
    MissingItemImage,
    MissingItemStatus,
} from '../../../../../models/dtos/AssetRetentionDto';
import { FormsModule } from '@angular/forms';
import { TableMissingItem } from './models/interfaces/TableMissingItem';
import { AcknowledgementModalService } from '../../../../../services/AcknowledgementModalService';
import { UtilityService } from '../../../../../services/UtilityService';
import { User } from '../../../../../models/classes/User';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { EditCommentsModalComponent } from './components/edit-comments-modal/edit-comments-modal.component';
import { AddAssetModalComponent } from './components/add-asset-modal/add-asset-modal.component';
import { ToastService } from '../../../../../services/ToastService';
import { AppMultiToggleButtonGroupComponent } from '../../../../../components/app-multi-toggle-button-group/app-multi-toggle-button-group';
import { ConfirmationModalService } from '../../../../../services/ConfirmationModalService';
import { PostToJunkTicketModalComponent } from './components/post-to-junk-ticket-modal/post-to-junk-ticket-modal.component';
import { UserDto } from '../../../../../models/dtos/UserDto';
import { TicketDetailsModalComponent } from './components/ticket-details-modal/ticket-details-modal.component';
import { MissingItemsRequestDto } from '../../../../../models/interfaces/MissingItemsRequestDto';
import { HttpError } from '../../../../../models/interfaces/HttpError';
import { MissingItemProperty } from '../../../../../models/interfaces/MissingItemProperty';
import { RetentionHistoryModalComponent } from './components/retention-history-modal/retention-history-modal.component';
import { AssetErrorDto, JunkTicketResults } from '../../../../../models/dtos/JunkTicketDto';
import { ManageImagesModalComponent } from './components/manage-images-modal/manage-images-modal.component';
import { AssetTypeType } from '../../../../../models/types/AssetTypeType';
import { Asset } from '../../../../../models/classes/SessionAsset';
import { Router } from '@angular/router';

@Component({
    selector: 'asset-retention',
    standalone: true,
    imports: [
        AppSelectComponent,
        NgIf,
        AppButtonComponent,
        AppToggleButtonGroupComponent,
        FilterSearchComponent,
        AppTableComponent,
        AppRowComponent,
        AppCellComponent,
        NgForOf,
        AppCellComponent,
        FormsModule,
        DatePipe,
        AppButtonComponent,
        AppButtonComponent,
        AppMultiToggleButtonGroupComponent,
        AppSelectComponent,
        NgClass,
    ],
    templateUrl: './asset-retention.component.html',
    styleUrls: ['./asset-retention.component.scss', '../../main-shared.scss'],
})
export class AssetRetentionComponent implements OnInit, OnDestroy {
    // Accessors
    orderBy = orderBy;
    protected readonly MissingItemStatus = MissingItemStatus;
    // Constants
    readonly MESSAGES = {
        postToTicketWarning: 'Please select at least one asset to post to a ticket.',
    };
    private readonly FILTER_PROPERTIES = ['assetNumber', 'assetDescription', 'assetCountNote'];
    private readonly INTERVAL_SECONDS: number = 10;
    private readonly MIN_COMMENT_LENGTH: number = 5;
    // Selection
    selectionOptions: ToggleButtonGroupOption<SelectionType>[] = [
        { text: 'Current', type: 'current' },
        { text: 'History', type: 'history' },
    ];
    selectedOption: SelectionType = 'current';
    // Districts
    allDistricts: DistrictsDto | undefined = undefined;
    districts: SelectOption[] = [];
    selectedDistrictCode: string | undefined = undefined;
    // Counts
    selectedCount: string = '';
    counts: SelectOption[] = [];
    countsSelectedOption: string | undefined = undefined;
    // Filters
    filterText: string = '';
    showOnlyChecked: boolean = false;
    showOnlyFailedToPostOrResolve: boolean = false;
    // Table
    headers: AppTableHeader[] = [];
    shouldShowNoAssetsFound: boolean = false;
    assetRetentionDtos: AssetRetentionDto[] = [];
    rows: TableMissingItem[] = [];
    rowsFiltered: TableMissingItem[] = [];
    shouldShowTable: boolean = true;
    users: UserDto[] = [];
    lastMissingItemsRequestDto: MissingItemsRequestDto | undefined = undefined;
    lastMissingItemsRequestHash: string = '';
    // Sync
    intervalId: number | null = null;
    // Footer
    filterOptions: MultiToggleButtonGroupOption<string>[] = [
        { type: 'checked', text: 'Checked', selected: false },
        { type: 'failed', text: 'Failed To Post / Resolve', selected: false },
    ];
    selectedAssetTypeOption: AssetTypeType = 'ALL';
    assetTypeOptions: ToggleButtonGroupOption<AssetTypeType>[] = [
        { text: 'All', type: 'ALL' },
        { text: 'R', type: 'R' },
        { text: 'O', type: 'O' },
    ];

    @ViewChild(FilterSearchComponent) filterSearch!: FilterSearchComponent;

    constructor(
        private assetRetentionService: AssetRetentionService,
        private loadingIndicatorService: LoadingIndicatorService,
        private stateService: StateService,
        private acknowledgementModalService: AcknowledgementModalService,
        private confirmationModalService: ConfirmationModalService,
        private utilityService: UtilityService,
        private modalService: NgbModal,
        private toastService: ToastService,
        private router: Router,
    ) {}

    async ngOnInit() {
        this.initializeTableHeaders();
        await this.initializeData();
    }

    ngOnDestroy(): void {
        this.stopInterval();
    }

    get selectedDistrictTooltip(): string {
        return (
            this.districts.find((district) => district.value === this.selectedDistrictCode)?.text ||
            ''
        );
    }

    get isModeCurrent(): boolean {
        return this.selectedOption === 'current';
    }

    getImagesLength(missingItemId: number): number {
        let length: number = 0;
        const missingItem: TableMissingItem | undefined =
            this.getMissingItemInRowsById(missingItemId);
        if (!missingItem) return 0;

        if (missingItem.images && missingItem.images.length) {
            length = missingItem.images.length;
        }

        return length;
    }

    async onSelectionChange(newSelectedOption: SelectionType) {
        if (this.selectedOption === newSelectedOption) return;

        await this.clearListsAndResetFilters();
        this.selectedOption = newSelectedOption;
        this.initializeDistrictsByType();
    }

    async onAssetTypeChange(newAssetTypeOption: AssetTypeType) {
        if (this.selectedAssetTypeOption === newAssetTypeOption) return;

        this.selectedAssetTypeOption = newAssetTypeOption;
        await this.filterTable();
    }

    async loadMissingItems() {
        // If one is already running, stop interval.
        this.stopInterval();

        // Prepare data for HTTP call.
        const dto: MissingItemsRequestDto = {
            districtCode: this.selectedDistrictCode as string,
            isActive: this.selectedOption === 'current',
            assetCountId: this.countsSelectedOption as string,
        };
        this.lastMissingItemsRequestDto = dto;

        // Clear form, display loading indicator, and execute HTTP request.
        this.filterText = '';
        this.loadingIndicatorService.set('component', true, 'Loading assets...');
        this.shouldShowNoAssetsFound = false;
        await this.clearListsAndResetFilters();

        // Update core asset retention dtos list and rows list, as well.
        this.assetRetentionDtos = await this.assetRetentionService.getMissingItems(dto);
        this.lastMissingItemsRequestHash = this.utilityService.hashData(this.assetRetentionDtos);
        this.rows = this.assetRetentionDtos.map((dto) =>
            this.buildTableMissingItemFromAssetRetentionDto(dto),
        );

        // If there is data loaded, start sync interval.
        if (this.assetRetentionDtos.length) this.startInterval();

        // For display, update the rows filtered list.
        this.rowsFiltered = [...this.rows];

        if (this.rows.length === 0) {
            this.shouldShowNoAssetsFound = true;
        }
        await this.utilityService.sleep(350);
        this.loadingIndicatorService.set('component', false);
    }

    async onFilterChange(filterText: string) {
        // Handle case where user clears the field through keystrokes (e.g. highlight all + del).
        if (filterText === '') {
            this.filterText = '';
            await this.filterTable(true);
            return;
        }

        if (filterText.trim() === this.filterText) return;

        const isSearchLengthIncreased = filterText.trim().length > this.filterText.length;
        this.filterText = filterText.trim().toLowerCase();

        if (this.filterText) {
            this.rowsFiltered = [
                ...this.rows.filter((missingItem) =>
                    missingItem.filterSearchText.toLowerCase().includes(this.filterText),
                ),
            ];
            if (!isSearchLengthIncreased) await this.filterTable(true);
        }
    }

    async filterTable(shouldSkipTextFilter: boolean = false) {
        let rowsFiltered: TableMissingItem[] = [...this.rows];

        // Apply text filtering.
        if (!shouldSkipTextFilter && this.filterText)
            rowsFiltered = [
                ...rowsFiltered.filter((missingItem) =>
                    missingItem.filterSearchText.toLowerCase().includes(this.filterText),
                ),
            ];

        // Apply show only checked filtering.
        if (rowsFiltered.length && this.showOnlyChecked) {
            rowsFiltered = rowsFiltered.filter(
                (missingItem: TableMissingItem) => missingItem.isChecked,
            );
        }

        // Apply show on failed to post filtering.
        if (rowsFiltered.length && this.showOnlyFailedToPostOrResolve) {
            const failedStatuses = [
                MissingItemStatus.FailedToResolve,
                MissingItemStatus.FailedToPost,
            ];
            rowsFiltered = rowsFiltered.filter((missingItem: TableMissingItem) =>
                failedStatuses.includes(missingItem.statusId),
            );
        }

        // Apply asset type filtering.
        if (this.selectedAssetTypeOption !== 'ALL') {
            rowsFiltered = rowsFiltered.filter(
                (missingItem: TableMissingItem) =>
                    missingItem.assetType === this.selectedAssetTypeOption,
            );
        }

        // Update rows in view.
        if (rowsFiltered.length >= this.rowsFiltered.length) {
            this.rowsFiltered = rowsFiltered;
            await this.refreshTable();
        } else if (
            rowsFiltered.length === this.rows.length &&
            this.rowsFiltered.length !== this.rows.length
        ) {
            this.rowsFiltered = rowsFiltered;
            await this.refreshTable();
        } else if (rowsFiltered.length < this.rowsFiltered.length) {
            this.rowsFiltered = rowsFiltered;
            await this.refreshTable();
        }
    }

    async onFilterClear() {
        this.filterText = '';
        await this.filterTable();
    }

    async displayRetentionHistoryModal() {
        const modalRef: NgbModalRef = this.modalService.open(RetentionHistoryModalComponent);
        const result: string | boolean = await modalRef.result;

        if (result) {
            await this.router.navigate(['/main/asset-retention-history', result]);
        }
    }

    async displayAddAssetModal() {
        const modalRef: NgbModalRef = this.modalService.open(AddAssetModalComponent);
        modalRef.componentInstance.districtCode = this.selectedDistrictCode;
        modalRef.componentInstance.assetCountId = this.countsSelectedOption;

        const addedAsset: AssetRetentionDto | boolean = await modalRef.result;

        if (addedAsset) {
            const dto = addedAsset as AssetRetentionDto;
            const missingItem: TableMissingItem =
                this.buildTableMissingItemFromAssetRetentionDto(dto);
            await this.addAsset(dto, missingItem);
            this.toastService.show({
                type: 'success',
                message: `Asset '${dto.assetNumber}' successfully added to retention.`,
            });
        }
    }

    async displayPostToJunkTicketModal() {
        const checkedMissingItemIds = this.getCheckedMissingItemIds();
        const assetNumbers = this.rows
            .filter((asset) => checkedMissingItemIds.includes(asset.missingItemId))
            .map((asset) => asset.assetNumber);

        // If the button disabling fails, message user if no assets are selected.
        if (checkedMissingItemIds.length === 0) {
            this.toastService.show({
                type: 'info',
                message: this.MESSAGES.postToTicketWarning,
            });
            return;
        }

        // Display post to junk ticket modal.
        const modalRef: NgbModalRef = this.modalService.open(PostToJunkTicketModalComponent);
        modalRef.componentInstance.missingItemIds = checkedMissingItemIds;
        modalRef.componentInstance.assetCountId = this.countsSelectedOption;
        modalRef.componentInstance.assetNumbers = assetNumbers;

        // If successfully posted asset numbers returned, remove them from the UI.
        let results: JunkTicketResults | boolean = await modalRef.result;
        if (results !== false) this.processJunkTicketResults(results as JunkTicketResults);
    }

    displayDistrictHistoryModal(): void {
        console.log('displayDistrictHistoryModal()');
    }

    async displayAssetErrorDetails(missingItemId: number) {
        const errorMessage: string | undefined = this.rows.find(
            (item) => item.missingItemId == missingItemId,
        )?.errorMessage;

        if (errorMessage)
            await this.acknowledgementModalService.open('Error Details', errorMessage);
    }

    async displayEditCommentsModal(missingItemId: number) {
        const inViewMissingItem: TableMissingItem | undefined =
            this.getMissingItemInViewById(missingItemId);
        const rowsMissingItem: TableMissingItem | undefined =
            this.getMissingItemInRowsById(missingItemId);

        if (inViewMissingItem && rowsMissingItem) {
            const modalRef = this.modalService.open(EditCommentsModalComponent);
            modalRef.componentInstance.missingItemId = missingItemId;

            const comment = await modalRef.result;
            if (comment !== false) {
                const user: User = this.stateService.get('user') as User;

                // Update local data.
                this.updateMissingItemProperties(missingItemId, [
                    { key: 'comment', value: comment },
                ]);

                // HTTP call to update comment on missing item.
                await this.assetRetentionService.editComment(missingItemId, comment, user.username);

                this.toastService.show({
                    type: 'success',
                    message: 'Comment successfully updated.',
                });
            }
        }
    }

    async displayManageImagesModal(missingItemId: number) {
        const missingItem: TableMissingItem | undefined =
            this.getMissingItemInRowsById(missingItemId);

        if (missingItem) {
            const modalRef = this.modalService.open(ManageImagesModalComponent);
            const originalImages: MissingItemImage[] | undefined = missingItem.images
                ? [...missingItem.images]
                : undefined;

            modalRef.componentInstance.missingItemId = missingItemId;
            modalRef.componentInstance.images = missingItem.images;
            modalRef.componentInstance.assetNumber = missingItem.assetNumber;

            // If changes to the images were made, update the local data.
            const result: boolean = await modalRef.result;
            if (result && !this.utilityService.areListsSame(originalImages, missingItem.images)) {
                this.updateMissingItemProperties(missingItemId, [
                    { key: 'images', value: missingItem.images },
                ]);
            }
        }
    }

    async handleResolveClick(missingItemId: number) {
        const missingItem = this.getMissingItemInRowsById(missingItemId) as TableMissingItem;
        const assetNumber = missingItem.assetNumber;
        const user = this.stateService.get('user') as User;

        const response = await this.confirmationModalService.open(
            `Resolve ${assetNumber}?`,
            'Are you sure you want to resolve this asset?',
        );

        if (response) {
            this.updateMissingItemProperties(missingItemId, [{ key: 'isDisabled', value: true }]);
            try {
                await this.assetRetentionService.resolveAsset(missingItemId, user.username);
                this.removeAssetFromAllLists(assetNumber);
                this.toastService.show({
                    type: 'success',
                    message: `Asset '${assetNumber}' successfully resolved.`,
                });
            } catch (error: any) {
                this.updateMissingItemProperties(missingItemId, [
                    { key: 'isDisabled', value: false },
                ]);
                const httpError: HttpError = { ...error } as HttpError;
                await this.processResolveError(missingItemId, assetNumber, httpError);
            }
        }
    }

    getUserFullname(username: string | undefined): string {
        if (!username) return '';

        const user: UserDto | undefined = this.users.find(
            (user) => user.userName === username.toLowerCase(),
        );
        const isUserSet: boolean = Boolean(user && user.firstName && user.lastName);

        return isUserSet ? `${user?.firstName} ${user?.lastName[0]}.` : username;
    }

    getUserFullnameTooltip(username: string | undefined): string {
        if (!username) return '';

        const user: UserDto | undefined = this.users.find(
            (user) => user.userName === username.toLowerCase(),
        );
        const isUserSet: boolean = Boolean(user && user.firstName && user.lastName);

        return isUserSet ? `${user?.firstName} ${user?.lastName}` : username;
    }

    toggleAll(): void {
        const areAllSelected: boolean = this.rowsFiltered.every((item) => item.isChecked);
        const nextValue = !areAllSelected;
        const assetNumbersInView = this.rowsFiltered.map((row) => row.assetNumber);

        // Ensure that both rows are updated.
        this.rowsFiltered.forEach(
            (missingItem: TableMissingItem) => (missingItem.isChecked = nextValue),
        );
        this.rows.forEach((missingItem: TableMissingItem) => {
            if (assetNumbersInView.includes(missingItem.assetNumber))
                missingItem.isChecked = nextValue;
        });
    }

    cannotResolveAsset(missingItem: TableMissingItem): boolean {
        let canResolve: boolean = false;

        if (missingItem.comment && missingItem?.comment.length) {
            canResolve = missingItem.comment.length >= this.MIN_COMMENT_LENGTH;
        }

        return !canResolve;
    }

    cannotPostJunkTickets(): boolean {
        return !Boolean(this.getCheckedMissingItemIds().length);
    }

    async clearFilters() {
        if (this.rowsFiltered.length === this.rows.length) return;

        // Clear search filter.
        this.filterSearch.clearSearch();

        // Reset footer toggle filters.
        this.showOnlyChecked = false;
        this.showOnlyFailedToPostOrResolve = false;
        const checkedToggled = this.getFilterOptionByType('checked');
        if (checkedToggled) checkedToggled.selected = false;
        const failedToggled = this.getFilterOptionByType('failed');
        if (failedToggled) failedToggled.selected = false;

        // Refresh table.
        await this.filterTable();
    }

    async onMultiToggleFilterChange(filterOptionChanges: MultiToggleButtonGroupOption<string>[]) {
        this.filterOptions = filterOptionChanges;
        this.showOnlyChecked =
            this.filterOptions.find((option) => option.type === 'checked')?.selected ?? false;
        this.showOnlyFailedToPostOrResolve =
            this.filterOptions.find((option) => option.type === 'failed')?.selected ?? false;

        await this.filterTable();
    }

    async displayTicketModal(missingItemId: number) {
        const missingItem = this.getMissingItemInViewById(missingItemId);
        const modalRef: NgbModalRef = this.modalService.open(TicketDetailsModalComponent);
        modalRef.componentInstance.assetNumber = missingItem?.assetNumber;
        modalRef.componentInstance.ticketId = missingItem?.onTicketId;
        modalRef.componentInstance.missingItemId = missingItem?.missingItemId;
    }

    handleSelectedDistrictChange() {
        this.updateDisplayedCounts();
    }

    private initializeTableHeaders(): void {
        this.headers = [
            CreateHeader('checkbox', '', { width: '60px', align: 'center' }),
            CreateHeader('asset', 'Asset', { width: '225px' }),
            CreateHeader('type', 'Type', { width: '85px', align: 'center' }),
            CreateHeader('count-notes', 'Count Notes', { width: '225px' }),
            CreateHeader('comments', 'Comments', { width: '250px' }),
            CreateHeader('images', 'Images', { width: '200px' }),
            CreateHeader('disposition', 'Disposition', { width: '250px' }),
            CreateHeader('options', ''),
        ];
    }

    private async processResolveError(
        missingItemId: number,
        assetNumber: string,
        httpError: HttpError,
    ) {
        const httpErrorMessage = httpError.messages[0];
        const errorMessage = `Asset '${assetNumber}' failed to resolve with the following error:<br /><br />${httpErrorMessage}`;

        const missingItemProperties: MissingItemProperty[] = [
            { key: 'statusId', value: MissingItemStatus.FailedToResolve },
            { key: 'errorMessage', value: httpErrorMessage },
        ];
        this.updateMissingItemProperties(missingItemId, missingItemProperties);

        await this.acknowledgementModalService.open('Failed to resolve...', errorMessage);
    }

    private updateMissingItemProperties(id: number, properties: MissingItemProperty[]): void {
        const dto = this.getAssetFromSourceData(id) as AssetRetentionDto;
        const missingItem = this.getMissingItemInRowsById(id) as TableMissingItem;
        const missingItemInView = this.getMissingItemInViewById(id) as TableMissingItem;

        properties.forEach((property) => {
            if (dto) {
                // @ts-ignore
                dto[property.key] = property.value;
                this.lastMissingItemsRequestHash = this.utilityService.hashData(
                    this.assetRetentionDtos,
                );
            }

            // New has to be applied to other lists (rows, and rowsFiltered).
            const hash: string = this.utilityService.hashData(dto);

            if (missingItem) {
                // @ts-ignore
                missingItem[property.key] = property.value;
                missingItem['hash'] = hash;
            }

            if (missingItemInView) {
                // @ts-ignore
                missingItemInView[property.key] = property.value;
                missingItemInView['hash'] = hash;
            }
        });
    }

    private async addAsset(dto: AssetRetentionDto, missingItem: TableMissingItem) {
        this.assetRetentionDtos.push(dto);
        this.lastMissingItemsRequestHash = this.utilityService.hashData(this.assetRetentionDtos);
        this.rows.push(missingItem);
        this.rowsFiltered.push(missingItem);
        this.shouldShowNoAssetsFound = !Boolean(this.assetRetentionDtos.length);
        await this.refreshTable();
    }

    private async refreshTable(shouldClearFilter: boolean = false) {
        this.shouldShowTable = false;
        await this.utilityService.sleep(1);
        if (shouldClearFilter) {
            this.filterText = '';
            this.rowsFiltered = this.rows;
            await this.utilityService.sleep(1);
        }
        this.shouldShowTable = true;
    }

    private async initializeData() {
        // Initialize users.
        this.users = this.stateService.get('users') as UserDto[];
        this.allDistricts = this.stateService.get('districts') as DistrictsDto;

        // Initialize districts.
        this.initializeDistrictsByType();
    }

    private initializeDistrictsByType(): void {
        if (!this.allDistricts) return;

        const type: DistrictType = this.getSelectedDistrictType();

        // Initialize displayed districts list.
        this.districts = sortBy(this.allDistricts[type], ['name']).map(
            (dto: DistrictResponseDto) => ({
                value: dto.districtCode,
                text: `${dto.districtCode} - ${dto.name}`,
            }),
        );
        if (this.districts.length) this.selectedDistrictCode = first(this.districts)?.value;

        // Initialize selected district counts, if on Current view.
        this.updateDisplayedCounts();
    }

    private updateDisplayedCounts(): void {
        if (!this.allDistricts) return;

        const type: DistrictType = this.getSelectedDistrictType();
        const districtsInView = this.allDistricts[type];
        const selectedDistrict = districtsInView.find(
            (district: DistrictResponseDto) => district.districtCode === this.selectedDistrictCode,
        );

        // Handle multi-select or single count display.
        const counts = selectedDistrict?.counts as DistrictCountDto[];

        if (counts?.length) {
            if (counts.length === 1) {
                this.counts = [];
                this.selectedCount = format(counts[0].startDate, 'M/d/yyyy');
                this.countsSelectedOption = counts[0].id;
            } else {
                this.selectedCount = '';
                this.counts = orderBy(counts, ['startDate'], ['asc']).map((count) => {
                    return {
                        value: count.id,
                        text: format(count.startDate, 'M/d/yyyy'),
                    };
                });
                this.countsSelectedOption = last(this.counts)?.value;
            }
        }
    }

    private getSelectedDistrictType(): DistrictType {
        return this.selectedOption === 'current' ? 'active' : 'inactive';
    }

    private getMissingItemInViewById(missingItemId: number): TableMissingItem | undefined {
        return this.rowsFiltered.find(
            (item: TableMissingItem) => item.missingItemId === missingItemId,
        );
    }

    private getMissingItemInRowsById(missingItemId: number): TableMissingItem | undefined {
        return this.rows.find((item: TableMissingItem) => item.missingItemId === missingItemId);
    }

    private getAssetFromSourceData(missingItemId: number): AssetRetentionDto | undefined {
        return this.assetRetentionDtos.find(
            (dto: AssetRetentionDto) => dto.missingItemId === missingItemId,
        );
    }

    private async clearListsAndResetFilters() {
        // Clear lists.
        this.assetRetentionDtos = [];
        this.rows = [];
        this.rowsFiltered = [];

        // Reset Filters.
        await this.clearFilters();
    }

    private getFilterOptionByType(type: string) {
        return this.filterOptions.find((option) => option.type === type);
    }

    private removeAssetFromAllLists(assetNumber: string): void {
        // Update asset retention dtos and hash.
        this.assetRetentionDtos = [
            ...this.assetRetentionDtos.filter((dto) => dto.assetNumber !== assetNumber),
        ];
        this.lastMissingItemsRequestHash = this.utilityService.hashData(this.assetRetentionDtos);

        // Update rows.
        this.rows = [...this.rows.filter((missingItem) => missingItem.assetNumber !== assetNumber)];

        // Update rowsFiltered.
        this.rowsFiltered = [
            ...this.rowsFiltered.filter((missingItem) => missingItem.assetNumber !== assetNumber),
        ];
    }

    private getCheckedMissingItemIds(): number[] {
        return this.rows
            .filter((missingItem) => missingItem.isChecked)
            .map((missingItem) => missingItem.missingItemId);
    }

    private processJunkTicketResults(results: JunkTicketResults): void {
        // Handle posted assets.
        if (results.postedAssets.length) {
            results.postedAssets.forEach((assetNumber: string) =>
                this.removeAssetFromAllLists(assetNumber),
            );
        }

        // Handle failed assets.
        if (results.failedAssets.length) {
            results.failedAssets.forEach((failedAsset: AssetErrorDto) => {
                const missingItem = this.rows.find(
                    (item: TableMissingItem) => item.assetNumber === failedAsset.assetNumber,
                );
                if (missingItem) {
                    const id = missingItem.missingItemId;
                    const properties: MissingItemProperty[] = [
                        { key: 'statusId', value: 3 },
                        { key: 'errorMessage', value: failedAsset.errorMessage },
                    ];
                    this.updateMissingItemProperties(id, properties);
                }
            });
        }
    }

    private getFilterSearchText(missingItem: AssetRetentionDto): string {
        return this.FILTER_PROPERTIES.map((property: any) => {
            // @ts-ignore
            if (missingItem[property]) return missingItem[property];
        })
            .filter((value: any) => value !== undefined)
            .join(' ')
            .trim();
    }

    private startInterval(): void {
        if (this.intervalId !== null) return;

        this.intervalId = window.setInterval(async () => {
            if (!this.lastMissingItemsRequestDto) return;

            const previousHash: string = this.lastMissingItemsRequestHash;
            const previousRows: TableMissingItem[] = [...this.rows];

            // Update core asset retention dtos list and rows list, as well.
            this.assetRetentionDtos = [
                ...(await this.assetRetentionService.getMissingItems(
                    this.lastMissingItemsRequestDto,
                )),
            ];
            this.lastMissingItemsRequestHash = this.utilityService.hashData(
                this.assetRetentionDtos,
            );
            this.rows = this.assetRetentionDtos.map((dto) =>
                this.buildTableMissingItemFromAssetRetentionDto(dto),
            );

            if (previousHash !== this.lastMissingItemsRequestHash) {
                const changedItemIds: number[] = this.getChangedItems(previousRows, this.rows);
                const addedItems: TableMissingItem[] = this.getAddedItems(previousRows, this.rows);
                const removedItemIds: number[] = this.getRemovedItemIds(previousRows, this.rows);

                if (changedItemIds.length) this.updateChangedMissingItems(changedItemIds);
                if (addedItems.length) await this.addMissingItems(addedItems);
                if (removedItemIds.length) this.removeItemsByMissingItemIds(removedItemIds);
            }
        }, this.INTERVAL_SECONDS * 1000);
    }

    private stopInterval(): void {
        if (this.intervalId !== null) {
            window.clearInterval(this.intervalId);
            this.intervalId = null;
        }
    }

    private updateChangedMissingItems(changedItemIds: number[]): void {
        // Get all keys except missingItemId
        const propertiesToUpdate = Object.keys(this.rows[0]).filter(
            (key) =>
                !['missingItemId', 'isChecked', 'isDisabled', 'filterSearchText'].includes(key),
        ) as (keyof AssetRetentionDto)[];

        // Iterate over each changed object and update accordingly.
        changedItemIds.forEach((missingItemId: number) => {
            // Get updated missing item object.
            const updatedMissingItem: TableMissingItem | undefined =
                this.getMissingItemInRowsById(missingItemId);
            if (!updatedMissingItem) return;

            // Update the rowsFiltered list.
            const index: number = this.rowsFiltered.findIndex(
                (missingItem: TableMissingItem) => missingItem.missingItemId === missingItemId,
            );
            if (index !== -1) {
                // Update each property
                propertiesToUpdate.forEach((key) => {
                    // @ts-ignore
                    this.rowsFiltered[index][key] = updatedMissingItem[key];
                });
            }
        });
    }

    private async addMissingItems(missingItems: TableMissingItem[]) {
        missingItems.forEach((missingItem: TableMissingItem) => {
            const index = this.rowsFiltered.findIndex(
                (mi: TableMissingItem) => mi.missingItemId === missingItem.missingItemId,
            );
            if (index === -1) this.rowsFiltered.push(missingItem);
        });

        // Since added items, need to re-apply the filters to ensure
        // that the table is refreshed, and filters re-applied.
        this.shouldShowNoAssetsFound = !Boolean(this.assetRetentionDtos.length);
        await this.filterTable();
    }

    private removeItemsByMissingItemIds(missingItemIds: number[]): void {
        missingItemIds.forEach((missingItemId: number) => {
            const index = this.rowsFiltered.findIndex(
                (missingItem: TableMissingItem) => missingItem.missingItemId === missingItemId,
            );
            if (index !== -1) this.rowsFiltered.splice(index, 1);
        });
    }

    private getAddedItems(
        oldItems: TableMissingItem[],
        newItems: TableMissingItem[],
    ): TableMissingItem[] {
        const oldIds = new Set(oldItems.map((item) => item.missingItemId));
        return newItems.filter((newItem) => !oldIds.has(newItem.missingItemId));
    }

    private getRemovedItemIds(
        oldItems: TableMissingItem[],
        newItems: TableMissingItem[],
    ): number[] {
        const newIds = new Set(newItems.map((item) => item.missingItemId));
        return oldItems
            .filter((oldItem) => !newIds.has(oldItem.missingItemId))
            .map((item) => item.missingItemId);
    }

    private getChangedItems(oldItems: TableMissingItem[], newItems: TableMissingItem[]): number[] {
        const oldItemMap = new Map(oldItems.map((item) => [item.missingItemId, item.hash]));

        return newItems
            .filter((newItem) => {
                const oldHash = oldItemMap.get(newItem.missingItemId);
                return oldHash !== newItem.hash;
            })
            .map((missingItem) => missingItem.missingItemId);
    }

    private buildTableMissingItemFromAssetRetentionDto(dto: AssetRetentionDto): TableMissingItem {
        return {
            isChecked: false,
            filterSearchText: this.getFilterSearchText(dto),
            hash: this.utilityService.hashData(dto),
            isDisabled: false,
            ...dto,
        };
    }
}
