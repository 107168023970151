import { Component, OnInit } from '@angular/core';
import { MenuOption } from '../../../models/classes/MenuOption';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { Router, RouterOutlet } from '@angular/router';
import { AppToastComponent } from '../../../components/app-toast/app-toast.component';
import { StateService } from '../../../services/StateService';
import { User } from '../../../models/classes/User';
import { MainService } from '../../../services/MainService';
import { AppDataResponse } from '../../../models/interfaces/AppDataResponse';
import { UtilityService } from '../../../services/UtilityService';
import { LoadingIndicatorService } from '../../../services/LoadingIndicatorService';
import { AppComponentLoadingIndicatorComponent } from '../../../components/app-component-loading-indicator/app-component-loading-indicator.component';
import {
    DistrictCountDto,
    DistrictResponseDto,
    DistrictsDto,
} from '../../../models/dtos/DistrictResponseDto';
import { CountDto } from '../../../models/dtos/CountDto';
import { MsalService } from '@azure/msal-angular';
import { ConfirmationModalService } from '../../../services/ConfirmationModalService';

@Component({
    selector: 'app-main',
    standalone: true,
    imports: [
        NgForOf,
        NgClass,
        RouterOutlet,
        AppToastComponent,
        NgIf,
        AppComponentLoadingIndicatorComponent,
    ],
    templateUrl: './main.component.html',
    styleUrl: './main.component.scss',
})
export class MainComponent implements OnInit {
    private readonly defaultMenuOption: string = 'home';

    menuOptions: MenuOption[] = [
        {
            name: 'home',
            text: 'Home',
            iconCss: 'material-icons-outlined',
            icon: 'home',
            route: '/main/home',
        },
        {
            name: 'asset-retention',
            text: 'Asset Retention',
            iconCss: 'material-icons',
            icon: 'list',
            route: '/main/asset-retention',
        },
        {
            name: 'asset-count-live',
            text: 'Asset Count Monitor',
            iconCss: 'material-icons-outlined',
            icon: 'live_tv',
            route: '/main/asset-count-live',
        },
        {
            name: 'reports',
            text: 'Asset Count Reports',
            iconCss: 'material-icons-outlined',
            icon: 'assignment',
            route: '/main/reports',
        },
    ];
    selectedOption: string = '';
    isAppDataLoaded: boolean = false;

    constructor(
        private router: Router,
        private stateService: StateService,
        private mainService: MainService,
        private msalService: MsalService,
        private utilityService: UtilityService,
        private loadingIndicatorService: LoadingIndicatorService,
        private confirmationModalService: ConfirmationModalService,
    ) {
        // Store the last path segment before refresh.
        window.onbeforeunload = () => {
            const currentPath = this.router.url;
            if (currentPath && currentPath !== '/main') {
                sessionStorage.setItem('lastRoute', currentPath);
            }
            return null;
        };
    }

    async ngOnInit() {
        this.loadingIndicatorService.set('fullScreen', true, 'Initializing...');
        this.handlePageInitializationRouting();
        await this.initializeData();
        this.initializeSelectedMenuOption();
        await this.utilityService.sleep(350);
        this.loadingIndicatorService.set('fullScreen', false);
    }

    get getUserFullName(): string {
        const user: User = this.stateService.get('user') as User;
        return user && user?.firstName && user?.lastName
            ? `${user.firstName} ${user.lastName}`
            : '';
    }

    async initializeData() {
        const response: AppDataResponse = await this.mainService.getAppInitData();
        const { completedCounts, appData } = response;
        const { districts, users, counts } = appData;

        this.stateService.set('completedCounts', completedCounts);
        this.stateService.set('districts', districts);
        this.stateService.set('users', users);
        this.stateService.set('counts', counts);

        this.initializeDistrictCounts(districts, counts);

        this.isAppDataLoaded = true;
    }

    async setSelectedOption(optionName: string) {
        // Set selected menu option.
        this.selectedOption = optionName;

        const menuOption: MenuOption | undefined = this.menuOptions.find(
            (option) => option.name === optionName,
        );

        if (menuOption) {
            await this.router.navigate([menuOption.route]);
        }
    }

    async handleLogout() {
        const response = await this.confirmationModalService.open(
            `Log Out?`,
            `Are you sure you want to log out?`,
        );

        if (!response) return;

        this.loadingIndicatorService.set('fullScreen', true, 'Logging out...');
        await this.utilityService.sleep(500);

        // First clear local state.
        this.msalService.instance.setActiveAccount(null);
        await this.msalService.instance.clearCache();

        // Then, navigate back to the login screen.
        await this.router.navigate(['/login']);

        // Then logout from Microsoft without redirect back through the MS auth workflow.
        this.msalService.logoutRedirect({
            postLogoutRedirectUri: window.location.origin + '/login',
            onRedirectNavigate: () => false,
        });

        await this.utilityService.sleep(350);
        this.loadingIndicatorService.set('fullScreen', false);
    }

    private handlePageInitializationRouting(): void {
        // Initialize data for direct history route access
        const isHistoryRoute = this.router.url.includes('asset-retention-history');
        if (isHistoryRoute) {
            return; // Skip regular route handling
        }

        if (this.router.url === '/main') {
            const lastRoute = sessionStorage.getItem('lastRoute');
            const intendedRoute = sessionStorage.getItem('intendedRoute');
            const targetRoute = lastRoute || intendedRoute;

            if (targetRoute) {
                if (targetRoute.startsWith('/main/')) {
                    this.router.navigate([targetRoute]);
                } else {
                    this.router.navigate([`/main/${targetRoute}`]);
                }
                sessionStorage.removeItem('lastRoute');
                sessionStorage.removeItem('intendedRoute');
            }
        }
    }

    private async initializeSelectedMenuOption() {
        const isHistoryRoute: boolean = this.router.url.includes('asset-retention-history');
        if (isHistoryRoute) {
            return;
        }

        const currentPath: string | undefined = this.router.url.split('/').pop();
        const matchingOption: MenuOption | undefined = this.menuOptions.find(
            (option) => option.name === currentPath,
        );
        const selectedOption: string = matchingOption
            ? matchingOption.name
            : this.defaultMenuOption;

        await this.setSelectedOption(selectedOption);
    }

    private initializeDistrictCounts(districts: DistrictsDto, counts: CountDto[]): void {
        ['active', 'inactive'].forEach((type) => {
            // @ts-ignore
            districts[type].forEach((district: DistrictResponseDto) => {
                district.counts = district.counts
                    ?.map((countId) => counts.find((count) => count.id === countId))
                    ?.filter((count) => count !== undefined)
                    ?.map((count) => {
                        return {
                            id: count.id,
                            startDate: count.startDate,
                            endDate: count.endDate,
                        } as DistrictCountDto;
                    });
            });
        });
    }
}
