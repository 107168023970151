/* src/components/app-table/app-table.component.scss */
.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis,
:host ::ng-deep .app-table .table thead th {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
:host ::ng-deep .app-table .table thead th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
:host ::ng-deep .app-table {
  position: relative;
  height: 100%;
}
:host ::ng-deep .app-table::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
:host ::ng-deep .app-table .table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-width: 0;
  border-bottom: 1px solid #bbb !important;
}
:host ::ng-deep .app-table .table thead,
:host ::ng-deep .app-table .table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
:host ::ng-deep .app-table .table thead {
  position: sticky;
  width: calc(100% - 15px) !important;
  top: 0;
  z-index: 2;
}
:host ::ng-deep .app-table .table thead th {
  font-size: 12px;
  font-weight: 600 !important;
  color: #252525;
  height: 41px;
  border-bottom: 1px solid #bbb !important;
  background-color: transparent !important;
  padding-top: 0;
  padding-bottom: 0;
}
:host ::ng-deep .app-table .table thead th:first-child {
  z-index: 3;
}
:host ::ng-deep .app-table .table thead th div {
  line-height: 41px;
}
:host ::ng-deep .app-table .table thead th.sortable {
  cursor: pointer;
}
:host ::ng-deep .app-table .table thead th.sortable:hover {
  background-color: #f8f9fa;
}
:host ::ng-deep .app-table .table thead th.sortable .sort-icon {
  display: inline-block;
  width: 31px;
  height: 31px;
  margin-left: 5px;
  vertical-align: middle;
}
:host ::ng-deep .app-table .table thead th.sortable .sort-icon svg {
  fill: currentColor;
}
:host ::ng-deep .app-table .table tbody {
  display: block;
  overflow-x: hidden;
  overflow-y: scroll;
  height: calc(100% - 41px);
}
:host ::ng-deep .app-table .table tbody.align-top td {
  vertical-align: top !important;
}
:host ::ng-deep .app-table .table tbody tr > * {
  vertical-align: top;
}
:host ::ng-deep .app-table .table tbody tr.disabled {
  opacity: 0.4;
}
:host ::ng-deep .app-table .table tbody tr td {
  vertical-align: middle;
}
:host ::ng-deep .app-table .table tbody tr:nth-child(odd) {
  background-color: rgba(221, 221, 221, 0.1) !important;
}
:host ::ng-deep .app-table .table tbody tr:nth-child(even) {
  background-color: transparent !important;
}
:host ::ng-deep .app-table .table tbody tr:hover {
  background-color: rgba(173, 216, 230, 0.15) !important;
}
:host ::ng-deep .app-table .table th,
:host ::ng-deep .app-table .table td {
  font-size: 12px;
  padding: 8px 15px 8px 10px;
  border-bottom: 1px solid #ddd;
  background-color: transparent !important;
}
:host ::ng-deep .app-table .table th:first-child,
:host ::ng-deep .app-table .table td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: transparent !important;
}
:host ::ng-deep .app-table .table td {
  padding-top: 10px;
  padding-bottom: 12px;
}
/*# sourceMappingURL=app-table.component.css.map */
