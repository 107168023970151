import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Toast, ToastState } from '../models/interfaces/Toast';
import { ToastPositionType } from '../models/types/ToastPositionType';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    private toastSubject = new BehaviorSubject<ToastState | null>(null);
    private defaultDuration = 3000;
    private defaultFadeTime = 150;
    private defaultPosition: ToastPositionType = 'top-center';

    show(toast: Toast): void {
        const duration = toast.duration ?? this.defaultDuration;
        const fadeInTime = toast.fadeInTime ?? this.defaultFadeTime;
        const fadeOutTime = toast.fadeOutTime ?? this.defaultFadeTime;
        const position = toast.position ?? this.defaultPosition;

        // Start fade in
        this.toastSubject.next({ ...toast, visible: true, opacity: 0 });

        setTimeout(() => {
            this.toastSubject.next({ ...toast, visible: true, opacity: 1 });

            // Start fade out after show time
            setTimeout(() => {
                this.hide();
            }, duration);
        }, fadeInTime);
    }

    private hide(): void {
        const currentToast = this.toastSubject.value;
        if (currentToast) {
            this.toastSubject.next({ ...currentToast, opacity: 0 });
            setTimeout(() => {
                this.toastSubject.next(null);
            }, currentToast.fadeOutTime ?? this.defaultFadeTime);
        }
    }

    get(): Observable<ToastState | null> {
        return this.toastSubject.asObservable();
    }
}
