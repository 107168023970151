import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AssetRetentionService } from '../../../../../../../services/AssetRetentionService';
import { LoadingIndicatorService } from '../../../../../../../services/LoadingIndicatorService';
import { UtilityService } from '../../../../../../../services/UtilityService';
import { StateService } from '../../../../../../../services/StateService';
import { AppModalLoadingIndicatorComponent } from '../../../../../../../components/app-modal-loading-indicator/app-modal-loading-indicator.component';
import { NgForOf, NgIf, NgStyle } from '@angular/common';
import { MissingItemImage } from '../../../../../../../models/dtos/AssetRetentionDto';
import { findIndex, orderBy } from 'lodash-es';
import { AppButtonComponent } from '../../../../../../../components/app-button/app-button.component';
import { ConfirmationModalService } from '../../../../../../../services/ConfirmationModalService';
import { User } from '../../../../../../../models/classes/User';

@Component({
    selector: 'manage-images-modal',
    standalone: true,
    imports: [AppModalLoadingIndicatorComponent, NgIf, NgForOf, NgStyle, AppButtonComponent],
    templateUrl: './manage-images-modal.component.html',
    styleUrls: [
        './manage-images-modal.component.scss',
        '../../../../../../../styles/_colors.scss',
        '../../../../../../../styles/_common.scss',
    ],
})
export class ManageImagesModalComponent implements OnInit {
    protected readonly orderBy = orderBy;

    selectedFile: File | null = null;
    errorMessage: string | null = null;
    selectedImageId: number | null = null;
    selectedImageUrl: string | null = null;
    selectedImageIndex: number | null = null;
    shouldShowUploadImageForm: boolean = false;

    @Input() images: MissingItemImage[] = [];
    @Input() missingItemId: number | undefined;
    @Input() assetNumber: string = '';

    constructor(
        public activeModal: NgbActiveModal,
        private assetRetentionService: AssetRetentionService,
        private confirmationModalService: ConfirmationModalService,
        private loadingIndicatorService: LoadingIndicatorService,
        private utilityService: UtilityService,
        private stateService: StateService,
    ) {}

    ngOnInit() {
        this.initializeModalImage();

        if (!this.images || this.images.length === 0) {
            this.displayUploadImageForm();
        }
    }

    get imagesLength(): number {
        let length = 0;

        if (this.images && this.images.length) {
            length = this.images.length;
        }

        return length;
    }

    closeModal(result: boolean): void {
        this.activeModal.close(result);
    }

    initializeModalImage(): void {
        if (this.images?.length) {
            this.selectedImageId = this.images[0].id;
            this.selectedImageUrl = this.images[0].url;
            this.selectedImageIndex = 1;
        }
    }

    displayUploadImageForm(): void {
        this.shouldShowUploadImageForm = true;
    }

    async deleteImage() {
        const imageId: number = this.selectedImageId as number;
        const response = await this.confirmationModalService.open(
            `Confirm Delete`,
            `Are you sure you want to delete image #${this.selectedImageIndex} for this asset (${this.assetNumber})?`,
        );

        if (response) {
            this.loadingIndicatorService.set('modal', true, 'Deleting...');

            // Delete from interface, and select another image if there are any left.
            // If none left, set selected properties to default/null values.
            await this.assetRetentionService.deleteMissingItemImage(imageId);
            // TODO consider adding a temp message displayed to the screen ~"image successfully deleted"
            this.removeSelectedImage();
            if (this.images.length) {
                this.displayImage(this.images[0].id, this.images[0].url);
            } else if (!this.images || this.images.length === 0) {
                this.displayUploadImageForm();
            }

            await this.utilityService.sleep(350);
            this.loadingIndicatorService.set('modal', false);
        }
    }

    displayImage(id: number, url: string): void {
        this.selectedImageId = id;
        this.selectedImageUrl = url;
        this.selectedImageIndex = findIndex(this.images, { id: id }) + 1;
    }

    removeSelectedImage(): void {
        const index = this.images.findIndex((image) => image.id === this.selectedImageId);
        if (index > -1) this.images.splice(index, 1);

        this.selectedImageId = null;
        this.selectedImageUrl = null;
        this.selectedImageIndex = null;
    }

    closeUploadForm(): void {
        this.shouldShowUploadImageForm = false;
        this.selectedFile = null;
        this.errorMessage = null;
    }

    onFileSelected(event: any): void {
        const file = event.target.files[0];

        if (file && file.type.startsWith('image/')) {
            this.selectedFile = file;
            this.errorMessage = null;
        } else {
            this.errorMessage = 'Please select a valid image file';
            this.selectedFile = null;
        }
    }

    async uploadFile(): Promise<void> {
        if (!this.selectedFile) return;

        try {
            this.loadingIndicatorService.set('modal', true, 'Uploading...');
            const user = this.stateService.get('user') as User;
            const image: MissingItemImage = await this.assetRetentionService.uploadMissingItemImage(
                {
                    missingItemId: this.missingItemId as number,
                    userName: user.username,
                    image: this.selectedFile,
                },
            );

            this.closeUploadForm();
            this.images.push(image);
            this.displayImage(image.id, image.url);
            await this.utilityService.sleep(350);
            this.loadingIndicatorService.set('modal', false);
        } catch (error: any) {
            this.errorMessage = error.message || 'Upload failed';
            this.loadingIndicatorService.set('modal', false);
        }
    }
}
