<div class="app-confirmation-modal">
    <div class="modal-container">
        <div class="modal-header">
            <div class="title">
                {{ title }}
            </div>
            <div
                class="close"
                aria-label="Close"
                (click)="activeModal.dismiss()"
            >
                <span class="material-icons icon">close</span>
            </div>
        </div>

        <div
            class="modal-body"
            [innerHTML]="message"
        ></div>

        <div class="modal-footer">
            <button
                type="button"
                class="app-button"
                (click)="onNo()"
            >
                No
            </button>

            <button
                type="button"
                class="app-button-primary"
                (click)="onYes()"
            >
                Yes
            </button>
        </div>
        <app-modal-loading-indicator />
    </div>
</div>
