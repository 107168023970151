import { Component, Input, Output, EventEmitter, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectOption } from '../../models/classes/SelectOption';

@Component({
    selector: 'app-select',
    standalone: true,
    imports: [CommonModule, FormsModule],
    template: `
        <select
            [id]="id"
            [ngClass]="getClasses()"
            [style.padding]="narrow !== null ? '0 10px' : null"
            [title]="tooltip"
            [(ngModel)]="selectedValue"
            (ngModelChange)="onSelectionChange($event)"
        >
            <option
                *ngFor="let option of options"
                [value]="option.value"
            >
                {{ option.text }}
            </option>
        </select>
    `,
    styleUrls: ['../../styles/_controls.scss'],
})
export class AppSelectComponent {
    @Input() options: SelectOption[] = [];
    @Input() tooltip: string = '';
    @Input() selectedValue: string | undefined = undefined;
    @Input() id?: string;
    @Input() class: string = '';

    @HostBinding('attr.small')
    @Input()
    small: '' | null = null;

    @HostBinding('attr.medium')
    @Input()
    medium: '' | null = null;

    @HostBinding('attr.narrow')
    @Input()
    narrow: '' | null = null;

    @Output() selectedValueChange = new EventEmitter<string>();

    onSelectionChange(value: string) {
        this.selectedValueChange.emit(value);
    }

    getClasses(): string {
        const classes = ['app-select'];

        if (this.class) {
            classes.push(this.class);
        }

        if (this.small !== null) {
            classes.push('small');
        } else if (this.medium !== null) {
            classes.push('medium');
        }

        return classes.join(' ');
    }
}
