import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { firstValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompletedCount } from '../models/classes/CompletedCount';

@Injectable({
    providedIn: 'root',
})
export class ReportsService {
    private urls = environment.endpoints;

    constructor(private http: HttpClient) {}

    async getCompletedCounts(): Promise<CompletedCount[]> {
        const url = this.urls.getCompletedCounts;
        return await firstValueFrom(this.http.get<CompletedCount[]>(url));
    }

    getSummaryReport(countId: string, warehouseCode: string): Observable<Blob> {
        const url = this.urls.downloadSummaryReport;
        const body = { id: countId, warehouseCode };

        return this.http
            .post(url, body, {
                responseType: 'blob',
                observe: 'response',
            })
            .pipe(map((response) => response.body as Blob));
    }

    getFinalStateReport(countId: string, warehouseCode: string): Observable<Blob> {
        const url = this.urls.downloadFinalStateReport;
        const body = { id: countId, warehouseCode };

        return this.http
            .post(url, body, {
                responseType: 'blob',
                observe: 'response',
            })
            .pipe(map((response) => response.body as Blob));
    }
}
