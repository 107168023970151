import {
    APP_INITIALIZER,
    ApplicationConfig,
    importProvidersFrom,
    provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideHttpClient } from '@angular/common/http';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import {
    MSAL_GUARD_CONFIG,
    MSAL_INSTANCE,
    MSAL_INTERCEPTOR_CONFIG,
    MsalGuard,
    MsalGuardConfiguration,
    MsalInterceptor,
    MsalInterceptorConfiguration,
    MsalService,
    ProtectedResourceScopes,
    MsalBroadcastService,
    MsalModule,
} from '@azure/msal-angular';
import {
    BrowserCacheLocation,
    IPublicClientApplication,
    InteractionType,
    PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

// MSAL instance configuration.
const msalInstanceConfig = {
    auth: {
        clientId: 'a4cda5e0-c634-4f34-9a58-5926365b85ad',
        authority: 'https://login.microsoftonline.com/bda43523-4404-4833-b00d-90e88aa1f2b3/',
        redirectUri: environment.msal.redirectUri,
        postLogoutRedirectUri: environment.msal.postLogoutRedirectUri,
        navigateToLoginRequestUrl: true, // This is important
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
    },
};

// MSAL guard configuration.
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['user.read'],
            extraQueryParameters: {
                // Store the full URL as a state parameter
                state: window.location.pathname,
            },
        },
    };
}

// MSAL interceptor configuration.
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

    return {
        interactionType: InteractionType.Redirect,
        protectedResourceMap,
    };
}

export const appConfig: ApplicationConfig = {
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: () => {
                // Capture the intended route before MSAL initialization.
                const path = window.location.pathname;
                if (path && path !== '/main') {
                    sessionStorage.setItem('intendedRoute', path);
                }
                return () => Promise.resolve();
            },
            multi: true,
        },
        provideHttpClient(),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(routes),
        importProvidersFrom(NgbModalModule, MsalModule),
        {
            provide: MSAL_INSTANCE,
            useFactory: (): IPublicClientApplication => {
                return new PublicClientApplication(msalInstanceConfig);
            },
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalInterceptor,
        MsalBroadcastService,
    ],
};
