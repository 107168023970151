import { Component, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AppButtonComponent } from '../../../components/app-button/app-button.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [AppButtonComponent],
    template: `
        <div class="auth-background">
            <div class="auth-form">
                <img
                    class="logo"
                    src="assets/logos/tts-web-auth-logo.png"
                    alt="Logo"
                />
                <app-button
                    class="login-button"
                    primary
                    width="100%"
                    text="Login"
                    (click)="login()"
                />
            </div>
        </div>
    `,
    styleUrl: './auth.component.scss',
})
export class AuthComponent implements OnInit {
    constructor(
        private msalService: MsalService,
        private router: Router,
    ) {}

    ngOnInit() {
        // Check if we're coming from a logout.
        if (this.msalService.instance.getAllAccounts().length === 0) {
            // We're properly logged out, just stay on login page
            return;
        }

        // If we have an account, handle normal auth flow.
        this.msalService.instance.handleRedirectPromise().then((result) => {
            if (result) {
                this.router.navigate(['/main']);
            }
        });
    }

    login() {
        // Check if an interaction is already in progress.
        const isInteractionInProgress =
            sessionStorage.getItem('msal.interaction.status') === 'interaction_in_progress';

        if (isInteractionInProgress) {
            console.warn('Login interaction already in progress.');
            return; // Prevent overlapping calls.
        }

        // Initiate login flow.
        this.msalService.loginRedirect({
            prompt: 'login', // Force a login prompt.
            scopes: ['user.read'], // Set required scopes.
        });
    }
}
