import { Component, Input, OnInit } from '@angular/core';
import { AppModalLoadingIndicatorComponent } from '../../../../../../../components/app-modal-loading-indicator/app-modal-loading-indicator.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingIndicatorService } from '../../../../../../../services/LoadingIndicatorService';
import { AssetRetentionService } from '../../../../../../../services/AssetRetentionService';
import { UtilityService } from '../../../../../../../services/UtilityService';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { AppButtonComponent } from '../../../../../../../components/app-button/app-button.component';
import {
    AssetType,
    JunkTicketDto,
    JunkTicketResults,
} from '../../../../../../../models/dtos/JunkTicketDto';
import { JunkTicketNoteDto } from '../../../../../../../models/dtos/JunkTicketNoteDto';
import { AcknowledgementModalService } from '../../../../../../../services/AcknowledgementModalService';
import { AppTabItem } from '../../../../../../../models/interfaces/AppTabItem';
import { AppTabsComponent } from '../../../../../../../components/app-tabs/app-tabs.component';
import { AppTextareaComponent } from '../../../../../../../components/app-textarea/app-textarea.component';

@Component({
    selector: 'app-add-to-junk-ticket-modal',
    standalone: true,
    imports: [
        AppModalLoadingIndicatorComponent,
        ReactiveFormsModule,
        NgIf,
        FormsModule,
        AppButtonComponent,
        NgForOf,
        NgClass,
        AppTabsComponent,
        AppTextareaComponent,
    ],
    templateUrl: './post-to-junk-ticket-modal.component.html',
    styleUrls: [
        './post-to-junk-ticket-modal.component.scss',
        '../../../../../../../styles/_colors.scss',
        '../../../../../../../styles/_common.scss',
        '../../../../../../../styles/_tabs.scss',
    ],
})
export class PostToJunkTicketModalComponent implements OnInit {
    readonly minNoteLength: number = 10;
    readonly maxNoteLength: number = 3500;

    isInitialized: boolean = false;
    view: 'intro' | 'results' = 'intro';
    hasNote: boolean = true;
    notes: string = '';
    results: JunkTicketDto[] | null = null;
    selectedAssetType: AssetType | null = null;
    selectedResults: JunkTicketDto | undefined = undefined;
    isValidCopied: boolean = false;
    isInvalidCopied: boolean = false;
    appTabItems: AppTabItem[] = [];
    selectedAppTabItem: string = '';

    @Input() missingItemIds: number[] = [];
    @Input() assetNumbers: number[] = [];
    @Input() assetCountId: string = '';

    constructor(
        public activeModal: NgbActiveModal,
        private acknowledgementModalService: AcknowledgementModalService,
        private loadingIndicatorService: LoadingIndicatorService,
        private assetRetentionService: AssetRetentionService,
        private utilityService: UtilityService,
    ) {}

    async ngOnInit() {
        this.loadingIndicatorService.set('modal', true, 'Initializing...');
        await this.initializeData();
    }

    get notesLength(): number {
        let length: number = 0;

        if (this.notes && this.notes.length) {
            length = this.notes.trim().length;
        }

        return length;
    }

    get isLengthValid(): boolean {
        let isValid: boolean = false;

        if (this.notes && this.notes.length) {
            isValid = this.notes.trim().length >= this.minNoteLength;
        }

        return isValid;
    }

    get isPostButtonEnabled(): boolean {
        return (this.hasNote || this.isLengthValid) && this.isInitialized;
    }

    get failedAssetsLength(): number {
        let length: number = 0;

        if (
            this.selectedResults &&
            this.selectedResults.failedAssets &&
            this.selectedResults.failedAssets.length
        ) {
            length = this.selectedResults.failedAssets.length;
        }

        return length;
    }

    get postedAssetsLength(): number {
        let length: number = 0;

        if (
            this.selectedResults &&
            this.selectedResults.postedAssets &&
            this.selectedResults.postedAssets.length
        ) {
            length = this.selectedResults.postedAssets.length;
        }

        return length;
    }

    initializeAppTabItems(): void {
        if (!this.results) {
            this.appTabItems = [];
            return;
        }

        this.appTabItems = this.results.map((dto: JunkTicketDto) => {
            const name = dto.assetType;
            const html = `<span class="app-tabs-ticket-type">${dto.assetType}-Type</span> ${dto.ticketId}`;
            return {
                name,
                text: html,
            };
        });
    }

    async postToTicket() {
        this.loadingIndicatorService.set('modal', true, 'Posting...');

        try {
            this.results = (
                await this.assetRetentionService.postJunkTicket(this.notes, this.missingItemIds)
            ).junkTicketResults;
            this.view = 'results';
            this.selectedAssetType = this.results ? this.results[0].assetType : null;
            this.selectedAppTabItem = this.results[0].assetType as string;
            this.initializeAppTabItems();
            this.selectedResults = this.results ? this.results[0] : undefined;
            await this.utilityService.sleep(350);
            this.loadingIndicatorService.set('modal', false);
        } catch (error) {
            console.error('error', error);
            this.activeModal.close(false);
            this.loadingIndicatorService.set('modal', false);
            await this.acknowledgementModalService.open(
                'Error Posting',
                'An error occurred while posting these assets. Please try again.',
            );
        }
    }

    copyToClipboard(type: string): void {
        let text: string = '';
        let copiedBool: string = '';

        if (type === 'valid') {
            copiedBool = 'isValidCopied';
            if (this.selectedResults?.postedAssets?.length == 0) return;
            text = this.selectedResults?.postedAssets
                .map((assetNumber) => `${assetNumber}\r\n`)
                .join('') as string;
        } else if (type === 'invalid') {
            copiedBool = 'isInvalidCopied';
            if (this.selectedResults?.failedAssets?.length == 0) return;
            text = this.selectedResults?.failedAssets
                .map((failedAsset) => `${failedAsset.assetNumber}\t${failedAsset.errorMessage}\r\n`)
                .join('') as string;
        }

        if (text)
            navigator.clipboard.writeText(text).then(() => {
                // @ts-ignore
                this[copiedBool] = true;
                // @ts-ignore
                setTimeout(() => (this[copiedBool] = false), 2500);
            });
    }

    setSelectedTab(type: any): void {
        this.selectedAssetType = type as AssetType;
        this.selectedAppTabItem = type as string;
        this.initializeAppTabItems();
        this.selectedResults = this.results?.find((result) => result.assetType === type);
    }

    closeModal(result: boolean): void {
        if (this.results) {
            this.updateMissingItemsWithResults(this.results);
        } else {
            this.activeModal.close(result);
        }
    }

    private updateMissingItemsWithResults(junkTickets: JunkTicketDto[]): void {
        const results: JunkTicketResults = { failedAssets: [], postedAssets: [] };

        junkTickets.forEach((junkTicket: JunkTicketDto) => {
            if (junkTicket) {
                if (junkTicket.failedAssets) {
                    results.failedAssets = [...results.failedAssets, ...junkTicket.failedAssets];
                }

                if (junkTicket.postedAssets)
                    results.postedAssets = [...results.postedAssets, ...junkTicket.postedAssets];
            }
        });

        if (results.postedAssets.length || results.failedAssets.length) {
            this.activeModal.close(results);
        }
    }

    private async initializeData() {
        const dto: JunkTicketNoteDto = await this.assetRetentionService.getJunkTicketNote(
            this.assetCountId,
        );

        // Initialize notes properties.
        this.hasNote = !!dto.note;
        this.notes = dto.note || '';

        await this.utilityService.sleep(350);
        this.loadingIndicatorService.set('modal', false);
        this.isInitialized = true;
    }
}
