import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
    providedIn: 'root',
})
export class ExcelService {
    constructor() {}

    generate(data: any, fileName: string): any {
        // Create workbook and worksheet.
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(data);

        // Add worksheet to workbook.
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Report');

        // Save file.
        XLSX.writeFile(workbook, `${fileName}.xlsx`);
    }
}
