import { Injectable } from '@angular/core';
import { SHA256 } from 'crypto-js';
import { isEmpty, xor } from 'lodash-es';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    sleep(milliseconds: number): Promise<any> {
        return new Promise((resolve) => setTimeout(resolve, milliseconds));
    }

    hashData(data: any): string {
        const normalized = JSON.stringify(data, (key, value) => {
            if (typeof value === 'object' && value !== null) {
                return Object.keys(value)
                    .sort()
                    .reduce((acc, key) => {
                        // @ts-ignore
                        acc[key] = value[key];
                        return acc;
                    }, {});
            }
            return value;
        });

        return SHA256(normalized).toString();
    }

    areListsSame(list1: any[] | undefined | null, list2: any[] | undefined | null): boolean {
        return isEmpty(xor(list1, list2));
    }
}
