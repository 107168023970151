import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-readonly-field',
    template: `
        <div class="app-readonly-field">
            <div class="label">{{ label }}</div>
            <div
                class="text"
                [ngClass]="{ 'no-value': text === '' }"
            >
                {{ text !== '' ? text : '--' }}
            </div>
        </div>
    `,
    standalone: true,
    styleUrl: '../../styles/_controls.scss',
    imports: [NgClass],
})
export class AppReadonlyFieldComponent {
    @Input() label: string = '';
    @Input() text: string = '';
}
