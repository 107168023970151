<div class="ticket-details-modal">
    <div class="modal-container">
        <div class="modal-header">
            <div class="title"> Ticket Detail - {{ ticketId }} </div>
            <div
                class="close"
                aria-label="Close"
                (click)="closeModal(false)"
            >
                <span class="material-icons icon">close</span>
            </div>
        </div>

        <div class="modal-body">
            <div
                class="columns"
                *ngIf="ticket"
            >
                <div class="column left">
                    <app-readonly-field
                        label="Asset #"
                        [text]="assetNumberDisplay"
                    />

                    <app-readonly-field
                        label="Office"
                        [text]="ticket.office"
                    />

                    <app-readonly-field
                        label="Type"
                        [text]="ticket.type"
                    />

                    <app-readonly-field
                        label="Dispatcher"
                        [text]="ticket.dispatcher"
                    />
                </div>
                <div class="column right">
                    <app-readonly-field
                        label="Account"
                        [text]="ticket.account"
                    />

                    <app-readonly-field
                        label="Posted"
                        [text]="ticket.posted"
                    />

                    <app-readonly-field
                        label="Delivery Date"
                        [text]="ticket.deliveryDate"
                    />
                </div>
            </div>

            <!--Notes-->
            <div
                class="notes"
                *ngIf="ticket"
            >
                <app-readonly-field
                    label="Notes"
                    [text]="ticket.note"
                />
            </div>

            <!--System Notes-->
            <div
                class="notes"
                *ngIf="ticket?.systemNote"
            >
                <app-readonly-field
                    label="System Notes"
                    [text]="systemNoteDisplay"
                />
            </div>
        </div>
        <app-modal-loading-indicator />
    </div>
</div>
