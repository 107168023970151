import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingIndicatorService } from '../../../../../../../services/LoadingIndicatorService';
import { AssetRetentionService } from '../../../../../../../services/AssetRetentionService';
import { UtilityService } from '../../../../../../../services/UtilityService';
import { AppModalLoadingIndicatorComponent } from '../../../../../../../components/app-modal-loading-indicator/app-modal-loading-indicator.component';
import {
    RetentionHistoryCountsDto,
    RetentionHistoryDto,
} from '../../../../../../../models/dtos/RetentionHistoryDto';
import { CountDto } from '../../../../../../../models/dtos/CountDto';
import { StateService } from '../../../../../../../services/StateService';
import { format } from 'date-fns';
import { FormsModule } from '@angular/forms';
import { DecimalPipe, NgForOf } from '@angular/common';

@Component({
    selector: 'app-retention-history-modal',
    standalone: true,
    imports: [AppModalLoadingIndicatorComponent, FormsModule, NgForOf, DecimalPipe],
    templateUrl: './retention-history-modal.component.html',
    styleUrls: [
        './retention-history-modal.component.scss',
        '../../../../../../../styles/_common.scss',
    ],
})
export class RetentionHistoryModalComponent implements OnInit {
    history: RetentionHistoryDto[] = [];

    constructor(
        public activeModal: NgbActiveModal,
        private assetRetentionService: AssetRetentionService,
        private loadingIndicatorService: LoadingIndicatorService,
        private utilityService: UtilityService,
        private stateService: StateService,
    ) {}

    async ngOnInit() {
        this.loadingIndicatorService.set('modal', true);
        await this.initializeRetentionHistoryData();
    }

    closeModal(result: boolean): void {
        this.activeModal.close(result);
    }

    routeToCountHistory(assetCountId: string): void {
        this.activeModal.close(assetCountId);
    }

    private async initializeRetentionHistoryData() {
        this.history = [];
        const retentionHistoryCountsDto: RetentionHistoryCountsDto[] =
            await this.assetRetentionService.getRetentionHistory();
        const counts: CountDto[] = this.stateService.get('counts') as CountDto[];

        // Post-process asset retention history data, adding in the count properties.
        for (let dto of retentionHistoryCountsDto) {
            const { assetCountId, invalidAssetCount, validAssetCount } = dto;
            const count: CountDto | undefined = counts.find((count) => count.id === assetCountId);
            if (!count) continue;
            const { districtCode, districtName, startDate, endDate } = count;

            this.history.push({
                assetCountId,
                invalidAssetCount,
                validAssetCount,
                districtCode,
                districtName,
                startDate: format(startDate, 'M/d/yyyy'),
                endDate: format(endDate, 'M/d/yyyy'),
            });
        }

        await this.utilityService.sleep(350);
        this.loadingIndicatorService.set('modal', false);
    }
}
