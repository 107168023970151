import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../environments/environment';
import { catchError, firstValueFrom } from 'rxjs';
import { GetCommentDto } from '../models/dtos/GetCommentDto';
import { AssetRetentionDto, MissingItemImage } from '../models/dtos/AssetRetentionDto';
import { ResolveAssetDto } from '../models/dtos/ResolveAssetDto';
import { TicketDto } from '../models/dtos/TicketDto';
import { MissingItemsRequestDto } from '../models/interfaces/MissingItemsRequestDto';
import { ErrorDto } from '../models/dtos/ErrorDto';
import { HttpError } from '../models/interfaces/HttpError';
import { RetentionHistoryCountsDto } from '../models/dtos/RetentionHistoryDto';
import { StateService } from './StateService';
import { User } from '../models/classes/User';
import { AddJunkTicketResponseDto } from '../models/dtos/JunkTicketDto';
import { JunkTicketNoteDto } from '../models/dtos/JunkTicketNoteDto';
import { MissingItemImageUploadDto } from '../models/dtos/MissingItemImageUploadDto';
import { CountRetentionHistoryDto } from '../models/dtos/CountRetentionHistoryDto';

@Injectable({
    providedIn: 'root',
})
export class AssetRetentionService {
    private urls = environment.endpoints;

    constructor(
        private http: HttpClient,
        private stateService: StateService,
    ) {}

    async addAsset(
        assetNumber: string,
        districtCode: string,
        userName: string,
        assetCountId: string,
    ) {
        const url = this.urls.addAsset;
        const body = { assetNumber, districtCode, userName, assetCountId };

        return await firstValueFrom(
            this.http.post<AssetRetentionDto | HttpError>(url, body).pipe(
                catchError((error) => {
                    if (error.status === 400) {
                        const errorDto = error.error as ErrorDto;
                        throw {
                            ...errorDto,
                            status: error.status,
                        } as HttpError;
                    }
                    throw error;
                }),
            ),
        );
    }

    async editComment(missingItemId: number, comment: string, userName: string) {
        const url = this.urls.editComment;
        const body = { missingItemId, comment, userName };
        return await firstValueFrom(this.http.post(url, body));
    }

    async getMissingItems(body: MissingItemsRequestDto): Promise<AssetRetentionDto[]> {
        const url: string = this.urls.getMissingItems;
        return await firstValueFrom(this.http.post<AssetRetentionDto[]>(url, body));
    }

    async getComment(missingItemId: number): Promise<GetCommentDto> {
        const url = `${this.urls.getComment}/${missingItemId}`;
        return await firstValueFrom(this.http.get<GetCommentDto>(url));
    }

    async resolveAsset(missingItemId: number, userName: string): Promise<ResolveAssetDto[]> {
        const url = this.urls.resolveAsset;
        const body = { missingItemId, userName };

        return await firstValueFrom(
            this.http.post<ResolveAssetDto[]>(url, body).pipe(
                catchError((error) => {
                    if (error.status === 400) {
                        const errorDto = error.error as ErrorDto;
                        throw {
                            ...errorDto,
                            status: error.status,
                        } as HttpError;
                    }
                    throw error;
                }),
            ),
        );
    }

    async getTicketDetail(missingItemId: number): Promise<TicketDto> {
        const url = `${this.urls.getTicketDetail}/${missingItemId}`;
        return await firstValueFrom(this.http.get<TicketDto>(url));
    }

    async getRetentionHistory(): Promise<RetentionHistoryCountsDto[]> {
        const url = this.urls.getRetentionHistory;
        return await firstValueFrom(this.http.get<RetentionHistoryCountsDto[]>(url));
    }

    async getJunkTicketNote(assetCountId: string): Promise<JunkTicketNoteDto> {
        const url = `${this.urls.getJunkTicketNote}/${assetCountId}`;
        return await firstValueFrom(this.http.get<JunkTicketNoteDto>(url));
    }

    async postJunkTicket(
        note: string,
        missingItemIds: number[],
    ): Promise<AddJunkTicketResponseDto> {
        const user = this.stateService.get('user') as User;
        const body = {
            userName: user.username,
            note,
            missingItemIds,
        };
        const url = this.urls.postJunkTicket;
        return await firstValueFrom(this.http.post<AddJunkTicketResponseDto>(url, body));
    }

    async uploadMissingItemImage(dto: MissingItemImageUploadDto): Promise<MissingItemImage> {
        const url = this.urls.addImageToMissingItem;
        const formData = new FormData();
        formData.append('missingItemId', dto.missingItemId.toString());
        formData.append('userName', dto.userName);
        formData.append('image', dto.image);

        try {
            return await firstValueFrom(this.http.post<MissingItemImage>(url, formData));
        } catch (error) {
            if (error instanceof HttpErrorResponse) {
                if (error.error?.errors) {
                    throw new Error(error.error.errors.join(', '));
                }
            }
            throw new Error('Upload failed. Please try again.');
        }
    }

    async deleteMissingItemImage(imageId: number): Promise<any> {
        const user = this.stateService.get('user') as User;
        const url = this.urls.deleteImageFromMissingItem;
        const body = { imageId, userName: user.username };
        return await firstValueFrom(this.http.put<any>(url, body));
    }

    async getCountRetentionHistory(assetCountId: string): Promise<CountRetentionHistoryDto> {
        const url = `${this.urls.getCountRetentionHistory}/${assetCountId}`;
        return await firstValueFrom(this.http.get<CountRetentionHistoryDto>(url));
    }
}
