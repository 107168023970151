import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'cleanText',
    standalone: true,
})
export class CleanTextPipe implements PipeTransform {
    transform(value: string | null): string {
        return value?.replace(/[\r\n]+/g, ' ').trim() ?? '';
    }
}
