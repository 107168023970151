import { Component } from '@angular/core';

@Component({
    selector: 'app-home',
    standalone: true,
    imports: [],
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss', '../../main-shared.scss'],
})
export class HomeComponent {}
