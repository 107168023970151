<div class="main-header">
    <!--Title-->
    <div class="title">Asset Count Monitor</div>

    <!--Options-->
    <div class="options"></div>
</div>

<div
    class="asset-count-live"
    *ngIf="activeCounts.length"
>
    <!--Active Count Tabs-->
    <app-tabs
        *ngIf="isLoaded"
        [tabs]="appTabItems"
        [selectedTab]="selectedAppTabItem"
        (tabSelected)="setSelectedTab($event)"
    />

    <div
        class="session"
        *ngIf="session"
    >
        <div class="session-header">
            <!--Date Start-->
            <div class="date-start">
                <span class="label">Start</span>
                <span class="value">{{ dateStart }}</span>
            </div>

            <!--Host User-->
            <div class="host">
                <span class="label">Host</span>
                <span class="value">{{ hostName }}</span>
                <span
                    class="value"
                    [innerHTML]="hostEmail"
                ></span>
            </div>

            <!--Reports-->
            <div class="reports">
                <app-button
                    primary
                    medium
                    icon="assignment"
                    text="Reports"
                    (click)="displayReportsModal()"
                />
            </div>
        </div>

        <!--Counts, Locations, Users, & Reports-->
        <div class="counts-locations-users-reports">
            <!--Counts-->
            <session-section title="Counts">
                <session-counts
                    *ngIf="session"
                    [session]="session"
                />
            </session-section>

            <!--Locations-->
            <session-section
                [title]="'Locations (' + session.locations.length + ')'"
                *ngIf="session"
            >
                <ul
                    #locationsDiv
                    class="locations"
                >
                    <li *ngFor="let location of orderBy(session.locations)">
                        {{ location }}
                    </li>
                </ul>
            </session-section>

            <!--Users-->
            <session-section
                [title]="'Users (' + session.users.length + ')'"
                *ngIf="session"
            >
                <ul
                    #usersDiv
                    class="users"
                    style="padding-right: 15px"
                >
                    <li *ngFor="let user of orderBy(this.session?.users, ['firstName'], ['asc'])">
                        {{ user.firstName }} {{ user.lastName }}
                    </li>
                </ul>
            </session-section>
        </div>

        <!--Assets-->
        <div
            class="assets"
            *ngIf="this.session?.assets"
        >
            <div class="header">
                <!--Title & Assets In View Count-->
                <div class="title">
                    Assets
                    <span *ngIf="filteredAssets.length">
                        ({{ filteredAssets.length | number }})
                    </span>
                </div>

                <!--Filter Toggle-->
                <div class="filter-button">
                    <app-button
                        small
                        icon="filter_alt"
                        title="Toggle filter mode"
                        (click)="handleFilterToggle()"
                    />
                </div>

                <!--Status & Issue Type Filters-->
                <div
                    class="status-filter"
                    *ngIf="shouldShowStatusFilters"
                >
                    <app-select
                        small
                        [options]="statusFilterOptions"
                        [(selectedValue)]="selectedStatusFilter"
                        (selectedValueChange)="handleStatusFilterChange($event)"
                    />

                    <div
                        class="issues-filters"
                        *ngIf="
                            shouldShowStatusFilters &&
                            selectedStatusFilter === AssetStatus.HasIssue.toString()
                        "
                    >
                        <app-select
                            small
                            [options]="issueTypesFilterOptions"
                            [(selectedValue)]="selectedIssueTypeFilterOption"
                            (selectedValueChange)="handleIssueTypeFilterChange($event)"
                        />
                        &nbsp;&nbsp;
                        <app-select
                            small
                            [options]="resolvedFilterOptions"
                            [(selectedValue)]="selectedResolvedFilterOption"
                            (selectedValueChange)="handleResolvedFilterChange($event)"
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <app-button
                            small
                            icon="replay"
                            title="Refresh issues filters"
                            (click)="handleIssuesFiltersReset()"
                        />
                    </div>
                </div>

                <!--Text Filter-->
                <div
                    class="text-filter"
                    *ngIf="!shouldShowStatusFilters"
                >
                    <div class="icon">
                        <span class="material-icons-sharp icon">search</span>
                    </div>
                    <div class="inputs">
                        <input
                            #textFilter
                            type="text"
                            class="text-search"
                            placeholder="Filter..."
                            spellcheck="false"
                            maxlength="50"
                            [(ngModel)]="selectedTextFilter"
                            (ngModelChange)="handleTextChange()"
                        />

                        <app-multi-select
                            #statusesMultiSelect
                            small
                            class="asset-status-select"
                            itemName="Statuses"
                            [options]="statusFilterOptions"
                            [(selectedValues)]="selectedFilterStatusOptions"
                            (selectedValuesChange)="handleTextFilterStatusChanges($event)"
                        />

                        <app-button
                            small
                            class="reset-filters-button"
                            icon="replay"
                            title="Reset filters"
                            (click)="handleClearTextFilters()"
                        />

                        <app-button
                            primary
                            small
                            class="generate-report-button"
                            text="Generate"
                            [isDisabled]="shouldDisableFilterTextReportButton"
                            (click)="handleFilterTextGenerateReportClick()"
                        />
                    </div>
                </div>
            </div>
            <div
                #assetDiv
                class="list"
            >
                <div
                    class="asset"
                    *ngFor="let asset of filteredAssets"
                >
                    <div class="asset-number-and-ticket">
                        <div class="asset-number">
                            {{ asset.assetNumber }}
                        </div>
                        <div
                            class="ticket"
                            *ngIf="asset.ticket"
                        >
                            {{ asset.ticket }}
                        </div>
                    </div>
                    <div class="description">
                        {{ asset.description }}
                    </div>
                    <!--Last Event-->
                    <div
                        class="special"
                        *ngIf="asset.shouldShowLastEvent"
                    >
                        <div class="icon">
                            <span class="material-icons-sharp icon">person</span>
                        </div>
                        <div class="text">{{ asset.lastEventDetails }}</div>
                    </div>
                    <!--Issue Type-->
                    <div
                        class="special"
                        *ngIf="asset.shouldShowIssueType"
                    >
                        <div class="icon">
                            <span class="material-icons-sharp icon">emergency</span>
                        </div>
                        <div class="text">{{ asset.issueTypeDetails }}</div>
                    </div>
                    <!--Notes-->
                    <div
                        class="special"
                        *ngIf="asset.shouldShowNotes"
                    >
                        <div class="icon">
                            <span class="material-icons-sharp icon">article</span>
                        </div>
                        <div class="text">{{ asset.notes }}</div>
                    </div>
                    <!--Resolved-->
                    <div
                        class="special resolved"
                        *ngIf="asset.shouldShowIsResolved"
                    >
                        <div class="icon">
                            <span class="material-icons-sharp icon">check_circle</span>
                        </div>
                        <div class="text">Resolved</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--Loading-->
<div
    class="asset-count-live message"
    *ngIf="!activeCounts.length && !hasNoCounts"
>
    Loading...
</div>
<!--No Active Counts-->
<div
    class="asset-count-live message"
    *ngIf="hasNoCounts"
>
    Currently, there are no active asset counts.
</div>
