import {
    Component,
    Input,
    ContentChildren,
    QueryList,
    AfterContentInit,
    ChangeDetectorRef,
    ElementRef,
    Renderer2,
    AfterViewInit,
} from '@angular/core';
import { DecimalPipe, NgIf } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppTableSortableHeaderDirective, SortEvent } from './app-table-sortable-header.directive';
import { SortDirection, AppTableHeader } from './models/interfaces/AppTableHeader';
import { AppTableRow, AppTableCell } from './models/interfaces/AppTableRow';
import { ICON_ASC, ICON_DESC } from './assets/sort-icons';
import { AppRowComponent } from './components/app-row/app-row.component';

export interface DefaultSort {
    column: string;
    direction: 'asc' | 'desc';
}

@Component({
    selector: 'app-table',
    standalone: true,
    imports: [AppTableSortableHeaderDirective, NgIf, DecimalPipe],
    providers: [DecimalPipe],
    templateUrl: './app-table.component.html',
    styleUrls: ['./app-table.component.scss'],
})
export class AppTableComponent implements AfterContentInit, AfterViewInit {
    @Input() headers: AppTableHeader[] = [];
    @Input() rows: AppTableRow[] = [];
    @Input() defaultSort?: DefaultSort;
    @Input() rowVerticalAlign: 'top' | 'center' = 'center';

    @ContentChildren(AppRowComponent, { read: ElementRef })
    projectedRows!: QueryList<ElementRef>;

    hasProjectedContent = false;

    constructor(
        private sanitizer: DomSanitizer,
        private decimalPipe: DecimalPipe,
        private cdr: ChangeDetectorRef,
        private renderer: Renderer2,
    ) {}

    ngAfterViewInit() {
        if (this.hasProjectedContent && this.defaultSort) {
            // Use setTimeout to push this task to the next event cycle
            setTimeout(() => {
                this.applyDefaultSort();
            });
        }
    }

    ngAfterContentInit() {
        this.hasProjectedContent = this.projectedRows && this.projectedRows.length > 0;
        if (this.hasProjectedContent) {
            this.applyColumnStyles();
            this.projectedRows.changes.subscribe(() => {
                this.applyColumnStyles();
                this.updateProjectedRows();
            });
        }

        if (this.defaultSort) {
            this.applyDefaultSort();
        }

        // Trigger change detection to ensure the view is updated
        this.cdr.detectChanges();
    }

    updateProjectedRows() {
        if (this.hasProjectedContent && this.projectedRows) {
            this.rows = this.projectedRows.map((rowRef: ElementRef) => {
                const rowElement = rowRef.nativeElement;
                const cellElements = rowElement.querySelectorAll('[app-cell]');
                return Array.from(cellElements).map((cellElement) => ({
                    value: (cellElement as HTMLElement).textContent?.trim() ?? '',
                    html: (cellElement as HTMLElement).innerHTML,
                }));
            });
            this.cdr.detectChanges();
        }
    }

    applyColumnStyles() {
        this.projectedRows.forEach((rowRef: ElementRef) => {
            const cells = rowRef.nativeElement.querySelectorAll('[app-cell]');
            cells.forEach((cell: HTMLElement, index: number) => {
                if (this.headers[index]) {
                    const style = this.getColumnStyle(this.headers[index]);
                    Object.keys(style).forEach((key) => {
                        this.renderer.setStyle(cell, key, style[key]);
                    });
                }
            });
        });
    }

    onSort({ column, direction }: SortEvent) {
        this.headers = this.headers.map((header) =>
            header.name === column ? { ...header, direction } : { ...header, direction: '' },
        );

        if (this.hasProjectedContent) {
            this.sortProjectedRows(column, direction);
        } else {
            this.sortRows(column, direction);
        }

        this.cdr.detectChanges();
    }

    getColumnStyle(header: AppTableHeader): { [klass: string]: any } {
        return {
            width: header.width || 'auto',
            'text-align': header.align || 'left',
        };
    }

    getSafeHtml(html: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    getSortIcon(direction: SortDirection): SafeHtml {
        if (direction === 'asc') {
            return this.sanitizer.bypassSecurityTrustHtml(ICON_ASC);
        } else if (direction === 'desc') {
            return this.sanitizer.bypassSecurityTrustHtml(ICON_DESC);
        }
        return '';
    }

    formatCellContent(cell: AppTableCell): string | number | SafeHtml {
        if (cell.html) {
            return this.getSafeHtml(cell.html);
        }

        if (
            typeof cell.value === 'number' ||
            (typeof cell.value === 'string' && !isNaN(Number(cell.value)))
        ) {
            return this.decimalPipe.transform(cell.value, '1.0-0') || cell.value;
        }

        return cell.value;
    }

    private sortRows(column: string, direction: SortDirection) {
        if (direction === '') {
            this.rows = [...this.rows]; // Reset to original order
        } else {
            const columnIndex = this.headers.findIndex((header) => header.name === column);
            if (columnIndex !== -1) {
                this.rows = [...this.rows].sort((a, b) => {
                    const valueA = a[columnIndex]?.value;
                    const valueB = b[columnIndex]?.value;
                    const comparison = this.compare(valueA, valueB);
                    return direction === 'asc' ? comparison : -comparison;
                });
            }
        }
    }

    private applyDefaultSort() {
        if (this.defaultSort) {
            this.onSort({
                column: this.defaultSort.column,
                direction: this.defaultSort.direction,
            });
            this.cdr.detectChanges();
        }
    }

    private compare(value1: any, value2: any): number {
        // First, check if both values are numeric.
        const num1 = Number(value1);
        const num2 = Number(value2);

        if (!isNaN(num1) && !isNaN(num2)) {
            return num1 - num2;
        }

        // If not both numeric, treat as strings.
        const str1 = String(value1).toLowerCase();
        const str2 = String(value2).toLowerCase();

        return str1.localeCompare(str2);
    }

    private sortProjectedRows(column: string, direction: SortDirection) {
        const columnIndex = this.headers.findIndex((header) => header.name === column);
        if (columnIndex === -1) return;

        if (!this.projectedRows || !this.projectedRows.first) {
            // console.log(' ### Projected rows not yet available.');
            return;
        }

        const tbody = this.projectedRows.first.nativeElement.parentElement;
        if (!tbody) {
            // console.log(' ### Parent element not found.');
            return;
        }

        const rows = Array.from(tbody.children) as HTMLElement[];

        rows.sort((a: HTMLElement, b: HTMLElement) => {
            const aValue = this.extractCellValue(a.children[columnIndex] as HTMLElement);
            const bValue = this.extractCellValue(b.children[columnIndex] as HTMLElement);
            const comparisonResult = this.compare(aValue, bValue);
            return direction === 'asc' ? comparisonResult : -comparisonResult;
        });

        // Clear and re-append sorted rows
        tbody.innerHTML = '';
        rows.forEach((row) => tbody.appendChild(row));
    }

    private extractCellValue(cell: Element): string | number {
        // Remove any commas from the content.
        const rawValue = cell.textContent?.trim().replace(/,/g, '') || '';

        // Try to convert to a number.
        const numValue = parseFloat(rawValue);

        // Return number if it's valid, otherwise return the original string.
        return isNaN(numValue) ? rawValue : numValue;
    }
}
