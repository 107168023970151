import {
    Directive,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
} from '@angular/core';
import { SortDirection } from './models/interfaces/AppTableHeader';

export type SortColumn = string;

export interface SortEvent {
    column: SortColumn;
    direction: SortDirection;
}

const rotate: { [key: string]: SortDirection } = {
    asc: 'desc',
    desc: '',
    '': 'asc',
};

@Directive({
    selector: '[sortable]',
    standalone: true,
})
export class AppTableSortableHeaderDirective {
    @Input() sortable: SortColumn = '';
    @Input() direction: SortDirection = '';
    @Output() sort = new EventEmitter<SortEvent>();

    @HostBinding('class.asc') get isAscending() {
        return this.direction === 'asc';
    }
    @HostBinding('class.desc') get isDescending() {
        return this.direction === 'desc';
    }

    @HostListener('click')
    rotate() {
        this.direction = rotate[this.direction];
        this.sort.emit({ column: this.sortable, direction: this.direction });
    }
}
