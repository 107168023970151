import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { StateService } from '../services/StateService';
import { User } from '../models/classes/User';
import { AppFullScreenLoadingIndicator } from '../components/app-full-screen-loading-indicator/app-full-screen-loading-indicator';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { AuthService } from '../services/AuthService';
import { BudsAuthDto } from '../models/dtos/BudsAuthDto';
import { AcknowledgementModalService } from '../services/AcknowledgementModalService';
import { LoadingIndicatorService } from '../services/LoadingIndicatorService';
import { UtilityService } from '../services/UtilityService';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, AppFullScreenLoadingIndicator],
    template: `
        <router-outlet></router-outlet>
        <app-full-screen-loading-indicator />
    `,
})
export class AppComponent implements OnInit {
    private readonly MESSAGES = {
        authTitle: 'TTS Web Auth Failed',
        authPrefix: 'The provided account is not authorized to access TTS Web.',
        authSuffix: 'Please contact the TTS Web dev team for assistance.',
    };

    constructor(
        private msalService: MsalService,
        public stateService: StateService,
        public authService: AuthService,
        public acknowledgementModalService: AcknowledgementModalService,
        public loadingIndicatorService: LoadingIndicatorService,
        public utilityService: UtilityService,
        private router: Router,
    ) {}

    ngOnInit() {
        // Ensure MSAL is initialized before proceeding.
        this.msalService.instance
            .initialize()
            .then(async () => {
                // Handle redirect observable (should only happen once).
                this.msalService.handleRedirectObservable().subscribe({
                    next: async (result) => {
                        if (result) {
                            const user: User = this.initializeUser(result.account);
                            this.stateService.set('user', user);

                            // Perform secondary authentication if needed.
                            const { username } = user;
                            const dto: BudsAuthDto =
                                await this.authService.isUserAuthorized(username);

                            if (dto.isAuthorized) {
                                // Navigate to /main if secondary auth succeeds.
                                await this.router.navigate(['/main']);
                            } else {
                                // Handle failed secondary authentication.
                                await this.handleFailedSecondaryAuth();
                            }
                        }
                    },
                    error: (err) => {
                        console.error('MSAL Redirect Error: ', err);
                        this.acknowledgementModalService.open('Auth Error (MSAL)', err);
                    },
                });

                // Check if user is already logged in
                const accounts = this.msalService.instance.getAllAccounts();

                if (accounts.length === 0) {
                    // If no accounts, redirect to login.
                    this.router.navigate(['/login']);
                } else {
                    // If accounts exist, ensure the user is initialized.
                    const user: User = this.initializeUser(accounts[0]);
                    this.stateService.set('user', user);
                    this.router.navigate(['/main']);
                }
            })
            .catch((error) => {
                console.error('MSAL Initialization Error: ', error);
            });
    }

    private async handleFailedSecondaryAuth() {
        // Clear any lingering user in state.
        this.stateService.clear('user');

        // Clear MSAL active account and cache; clear tokens and storage.
        this.msalService.instance.setActiveAccount(null);
        await this.msalService.instance.clearCache();

        // Clear session storage; this will remove all MSAL session data.
        sessionStorage.clear();

        // Log the event w/ a warning.
        console.warn('Secondary authentication failed. User has been logged out.');

        // Logout MSAL session.
        this.msalService.logoutRedirect({
            postLogoutRedirectUri: window.location.origin + '/login',
            onRedirectNavigate: () => false,
        });

        // Delay modal display slightly to allow redirect to complete.
        await this.utilityService.sleep(100);
        await this.displayAuthFailedModal();
    }

    private async displayAuthFailedModal() {
        await this.acknowledgementModalService.open(
            this.MESSAGES.authTitle,
            `${this.MESSAGES.authPrefix}<br /><br />${this.MESSAGES.authSuffix}`,
        );
    }

    private initializeUser(accountInfo: AccountInfo): User {
        const nameArray: string[] = (accountInfo.name as string).split(' ');
        const firstName: string = nameArray[0];
        const lastName: string = nameArray[1];
        const email: string = accountInfo.username;
        const username: string = email.split('@')[0];

        return {
            firstName,
            lastName,
            email,
            username,
        };
    }
}
