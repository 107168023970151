import { Environment } from '../models/classes/Environment';

const baseUrl = 'https://ttswebtest.azurewebsites.net/api';
const assetCountBaseUrl = 'https://ttsassetcounttest.azurewebsites.net/api';
const redirectUri = 'https://gray-stone-0666a3210.5.azurestaticapps.net';

export const environment: Environment = {
    isProduction: false,
    name: 'test',
    endpoints: {
        // Asset Count API
        getCompletedCounts: `${assetCountBaseUrl}/GetCompletedCounts?code=n-0prIc42AqTPgR7qc8zjpEp-iB-9s6tawsDdxcXA2ujAzFu_QDFAQ==`,
        downloadSummaryReport: `${assetCountBaseUrl}/DownloadSummaryReport?code=LMQoV82Q58ZZBOWhFJAy5wHToXiekbPtLvs2DmAOZdjNAzFuPA22Sw==`,
        downloadFinalStateReport: `${assetCountBaseUrl}/DownloadFinalStateReport?code=X8giWJqFmA-MGrvEAMuXuszese3B8zU5g_8J4Z0BXYRbAzFuQzkOvA==`,
        getActiveCount: `${assetCountBaseUrl}/ActiveCount/{0}/{1}?code=U04inTfgNQpPma-PtAddHFUsRO-d2OfMIv6I5pTkI61OAzFuLjqFZw==`,
        getActiveCounts: `${assetCountBaseUrl}/ActiveCounts?code=Or-uoeouGKtV1LaLla1enpGBOxYjN1_UFtCjhr-HrIsWAzFumBAGBg==`,

        // TTS Web API
        addAsset: `${baseUrl}/AssetRetention/Asset`,
        editComment: `${baseUrl}/AssetRetention/Asset/Comment`,
        getMissingItems: `${baseUrl}/AssetRetention/Assets`,
        getComment: `${baseUrl}/AssetRetention/Asset/Comment`,
        resolveAsset: `${baseUrl}/AssetRetention/Asset/Resolve`,
        getTicketDetail: `${baseUrl}/AssetRetention/Asset/OnTicketDetail`,
        getAppData: `${baseUrl}/AppData`,
        getRetentionHistory: `${baseUrl}/AssetRetention/History`,
        getJunkTicketNote: `${baseUrl}/JunkTicket/Note`,
        postJunkTicket: `${baseUrl}/AssetRetention/JunkTicket`,
        addImageToMissingItem: `${baseUrl}/AssetRetention/Asset/Image`,
        deleteImageFromMissingItem: `${baseUrl}/AssetRetention/Asset/Image`,
        getCountRetentionHistory: `${baseUrl}/AssetRetention/CountHistory`,
        isUserAuthorized: `${baseUrl}/Auth`,
    },
    msal: {
        redirectUri: redirectUri,
        postLogoutRedirectUri: redirectUri,
    },
};
