/* src/styles/_tabs.scss */
.flex-row,
.app-tabs > .tab,
.app-tabs {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
.app-tabs > .tab {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.app-tabs > .tab {
  width: auto;
  height: 30px;
  line-height: 30px;
  padding: 2px 20px 0 20px;
  margin: 0 5px 0 0;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  color: #505050;
  border-bottom: 2px solid #bbb;
  cursor: pointer;
}
.app-tabs > .tab.selected {
  border-bottom: 2px solid #0a51a3;
  background-color: rgba(10, 81, 163, 0.065);
}
.app-tabs > .tab:not(.selected):hover {
  background-color: rgba(187, 187, 187, 0.1);
  border-bottom: 2px solid #757575;
}
/*# sourceMappingURL=_tabs.css.map */
