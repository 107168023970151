<div class="main-header">
    <!--Back To Asset Retention Button-->
    <app-button
        small
        class="back-button"
        icon="arrow_back"
        title="Back to Asset Retention"
        (click)="navigateToAssetRetention()"
    />

    <!--Title-->
    <div class="title"> Asset Retention History </div>
</div>

<div
    class="asset-retention-history"
    *ngIf="count"
>
    <!--District Code - Name-->
    <div class="count-title">
        <span class="district-code">
            {{ count.districtCode }}
        </span>
        <span class="district-name">
            {{ count.districtName }}
        </span>
    </div>

    <!--Start Date-->
    <div class="count-info">
        <span class="label"> Start </span>
        <div class="value">
            {{ dateStart }} <span class="user">({{ userStart }})</span>
        </div>
    </div>

    <!--End Date-->
    <div class="count-info">
        <span class="label"> End </span>
        <div class="value">
            {{ dateEnd }} <span class="user">({{ userEnd }})</span>
        </div>
    </div>

    <!--Asset Number Lists-->
    <div
        class="asset-numbers"
        *ngIf="history"
    >
        <!--Invalid Assets-->
        <div class="assets invalid">
            <!--Header-->
            <div class="header">
                <div class="left">
                    <div class="icon-and-title">
                        <div class="icon">
                            <span class="material-icons icon invalid">close</span>
                        </div>
                        <div class="title"> Invalid Assets </div>
                    </div>
                    <div class="details">
                        {{ invalidAssetsCount | number }} assets failed to be added to retention.
                    </div>
                </div>

                <div class="right">
                    <app-button
                        small
                        icon="content_copy"
                        class="copy-button"
                        title="Copy to clipboard"
                        *ngIf="history.invalidAssets?.length"
                        (click)="copyToClipboard('invalid')"
                    />
                </div>
            </div>

            <!--List-->
            <div
                class="list"
                *ngIf="history.invalidAssets?.length"
            >
                <div
                    class="asset invalid"
                    *ngFor="let asset of history.invalidAssets"
                >
                    <div
                        class="asset-number"
                        [title]="asset.assetNumber"
                    >
                        {{ asset.assetNumber }}
                    </div>
                    <div class="errors">
                        <ng-container *ngIf="asset.errorMessages.length === 1; else multipleErrors">
                            {{ asset.errorMessages[0] | cleanText }}
                        </ng-container>
                        <ng-template #multipleErrors>
                            <ul>
                                <li *ngFor="let error of asset.errorMessages">
                                    {{ error | cleanText }}
                                </li>
                            </ul>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

        <!--Valid Assets-->
        <div class="assets valid">
            <!--Header-->
            <div class="header">
                <div class="left">
                    <div class="icon-and-title">
                        <div class="icon">
                            <span class="material-icons icon valid">check</span>
                        </div>
                        <div class="title"> Valid Assets </div>
                    </div>
                    <div class="details">
                        {{ validAssetsCount | number }} assets added to retention.
                    </div>
                </div>
            </div>

            <!--List-->
            <div
                class="list"
                *ngIf="history.validAssets?.length"
            >
                <div
                    class="asset valid"
                    *ngFor="let assetNumber of history.validAssets"
                >
                    <div class="asset-number">
                        {{ assetNumber }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
