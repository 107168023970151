<div class="add-to-junk-ticket-modal">
    <div class="modal-container">
        <div class="modal-header">
            <div class="title">
                Post To Junk Ticket
                <span *ngIf="isInitialized && this.results !== null"> - Results</span>
            </div>
            <div
                class="close"
                aria-label="Close"
                (click)="closeModal(false)"
            >
                <span class="material-icons icon">close</span>
            </div>
        </div>

        <div
            class="modal-body"
            [ngClass]="{ results: view === 'results' }"
        >
            <!--Intro-->
            <div
                class="view intro"
                *ngIf="view === 'intro'"
            >
                <p>
                    Within this modal, you can post the selected assets to junk tickets associated
                    with the selected count.
                </p>
                <p
                    class="assets-quantity-note"
                    *ngIf="isInitialized && missingItemIds.length"
                >
                    Ready to post {{ missingItemIds.length }} asset(s).
                </p>

                <div class="list-and-notes">
                    <div class="list">
                        <div
                            class="asset-number"
                            *ngFor="let assetNumber of assetNumbers"
                        >
                            {{ assetNumber }}
                        </div>
                    </div>

                    <div class="notes">
                        <div class="label">
                            Note
                            <span
                                *ngIf="isInitialized && hasNote"
                                class="pre-existing"
                            >
                                From pre-existing ticket(s)
                            </span>
                        </div>

                        <app-textarea
                            *ngIf="isInitialized && !hasNote"
                            [(value)]="notes"
                            [min]="minNoteLength"
                            [max]="maxNoteLength"
                            width="100%"
                            height="110px"
                            placeholder="Specify ticket note..."
                        />

                        <div
                            class="notes-footer"
                            *ngIf="isInitialized && !hasNote"
                        >
                            <div class="min-max">
                                Required: {{ minNoteLength }} &nbsp; &nbsp; Max: {{ maxNoteLength }}
                            </div>

                            <div class="character-count">
                                {{ notesLength }} characters
                                <span
                                    class="material-icons-sharp icon valid"
                                    *ngIf="isLengthValid"
                                >
                                    check
                                </span>
                                <span
                                    class="material-icons-sharp icon invalid"
                                    *ngIf="!isLengthValid"
                                >
                                    close
                                </span>
                            </div>
                        </div>
                        <div
                            class="text"
                            *ngIf="isInitialized && hasNote"
                        >
                            {{ notes }}
                        </div>
                    </div>
                </div>
            </div>

            <!--Results-->
            <div
                class="view results"
                *ngIf="view === 'results'"
            >
                <!--Tabs-->
                <app-tabs
                    *ngIf="results"
                    [tabs]="appTabItems"
                    [selectedTab]="selectedAppTabItem"
                    (tabSelected)="setSelectedTab($event)"
                />

                <!--Selected Tab-->
                <div class="selected-tab">
                    <!--Invalid-->
                    <div class="results invalid">
                        <div class="title">
                            <span class="material-icons-sharp icon">close</span>
                            Invalid Assets

                            <app-button
                                small
                                icon="content_copy"
                                class="copy-button"
                                title="Copy to clipboard"
                                *ngIf="!isInvalidCopied && selectedResults?.failedAssets?.length"
                                (click)="copyToClipboard('invalid')"
                            />
                            <div
                                class="copied-notification"
                                *ngIf="isInvalidCopied"
                            >
                                Copied!
                            </div>
                        </div>
                        <div class="summary"> {{ failedAssetsLength }} assets failed to post. </div>
                        <div class="assets-list">
                            <div
                                class="list-asset"
                                *ngFor="let failedAsset of selectedResults?.failedAssets"
                            >
                                <div class="list-asset-number">
                                    {{ failedAsset.assetNumber }}
                                </div>
                                <div class="list-asset-reason">
                                    {{ failedAsset.errorMessage }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--Valid-->
                    <div class="results valid">
                        <div class="title">
                            <span class="material-icons-sharp icon">check</span>
                            Valid Assets

                            <app-button
                                small
                                icon="content_copy"
                                class="copy-button"
                                title="Copy to clipboard"
                                *ngIf="!isValidCopied && selectedResults?.postedAssets?.length"
                                (click)="copyToClipboard('valid')"
                            />
                            <div
                                class="copied-notification"
                                *ngIf="isValidCopied"
                            >
                                Copied!
                            </div>
                        </div>
                        <div class="summary"> {{ postedAssetsLength }} assets posted. </div>
                        <div class="assets-list">
                            <div
                                class="list-asset"
                                *ngFor="let postedAsset of selectedResults?.postedAssets"
                            >
                                <div
                                    class="list-asset-number"
                                    style="width: 100%"
                                >
                                    {{ postedAsset }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="modal-footer"
            *ngIf="this.view === 'intro'"
        >
            <app-button
                primary
                icon="add"
                text="Post Assets To Junk Ticket"
                (click)="postToTicket()"
                [isDisabled]="!isPostButtonEnabled"
            />
        </div>
        <app-modal-loading-indicator />
    </div>
</div>
