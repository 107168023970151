import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-input-text',
    standalone: true,
    imports: [FormsModule, CommonModule],
    template: `
        <div
            class="input-wrapper"
            [class]="class"
        >
            <input
                [id]="id"
                type="text"
                spellcheck="false"
                [(ngModel)]="value"
                [placeholder]="placeholder"
                [class.has-clear-button]="showClear"
                [style.textTransform]="showAllCaps ? 'uppercase' : 'none'"
                autocomplete="off"
                (ngModelChange)="onValueChange($event)"
            />
            <div
                *ngIf="showClear"
                class="clear"
                (click)="clear()"
            >
                <span class="material-icons icon">close</span>
            </div>
        </div>
    `,
    styleUrls: ['app-input-text.component.scss'],
    host: {
        '[style.width]': 'width',
    },
})
export class AppInputTextComponent {
    @Input() value = '';
    @Input() class = '';
    @Input() placeholder = '';
    @Input() id = '';
    @Input() showClear = false;
    @Input() width = '100%';
    @Input() showAllCaps = false;

    @Output() valueChange = new EventEmitter<string>();

    onValueChange(value: string) {
        this.valueChange.emit(value);
    }

    clear() {
        this.value = '';
        this.valueChange.emit(this.value);
    }
}
