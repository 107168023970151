import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppTabItem } from '../../models/interfaces/AppTabItem';

@Component({
    selector: 'app-tabs',
    standalone: true,
    imports: [CommonModule],
    template: `
        <div
            [class]="containerClass"
            *ngIf="tabs"
        >
            <div
                class="tab"
                *ngFor="let tab of tabs"
                [ngClass]="{ selected: selectedTab === tab.name }"
                (click)="onTabSelect(tab.name)"
                [innerHTML]="tab.text"
            >
            </div>
        </div>
    `,
    styleUrls: [`../../styles/_tabs.scss`],
})
export class AppTabsComponent {
    @Input() tabs: AppTabItem[] = [];
    @Input() selectedTab: string = '';
    @Input() containerClass: string = 'app-tabs';

    @Output() tabSelected = new EventEmitter<string>();

    onTabSelect(name: string): void {
        this.tabSelected.emit(name);
    }
}
