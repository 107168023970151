/* src/app/modules/main/components/asset-retention/components/ticket-details-modal/ticket-details-modal.component.scss */
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.flex-row,
.ticket-details-modal .modal-container > .modal-body .notes,
.ticket-details-modal .modal-container > .modal-body .columns,
.app-modal .modal-container > .modal-footer,
.ticket-details-modal .modal-container > .modal-footer,
.app-modal .modal-container > .modal-header > .close,
.ticket-details-modal .modal-container > .modal-header > .close,
.app-modal .modal-container > .modal-header,
.ticket-details-modal .modal-container > .modal-header,
.app-modal,
.ticket-details-modal,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column,
.ticket-details-modal .modal-container > .modal-body .columns .column,
.app-modal .modal-container > .modal-body,
.ticket-details-modal .modal-container > .modal-body,
.app-modal .modal-container,
.ticket-details-modal .modal-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select,
.app-modal .modal-container > .modal-header > .title,
.ticket-details-modal .modal-container > .modal-header > .title,
.app-modal .modal-container > .modal-header,
.ticket-details-modal .modal-container > .modal-header,
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow,
.app-modal .modal-container,
.ticket-details-modal .modal-container {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border,
.app-modal .modal-container,
.ticket-details-modal .modal-container {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-button,
.app-toggle-button-group button,
.app-multi-toggle-button-group button,
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to bottom,
      rgba(37, 37, 37, 0) 0%,
      rgba(37, 37, 37, 0.2) 100%);
  width: auto;
  justify-content: center;
  height: 33px;
  min-height: 33px;
  max-height: 33px;
  line-height: 33px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  padding: 0 22px;
  font-size: 12.5px;
  border-radius: 2px;
  white-space: nowrap;
  cursor: pointer;
  color: #505050;
  background-color: #efefef;
  border: 1px solid #aaa;
}
.app-button .icon,
.app-toggle-button-group button .icon,
.app-multi-toggle-button-group button .icon,
.app-button-primary .icon {
  width: auto;
  margin: 0 5px 0 0;
  font-size: 15px;
  padding: 0;
  font-weight: 500;
}
.app-button[disabled],
.app-toggle-button-group button[disabled],
.app-multi-toggle-button-group button[disabled],
[disabled].app-button-primary {
  opacity: 0.4;
  cursor: default;
}
.app-button:not([disabled]):hover,
.app-toggle-button-group button:not([disabled]):hover,
.app-multi-toggle-button-group button:not([disabled]):hover,
.app-button-primary:not([disabled]):hover {
  background: #efefef;
}
.app-button:not([disabled]):active,
.app-toggle-button-group button:not([disabled]):active,
.app-multi-toggle-button-group button:not([disabled]):active,
.app-button-primary:not([disabled]):active {
  background-color: #ccc;
}
.app-button.app-button-medium,
.app-toggle-button-group button.app-button-medium,
.app-multi-toggle-button-group button.app-button-medium,
.app-button-medium.app-button-primary {
  padding: 0 17px;
  font-size: 11px;
  height: 29px;
  min-height: 29px;
  max-height: 29px;
  line-height: 29px;
}
.app-button.app-button-medium .icon,
.app-toggle-button-group button.app-button-medium .icon,
.app-multi-toggle-button-group button.app-button-medium .icon,
.app-button-medium.app-button-primary .icon {
  margin: 0 5px 0 0;
  font-size: 15px;
}
.app-button.app-button-small,
.app-toggle-button-group button.app-button-small,
.app-multi-toggle-button-group button.app-button-small,
.app-button-small.app-button-primary {
  padding: 0 12px;
  font-size: 10px;
  height: 23px;
  min-height: 23px;
  max-height: 23px;
  line-height: 23px;
}
.app-button.app-button-small .icon,
.app-toggle-button-group button.app-button-small .icon,
.app-multi-toggle-button-group button.app-button-small .icon,
.app-button-small.app-button-primary .icon {
  margin: 0 4px 0 0;
  font-size: 13px;
}
.app-button.icon-only,
.app-toggle-button-group button.icon-only,
.app-multi-toggle-button-group button.icon-only,
.icon-only.app-button-primary {
  padding: 0 15px;
}
.app-button.icon-only .icon,
.app-toggle-button-group button.icon-only .icon,
.app-multi-toggle-button-group button.icon-only .icon,
.icon-only.app-button-primary .icon {
  margin: 0;
}
.app-button.icon-only.app-button-medium,
.app-toggle-button-group button.icon-only.app-button-medium,
.app-multi-toggle-button-group button.icon-only.app-button-medium,
.icon-only.app-button-medium.app-button-primary {
  padding: 0 12px;
}
.app-button.icon-only.app-button-small,
.app-toggle-button-group button.icon-only.app-button-small,
.app-multi-toggle-button-group button.icon-only.app-button-small,
.icon-only.app-button-small.app-button-primary {
  padding: 0 10px;
}
.app-button-primary,
.app-toggle-button-group button.selected,
.app-multi-toggle-button-group button.selected {
  background:
    linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 100%);
  color: #fff;
  background-color: #0a51a3;
  border: 1px solid #0a51a3;
}
.app-button-primary:not([disabled]):hover,
.app-toggle-button-group button.selected:not([disabled]):hover,
.app-multi-toggle-button-group button.selected:not([disabled]):hover {
  background: #0a51a3;
}
.app-button-primary:not([disabled]):active,
.app-toggle-button-group button.selected:not([disabled]):active,
.app-multi-toggle-button-group button.selected:not([disabled]):active {
  background-color: #093b75;
}
.app-toggle-button-group,
.app-multi-toggle-button-group {
  display: inline-flex;
  border: 1px solid #ccc;
  border-radius: 2px;
  overflow: hidden;
}
.app-toggle-button-group button,
.app-multi-toggle-button-group button {
  font-size: 12px;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 0 !important;
}
.app-toggle-button-group button:first-child,
.app-multi-toggle-button-group button:first-child {
  border-top-left-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
}
.app-toggle-button-group button:last-child,
.app-multi-toggle-button-group button:last-child {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.app-toggle-button-group button:not(:last-child),
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button:not(:last-child) {
  border-right: 1px solid #aaa;
}
.app-multi-toggle-button-group button .icon {
  font-size: 14px;
  color: #505050;
  margin: 0 4px 0 0;
}
.app-multi-toggle-button-group button.selected {
  border-right: 1px solid #0a51a3;
}
.app-multi-toggle-button-group button.selected .icon {
  color: #fff;
  opacity: 0.85;
}
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.modal-shadow,
.app-modal .modal-container,
.ticket-details-modal .modal-container {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border,
.app-modal .modal-container,
.ticket-details-modal .modal-container {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.modal-body-shared,
.ticket-details-modal .modal-container > .modal-body {
  justify-content: start !important;
  align-items: start !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 17px !important;
  padding-bottom: 20px !important;
}
.app-modal,
.ticket-details-modal {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 300;
}
.app-modal .modal-container,
.ticket-details-modal .modal-container {
  position: relative;
  width: 550px;
  height: 400px;
  background-color: #fff;
}
.app-modal .modal-container > .modal-header,
.ticket-details-modal .modal-container > .modal-header {
  height: 65px;
}
.app-modal .modal-container > .modal-header > .title,
.ticket-details-modal .modal-container > .modal-header > .title {
  width: calc(100% - 65px);
  text-indent: 25px;
  font-size: 19px;
  font-weight: 600;
}
.app-modal .modal-container > .modal-header > .close,
.ticket-details-modal .modal-container > .modal-header > .close {
  justify-content: center;
  width: 65px;
  height: 100%;
  cursor: pointer;
}
.app-modal .modal-container > .modal-header > .close .icon,
.ticket-details-modal .modal-container > .modal-header > .close .icon {
  font-size: 23px;
  font-weight: 300;
  opacity: 0.6;
}
.app-modal .modal-container > .modal-header > .close:hover,
.ticket-details-modal .modal-container > .modal-header > .close:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.app-modal .modal-container > .modal-header > .close:hover .icon,
.ticket-details-modal .modal-container > .modal-header > .close:hover .icon {
  font-weight: 500;
  opacity: 1;
}
.app-modal .modal-container > .modal-header > .close:active,
.ticket-details-modal .modal-container > .modal-header > .close:active {
  background-color: rgba(0, 0, 0, 0.1);
}
.app-modal .modal-container > .modal-body,
.ticket-details-modal .modal-container > .modal-body {
  justify-content: start;
  align-items: center;
  padding: 7px 27px 7px 27px;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 13px;
  line-height: 17px;
}
.app-modal .modal-container > .modal-footer,
.ticket-details-modal .modal-container > .modal-footer {
  justify-content: end;
  padding: 0 20px;
  height: 65px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  background:
    linear-gradient(
      to top,
      rgba(0, 0, 0, 0.125),
      transparent);
}
.ticket-details-modal .modal-container {
  width: 400px;
  height: auto;
}
.ticket-details-modal .modal-container > .modal-body {
  padding-bottom: 25px !important;
}
.ticket-details-modal .modal-container > .modal-body p {
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  margin: 0 0 20px 0;
}
.ticket-details-modal .modal-container > .modal-body .columns {
  align-items: flex-start;
}
.ticket-details-modal .modal-container > .modal-body .columns .column {
  width: 45%;
  margin: 0 2.5%;
}
.ticket-details-modal .modal-container > .modal-body .notes {
  align-items: flex-start;
  margin: 0 0 0 2.5%;
  width: 97.5%;
}
/*# sourceMappingURL=ticket-details-modal.component.css.map */
