import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { StateService } from '../services/StateService';
import { AccountInfo } from '@azure/msal-browser';
import { User } from '../models/classes/User';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private msalService: MsalService,
        private router: Router,
        private stateService: StateService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
        const accounts = this.msalService.instance.getAllAccounts();
        const user = this.stateService.get('user');
        const isHistoryRoute = route.routeConfig?.path?.includes('asset-retention-history');

        if (!accounts || accounts.length === 0) {
            this.router.navigate(['/login']);
            return false;
        }

        if (!user) {
            if (isHistoryRoute) {
                this.initializeUser(accounts[0]);
                return true;
            }
            this.router.navigate(['/login']);
            return false;
        }

        return true;
    }

    private initializeUser(accountInfo: AccountInfo): void {
        const nameArray: string[] = (accountInfo.name as string).split(' ');
        const user: User = {
            firstName: nameArray[0],
            lastName: nameArray[1],
            email: accountInfo.username,
            username: accountInfo.username.split('@')[0],
        };
        this.stateService.set('user', user);
    }
}
