<div class="app-table">
    <table class="table">
        <thead>
            <tr>
                @for (header of headers; track header.name) {
                    <th
                        [attr.scope]="'col'"
                        [style]="getColumnStyle(header)"
                        [class.sortable]="header.isSortable !== false"
                        [attr.aria-sort]="
                            header.direction
                                ? header.direction === 'asc'
                                    ? 'ascending'
                                    : 'descending'
                                : 'none'
                        "
                    >
                        <div
                            *ngIf="header.isSortable !== false"
                            [sortable]="header.name"
                            [direction]="header.direction || ''"
                            (sort)="onSort($event)"
                            style="cursor: pointer"
                        >
                            {{ header.text }}
                            <span
                                class="sort-icon"
                                [innerHTML]="getSortIcon(header.direction)"
                                *ngIf="header.direction"
                            ></span>
                        </div>
                        <div *ngIf="header.isSortable === false">
                            {{ header.text }}
                        </div>
                    </th>
                }
            </tr>
        </thead>
        <tbody [class.align-top]="rowVerticalAlign === 'top'">
            @if (hasProjectedContent) {
                <ng-content select="[app-row]"></ng-content>
            } @else if (rows && rows.length) {
                @for (row of rows; track $index) {
                    <tr>
                        @for (header of headers; track header.name) {
                            <td [style]="getColumnStyle(header)">
                                <ng-container
                                    *ngIf="row[headers.indexOf(header)].html; else defaultContent"
                                >
                                    <span
                                        [innerHTML]="
                                            getSafeHtml(row[headers.indexOf(header)].html ?? '')
                                        "
                                    ></span>
                                </ng-container>
                                <ng-template #defaultContent>
                                    {{ formatCellContent(row[headers.indexOf(header)]) }}
                                </ng-template>
                            </td>
                        }
                    </tr>
                }
            }
        </tbody>
    </table>
</div>
