import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-textarea',
    standalone: true,
    imports: [FormsModule, CommonModule],
    template: `
        <textarea
            [id]="id"
            [class]="'app-textarea ' + class"
            spellcheck="false"
            [(ngModel)]="value"
            [placeholder]="placeholder"
            [maxlength]="max"
            [minlength]="min"
            autocomplete="off"
            (ngModelChange)="onValueChange($event)"
            [style.resize]="'none'"
        ></textarea>
    `,
    styleUrls: ['../../styles/_controls.scss'],
    host: {
        '[style.width]': 'width',
        '[style.height]': 'height',
    },
})
export class AppTextareaComponent {
    @Input() value = '';
    @Input() class = '';
    @Input() placeholder = '';
    @Input() id = '';
    @Input() width = '100%';
    @Input() height = '100px';
    @Input() max: number = 255;
    @Input() min: number = 0;

    @Output() valueChange = new EventEmitter<string>();

    onValueChange(value: string) {
        this.valueChange.emit(value);
    }
}
