import {
    Component,
    Input,
    Output,
    EventEmitter,
    HostBinding,
    HostListener,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SelectOption } from '../../models/classes/SelectOption';
import { UtilityService } from '../../services/UtilityService';

@Component({
    selector: 'app-multi-select',
    standalone: true,
    imports: [CommonModule, FormsModule],
    template: `
        <div
            class="app-multi-select"
            [id]="id"
            [ngClass]="getClasses()"
            [style.padding]="narrow !== null ? '0 10px' : null"
            [title]="tooltip"
            (click)="toggleDropdown()"
        >
            <div class="display">
                {{ getDisplayText() }}
                <span class="dropdown-arrow">&#x2335;</span>
            </div>

            <div
                class="dropdown-panel"
                *ngIf="isOpen"
            >
                <div
                    class="option"
                    *ngFor="let option of options"
                    (click)="toggleSelection(option, $event)"
                >
                    <input
                        readonly
                        type="checkbox"
                        [checked]="isSelected(option.value)"
                    />
                    <span class="option-text">{{ option.text }}</span>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./app-multi-select.component.scss'],
})
export class AppMultiSelectComponent implements OnChanges {
    protected readonly DefaultText: string = 'Select';

    isOpen = false;

    @Input() options: SelectOption[] = [];
    @Input() tooltip: string = '';
    @Input() id?: string;
    @Input() class: string = '';
    @Input() itemName: string = '';
    @Input() selectedValues: string[] = [];

    @HostBinding('attr.small')
    @Input()
    small: '' | null = null;

    @HostBinding('attr.medium')
    @Input()
    medium: '' | null = null;

    @HostBinding('attr.narrow')
    @Input()
    narrow: '' | null = null;

    @Output() selectedValuesChange = new EventEmitter<string[]>();

    @HostListener('document:click', ['$event'])
    async onClickOutside(event: MouseEvent) {
        const element = event.target as HTMLElement;
        if (!element.closest('app-multi-select')) {
            this.isOpen = false;
            await this.utilityService.sleep(50);
            this.selectedValuesChange.emit(this.selectedValues);
        }
    }

    @Input() clear() {
        this.selectedValues = [];
        this.selectedValuesChange.emit(this.selectedValues);
    }

    constructor(private utilityService: UtilityService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['selectedValues'] && !changes['selectedValues'].firstChange) {
            this.selectedValues = [...changes['selectedValues'].currentValue];
        }
    }

    toggleDropdown() {
        this.isOpen = !this.isOpen;
    }

    toggleSelection(option: SelectOption, event: MouseEvent) {
        event.stopPropagation();
        const index = this.selectedValues.indexOf(option.value);

        if (index === -1) {
            this.selectedValues = [...this.selectedValues, option.value];
        } else {
            this.selectedValues = this.selectedValues.filter((value) => value !== option.value);
        }
    }

    isSelected(value: string): boolean {
        return this.selectedValues.includes(value);
    }

    getDisplayText(): string {
        if (this.selectedValues.length === 0) {
            return `${this.DefaultText} ${this.itemName}`;
        }

        const selectedOptions = this.options
            .filter((option) => this.selectedValues.includes(option.value))
            .map((option) => option.text);

        return `${selectedOptions.length} ${this.itemName} Selected`;
    }

    getClasses(): string {
        const classes = ['app-multi-select'];

        if (this.class) {
            classes.push(this.class);
        }

        if (this.small !== null) {
            classes.push('small');
        } else if (this.medium !== null) {
            classes.push('medium');
        }

        return classes.join(' ');
    }
}
