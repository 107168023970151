<div class="main-header">
    <!--Title-->
    <div class="title"> Asset Retention </div>

    <!--Options-->
    <div class="options">
        <!--Add Asset-->
        <app-button
            primary
            text="Retention History"
            icon="list_alt"
            (click)="displayRetentionHistoryModal()"
        />
    </div>
</div>

<div class="asset-retention-body">
    <!--Row 1-->
    <div class="row controls-one">
        <!--Current / History Toggle-->
        <app-toggle-button-group
            [options]="selectionOptions"
            [selected]="selectedOption"
            (selectionChange)="onSelectionChange($event)"
        />

        <!--Districts-->
        <app-select
            id="districts-select"
            [options]="districts"
            [(selectedValue)]="selectedDistrictCode"
            [tooltip]="selectedDistrictTooltip"
            (selectedValueChange)="handleSelectedDistrictChange()"
        />

        <!--Counts - Single display-->
        <div
            class="district-counts single"
            *ngIf="selectedCount"
        >
            <div class="label"> Count </div>
            <div class="text">
                {{ selectedCount }}
            </div>
        </div>
        <!--Counts - Multi select-->
        <div
            class="district-counts multi"
            *ngIf="counts.length"
        >
            <div class="label"> Count </div>
            <div class="select">
                <app-select
                    narrow
                    [options]="counts"
                    [(selectedValue)]="countsSelectedOption"
                />
            </div>
        </div>

        <!--Load Button-->
        <app-button
            primary
            class="load-button"
            text="Load"
            (click)="loadMissingItems()"
        />
    </div>

    <!--Row 2-->
    <div
        class="row controls-two"
        *ngIf="this.rows.length"
    >
        <!--Table Filter-->
        <app-filter-search
            #filterSearch
            (filter)="onFilterChange($event)"
            (clear)="onFilterClear()"
        />

        <!--Add Asset-->
        <app-button
            primary
            class="add-asset-button"
            text="Add Asset"
            icon="add"
            *ngIf="isModeCurrent"
            (click)="displayAddAssetModal()"
        />

        <!--Post To Junk Ticket-->
        <app-button
            primary
            class="post-to-junk-ticket-button"
            text="Post To Junk Ticket"
            icon="add"
            *ngIf="isModeCurrent"
            [isDisabled]="cannotPostJunkTickets()"
            [title]="cannotPostJunkTickets() ? MESSAGES.postToTicketWarning : ''"
            (click)="displayPostToJunkTicketModal()"
        />

        <!--District History-->
        <!--<app-button
            primary
            class="district-history-button"
            text="District History"
            icon="list_alt"
            (click)="displayDistrictHistoryModal()"
        />-->
    </div>

    <!--Row 3 - Table-->
    <div class="row table">
        <app-table
            [headers]="headers"
            rowVerticalAlign="top"
            [defaultSort]="{ column: 'asset', direction: 'asc' }"
            *ngIf="shouldShowTable && this.rows.length"
        >
            <tr
                app-row
                *ngFor="let row of rowsFiltered"
                [ngClass]="{ disabled: row.isDisabled }"
            >
                <!--Checkbox-->
                <td
                    app-cell
                    class="checkbox"
                >
                    <div
                        class="disabled-cell-overlay"
                        *ngIf="row.isDisabled"
                    ></div>
                    <input
                        type="checkbox"
                        *ngIf="isModeCurrent"
                        [(ngModel)]="row.isChecked"
                    />
                </td>

                <!--Asset Info-->
                <td
                    app-cell
                    class="asset"
                >
                    <div class="asset-number">
                        {{ row.assetNumber }}
                    </div>
                    <div class="asset-description">
                        {{ row.assetDescription }}
                    </div>
                </td>

                <!--Asset Type-->
                <td
                    app-cell
                    class="type"
                >
                    {{ row.assetType }}
                </td>

                <!--Count Notes-->
                <td
                    app-cell
                    class="count-notes"
                >
                    {{ row.assetCountNote ?? '' }}
                </td>

                <!--Comments-->
                <td
                    app-cell
                    class="comments"
                >
                    {{ row.comment ?? '' }}
                </td>

                <!--Images-->
                <td
                    app-cell
                    class="images"
                >
                    <div
                        class="disabled-cell-overlay"
                        *ngIf="row.isDisabled"
                    ></div>
                    <div
                        class="list"
                        *ngIf="getImagesLength(row.missingItemId)"
                        (click)="displayManageImagesModal(row.missingItemId)"
                    >
                        <div
                            *ngFor="let image of orderBy(row.images, ['id'], ['desc'])"
                            class="image"
                            [style.backgroundImage]="'url(' + image.url + ')'"
                        ></div>
                    </div>
                    <div
                        class="count"
                        *ngIf="getImagesLength(row.missingItemId) > 3"
                    >
                        {{ getImagesLength(row.missingItemId) }} images
                    </div>
                </td>

                <!--Disposition-->
                <td
                    app-cell
                    class="disposition"
                >
                    <div class="disposition">
                        <!--Added-->
                        <div class="added">
                            <div class="title">
                                <span
                                    class="material-icons icon"
                                    style="margin-right: 1px"
                                    >add</span
                                >
                                Added
                            </div>
                            <div
                                class="username"
                                [title]="getUserFullnameTooltip(row.addedUser)"
                            >
                                {{ getUserFullname(row.addedUser) }}
                            </div>
                            <div class="date">
                                {{ row.addedDate | date: 'MM/dd/yyyy' }}
                            </div>
                            <div
                                class="ticket"
                                *ngIf="row.onTicketId"
                                (click)="displayTicketModal(row.missingItemId)"
                            >
                                <span class="details">
                                    {{ row.onTicketId }}
                                </span>
                            </div>
                        </div>

                        <!--Resolved-->
                        <div
                            class="resolved"
                            *ngIf="row.statusId !== MissingItemStatus.Added"
                        >
                            <!--Posted | Resolved-->
                            <div
                                *ngIf="
                                    row.statusId === MissingItemStatus.Posted ||
                                    row.statusId === MissingItemStatus.Resolved
                                "
                            >
                                <div class="title">
                                    <span
                                        class="material-icons icon"
                                        style="margin-right: 2px"
                                        >check</span
                                    >
                                    {{
                                        row.statusId === MissingItemStatus.Posted
                                            ? 'Posted'
                                            : 'Resolved'
                                    }}
                                </div>
                                <div
                                    class="username"
                                    [title]="getUserFullnameTooltip(row.resolvedUser)"
                                >
                                    {{ getUserFullname(row.resolvedUser) }}
                                </div>
                                <div
                                    class="date"
                                    *ngIf="row.resolvedDate"
                                >
                                    {{ row.resolvedDate | date: 'MM/dd/yyyy' }}
                                </div>
                                <div
                                    class="ticket"
                                    *ngIf="
                                        row.statusId === MissingItemStatus.Posted &&
                                        row.junkTicketId
                                    "
                                    title="Junk Ticket ID"
                                >
                                    {{ row.junkTicketId }}
                                </div>
                            </div>

                            <!--Failed to Post | Resolve-->
                            <div
                                class="error"
                                *ngIf="
                                    row.statusId === MissingItemStatus.FailedToPost ||
                                    row.statusId === MissingItemStatus.FailedToResolve
                                "
                            >
                                <div class="title">
                                    <span
                                        class="material-icons icon"
                                        style="margin-right: 2px"
                                        >close</span
                                    >
                                    Failed To
                                    {{
                                        row.statusId === MissingItemStatus.FailedToPost
                                            ? ' Post'
                                            : ' Resolve'
                                    }}
                                </div>
                                <span
                                    class="details"
                                    (click)="displayAssetErrorDetails(row.missingItemId)"
                                >
                                    Details
                                </span>
                            </div>
                        </div>
                    </div>
                </td>

                <!--Actions-->
                <td
                    app-cell
                    class="actions"
                >
                    <div
                        class="disabled-cell-overlay"
                        *ngIf="row.isDisabled"
                    ></div>
                    <div class="action-buttons">
                        <!--Edit Comments-->
                        <app-button
                            medium
                            icon="edit_note"
                            title="Edit comments"
                            class="actions-button"
                            *ngIf="isModeCurrent"
                            (click)="displayEditCommentsModal(row.missingItemId)"
                        />

                        <!--Manage Images-->
                        <app-button
                            medium
                            icon="perm_media"
                            title="Manage images for asset"
                            class="actions-button"
                            *ngIf="isModeCurrent"
                            (click)="displayManageImagesModal(row.missingItemId)"
                        />

                        <!--Resolve-->
                        <app-button
                            primary
                            medium
                            text="Resolve"
                            class="actions-button"
                            *ngIf="isModeCurrent"
                            [title]="
                                cannotResolveAsset(row)
                                    ? 'Comments must be added before resolving.'
                                    : ''
                            "
                            [isDisabled]="cannotResolveAsset(row)"
                            (click)="handleResolveClick(row.missingItemId)"
                        />
                    </div>
                </td>
            </tr>
        </app-table>
        <div
            class="no-assets-found"
            *ngIf="shouldShowNoAssetsFound"
        >
            No assets found in this district.
        </div>
    </div>

    <!--Row 4 - Footer-->
    <div
        class="row footer"
        *ngIf="this.rows.length"
    >
        <app-button
            class="toggle-button"
            icon="check_box"
            *ngIf="isModeCurrent"
            (click)="toggleAll()"
        />

        <app-multi-toggle-button-group
            *ngIf="isModeCurrent"
            [options]="filterOptions"
            (selectionChange)="onMultiToggleFilterChange($event)"
        />

        <app-toggle-button-group
            class="asset-type-group-toggle"
            [options]="assetTypeOptions"
            [selected]="selectedAssetTypeOption"
            (selectionChange)="onAssetTypeChange($event)"
        />

        <div class="count-and-reset">
            Displaying {{ rowsFiltered.length }} of {{ rows.length }}

            <app-button
                class="clear-filters-button"
                icon="refresh"
                title="Reset filters"
                (click)="clearFilters()"
            />
        </div>
    </div>
</div>
