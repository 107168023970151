import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiToggleButtonGroupOption } from '../../models/interfaces/ToggleButtonGroupOption';

@Component({
    selector: 'app-multi-toggle-button-group',
    standalone: true,
    imports: [CommonModule],
    template: `
        <div class="app-multi-toggle-button-group">
            <button
                *ngFor="let option of options"
                type="button"
                [ngClass]="{ selected: option.selected }"
                (click)="toggleSelection(option)"
            >
                <span class="material-icons icon">
                    {{ option.selected ? 'check_box' : 'check_box_outline_blank' }}
                </span>
                {{ option.text }}
            </button>
        </div>
    `,
    styleUrl: '../../styles/_buttons.scss',
})
export class AppMultiToggleButtonGroupComponent<T extends string | number | symbol> {
    @Input() options: MultiToggleButtonGroupOption<T>[] = [];

    @Output() selectionChange = new EventEmitter<MultiToggleButtonGroupOption<T>[]>();

    toggleSelection(option: MultiToggleButtonGroupOption<T>): void {
        option.selected = !option.selected;
        this.selectionChange.emit([...this.options]);
    }
}
