import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AcknowledgementModalComponent } from '../components/acknowledgement-modal-component/acknowledgement-modal.component';

@Injectable({
    providedIn: 'root',
})
export class AcknowledgementModalService {
    constructor(private modalService: NgbModal) {}

    async open(title: string, message: string): Promise<boolean> {
        const modalRef = this.modalService.open(AcknowledgementModalComponent);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        try {
            return await modalRef.result;
        } catch {
            return false;
        }
    }
}
