<div class="main-header">
    <!--Title-->
    <div class="title">Reports</div>

    <!--Options-->
    <div class="options"></div>
</div>

<div class="reports">
    <!--Summary Report-->
    <div class="report summary-report">
        <!--Title-->
        <div class="title">Completed Counts</div>

        <!--Form-->
        <div class="form">
            <div class="form-row">
                <app-select
                    class="reports-select"
                    [options]="completedCountsOptions"
                    [(selectedValue)]="selectedCountId"
                />
            </div>

            <div class="form-row">
                <!--Download Summary Report-->
                <app-button
                    primary
                    icon="download"
                    class="download-button"
                    text="Summary Report"
                    title="Download Report"
                    (click)="downloadReport('summary')"
                />
                <!--Download Final State Report-->
                <app-button
                    primary
                    icon="download"
                    class="download-button"
                    text="Final State Report"
                    title="Download Report"
                    (click)="downloadReport('finalState')"
                />
            </div>
        </div>
    </div>
</div>
