<div class="active-count-reports-modal">
    <div class="modal-container">
        <div class="modal-header">
            <div class="title"> Generate Report </div>
            <div
                class="close"
                aria-label="Close"
                (click)="closeModal(false)"
            >
                <span class="material-icons icon">close</span>
            </div>
        </div>

        <div class="modal-body">
            <p> Select the sections that you would like to include in your report. </p>

            <form [formGroup]="form">
                <div class="columns">
                    <!--Asset Statuses-->
                    <div class="column left">
                        <div class="title"> Statuses </div>
                        <div formGroupName="assetStatuses">
                            <div
                                class="checkbox-item"
                                *ngFor="let status of assetStatuses"
                                (click)="toggleCheckbox('assetStatuses', status.key)"
                            >
                                <input
                                    type="checkbox"
                                    [formControlName]="status.key"
                                    readonly
                                />
                                <span>
                                    {{ getStatusLabel(status.key) }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <!--Issue Types-->
                    <div class="column right">
                        <div class="title"> Issue Types </div>
                        <div formGroupName="issueTypes">
                            <div
                                class="checkbox-item"
                                *ngFor="let type of issueTypes"
                                (click)="toggleCheckbox('issueTypes', type.key)"
                            >
                                <input
                                    type="checkbox"
                                    [formControlName]="type.key"
                                    readonly
                                />
                                <span>
                                    <!--{{ type.key }}-->
                                    {{ getIssueTypeLabel(type.key) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

        <div class="modal-footer">
            <button
                type="button"
                class="app-button-primary"
                (click)="handleGenerateClick()"
                [disabled]="shouldDisableGenerateButton"
            >
                Generate
            </button>
        </div>
    </div>
</div>
