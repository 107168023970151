import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { firstValueFrom } from 'rxjs';
import { BudsAuthDto } from '../models/dtos/BudsAuthDto';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private urls = environment.endpoints;

    constructor(private http: HttpClient) {}

    async isUserAuthorized(userName: string): Promise<BudsAuthDto> {
        const url = `${this.urls.isUserAuthorized}?userName=${userName}`;
        return await firstValueFrom(this.http.get<BudsAuthDto>(url));
    }
}
