/* src/components/app-component-loading-indicator/app-component-loading-indicator.component.scss */
.flex-row,
.app-loading-indicator .container,
.app-component-loading-indicator .container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column,
.app-loading-indicator,
.app-component-loading-indicator {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.app-loading-indicator,
.app-component-loading-indicator {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  align-items: center;
  justify-content: start;
  padding: 75px 0 0 0;
  background-color: rgba(37, 37, 37, 0.1);
  z-index: 200;
  opacity: 0;
  transition: opacity 350ms ease-in-out;
  pointer-events: none;
}
.app-loading-indicator.show,
.show.app-component-loading-indicator {
  opacity: 1;
  pointer-events: auto;
}
.app-loading-indicator.hide,
.hide.app-component-loading-indicator {
  opacity: 0;
  pointer-events: none;
}
.app-loading-indicator .container,
.app-component-loading-indicator .container {
  justify-content: center;
  width: auto;
  color: #eee;
  background-color: #252525;
  padding: 15px 28px;
  font-size: 16px;
  font-weight: 400;
}
.app-loading-indicator .container .spinner,
.app-component-loading-indicator .container .spinner {
  width: 30px !important;
  min-width: 30px !important;
  max-width: 30px !important;
  height: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;
  border: 5px solid #bbb;
  border-top: 5px solid #eee;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 10px 0 0;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.app-component-loading-indicator {
  justify-content: flex-start;
  padding: 35px 0 0 0;
  z-index: 400;
}
.app-component-loading-indicator .container {
  padding: 13px 23px !important;
  font-size: 14px !important;
  opacity: 0.9;
}
.app-component-loading-indicator .container .spinner {
  width: 21px !important;
  min-width: 21px !important;
  max-width: 21px !important;
  height: 21px !important;
  min-height: 21px !important;
  max-height: 21px !important;
  border-width: 3.5px;
}
/*# sourceMappingURL=app-component-loading-indicator.component.css.map */
