import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ToastService } from '../../services/ToastService';
import { ToastState } from '../../models/interfaces/Toast';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-toast',
    template: `
        <div
            *ngIf="toast"
            class="app-toast"
            [ngClass]="[toast.type || '', toast.position || 'top-center']"
            [style.opacity]="toast.opacity"
        >
            {{ toast.message }}
        </div>
    `,
    styleUrls: ['app-toast.component.scss'],
    standalone: true,
    imports: [NgClass, NgIf],
})
export class AppToastComponent implements OnInit, OnDestroy {
    toast: ToastState | null = null;
    private subscription: Subscription = new Subscription();

    constructor(private toastService: ToastService) {}

    ngOnInit() {
        this.subscription = this.toastService.get().subscribe((toast) => (this.toast = toast));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
