import { Injectable } from '@angular/core';

type StateValue = string | boolean | number | Date | object;

@Injectable({
    providedIn: 'root',
})
export class StateService {
    private state: Record<string, StateValue> = {};

    set(key: string, value: StateValue): void {
        this.state[key] = value;
    }

    get(key: string): StateValue | undefined {
        return this.state[key];
    }

    clear(key: string): void {
        delete this.state[key];
    }

    clearAll(): void {
        this.state = {};
    }
}
