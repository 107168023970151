/* src/app/modules/main/main-shared.scss */
.flex-row,
.main-header > .options,
.main-header > .title,
.main-header {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
}
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.modal-shadow {
  box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.3);
}
.modal-border {
  border: 1px solid rgba(0, 0, 0, 0.8);
}
.app-field-shadow {
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(255, 255, 255, 0.1);
}
.main-header {
  width: 100%;
  height: 60px;
  margin: 0;
  padding: 0 25px;
}
.main-header > .title {
  font-size: 19px;
  font-weight: 500;
  color: #252525;
  white-space: nowrap;
}
.main-header > .options {
  justify-content: end;
  min-width: 100px;
  height: 100%;
  margin: 0 0 0 auto;
}
/*# sourceMappingURL=main-shared.css.map */
