import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';

@Component({
    selector: 'acknowledgement-modal',
    standalone: true,
    imports: [NgIf],
    templateUrl: 'acknowledgement-modal.component.html',
    styleUrls: ['acknowledgement-modal.component.scss'],
})
export class AcknowledgementModalComponent {
    @Input() title: string = '';
    @Input() message: string = '';

    constructor(public activeModal: NgbActiveModal) {}
}
